<template>
  <div class="gwd-table">
    <div class="gwd-table-header narrow-cost-list">
      <span>Tüüp</span>
      <span>Kirjeldus</span>
      <span>Kuupäev</span>
      <span>Kogus</span>
      <span>Kulu/Hinna summa</span>
      <span class="flex justify-end">
        <button
          class="btn-primary"
          @click="toggleShowAdded"
          v-if="!markAsPaid && !combineCostsActive"
        >
          {{ showAdded ? "Peida" : "Näita" }}
        </button>
      </span>
    </div>
    <div
      v-for="task in filteredTasks"
      :key="task.id"
      class="mb-3 border rounded"
    >
      <div
        class="gwd-table-row narrow-task-list items-center cursor-pointer"
        @click="showTaskPlannedActualCosts(task)"
      >
        <span class="font-medium"> {{ task.title }}</span>
        <span> {{ moment(task.start_time).format("DD.MM.YYYY") }}</span>
        <div v-if="!combineCostsActive" class="flex items-center ml-auto">
          <button
            class="btn-primary"
            @click.stop.prevent="combineTasksCosts(task)"
          >
            Kombineeri töö kulud
          </button>
        </div>
      </div>
      <div
        v-if="task.payer"
        class="gwd-table-row narrow-task-list items-center"
      >
        <span class="col-span-2"> Maksja: {{ task.payer.name }} </span>
        <div class="flex items-center ml-auto">
          <button
            v-if="!task.checked"
            @click="addAnotherInvoice(task)"
            class="btn-attention"
          >
            <span class="label">Eraldi arve</span>
          </button>
        </div>
      </div>
      <div
        v-if="task.address || task.end_address"
        class="gwd-table-row grid grid-cols-2 items-center cursor-pointer"
      >
        <div class="flex w-full items-center gap-x-1">
          <img
            class="h-3 w-3"
            src="/bc21/placeholder.svg"
            alt="location icon"
          />
          <span> {{ task.address ? task.address : "-" }}</span>
        </div>
        <div class="flex w-full items-center gap-x-1">
          <img
            class="h-3 w-3"
            src="/bc21/placeholder.svg"
            alt="location icon"
          />
          <span> {{ task.end_address ? task.end_address : "-" }}</span>
        </div>
      </div>
      <template-cost
        v-for="templateType in generateTemplateTypes(task)"
        :key="templateType.id"
        :id="templateType.id"
        :template="templates.find((x) => x.type === templateType.type)"
        :costs="templateType.costs"
        :name-prefix="templateType.name"
        :added-cost-ids="addedCostIds"
        :show-added="showAdded"
        :task="task"
        :combine-costs-active="combineCostsActive"
        :combined-costs="combinedCosts"
        :mark-as-paid="markAsPaid"
        :status="status"
        @addItem="addItem"
        @selectTemplate="selectTemplate"
        @addToCombinedCosts="addToCombinedCosts"
        :type="templateType.type"
        class="flex"
      />
      <div class="flex gwd-table-row narrow-task-list items-center">
        <div
          v-if="task.payment_info"
          class="flex col-span-2 items-center gap-x-3"
        >
          <span>
            Makstud
            {{
              task.payment_info.payment_type === 1
                ? "sularahas"
                : "kaardimaksega"
            }}
          </span>
          <span>
            {{ task.payment_info.payment_amount }}
          </span>
        </div>
        <span v-else class="col-span-2" />
        <div class="flex items-center">
          Töö kogukulu: {{ generateTaskTotalCost(task) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TemplateCost from "@/components/invoices/TemplateCost.vue";
import { round2 } from "@/assets/utils/commonMath";
import set from "lodash/set";

export default {
  name: "TaskCosts",
  components: { TemplateCost },
  props: {
    totalCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    templates: {
      type: Array,
      default: () => {
        return [];
      },
    },
    addedCostIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    combinedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    combineCostsActive: {
      type: Boolean,
      default: false,
    },
    showAdded: {
      type: Boolean,
      default: false,
    },
    markedAsPaid: {
      type: Array,
      default: () => [],
    },
    markAsPaid: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: null,
    },
    timeFilter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      moment: moment,
      filteredTasks: [],
    };
  },
  mounted() {
    this.filterTasks();
  },
  methods: {
    filterTasks() {
      if (this.timeFilter && this.timeFilter.filter) {
        this.filteredTasks = this.totalCosts
          .filter((x) => {
            return moment(x.start_time).isBetween(
              this.timeFilter.startDate,
              this.timeFilter.endDate,
              "day",
              "[]"
            );
          })
          .filter((x) => x.costs.length > 0);
      } else {
        this.filteredTasks = this.totalCosts.filter((x) => x.costs.length > 0);
      }
    },
    combineTasksCosts(task) {
      task.costs.forEach((x) => {
        if (!this.addedCostIds.includes(`${x.type}${x.id}`))
          this.addToCombinedCosts(x);
      });
      this.$emit("toggleCombinedView", task);
    },
    combineAll() {
      this.filteredTasks.forEach((e) => {
        e.costs.forEach((x) => {
          this.addToCombinedCosts(x);
        });
      });
    },
    showTaskPlannedActualCosts(e) {
      this.$emit("toggleTaskPlannedActual", e);
    },
    changeTaskToNotChecked(e) {
      let task = this.totalCosts.find((x) => x.id === e);
      set(task, "checked", false);
    },
    addAnotherInvoice(e) {
      e.checked = true;
      this.$emit("addAnotherInvoice", {
        payer: e.payer,
        task: e.id,
      });
    },
    toggleShowAdded() {
      this.$emit("toggleShowAdded");
    },
    addItem(e) {
      this.$emit("addItem", e);
    },
    selectTemplate(template, task) {
      this.$emit("selectTemplate", template, task);
    },
    addToCombinedCosts(e) {
      this.$emit("addToCombinedCosts", e);
    },
    addToMarkedAsPaid(e) {
      this.$emit("addToMarkedAsPaid", e);
    },
    generateTaskTotalCost(task) {
      return round2(
        [...task.costs].reduce(
          (prev, curr) =>
            parseFloat(prev) +
            round2(curr.amount * curr.cost * curr.cost_coefficient),
          0
        )
      );
    },
    generateTemplateTypes(task) {
      let types = [
        {
          id: "resourcecosts",
          costs: task.costs.filter((x) => x.type === "resourceCost"),
          type: 3,
          name: "Ressurss",
        },
        {
          id: "suppliercosts",
          costs: task.costs.filter((x) => x.type === "supplierCost"),
          type: 5,
          name: "Hankija",
        },
        {
          id: "extraCosts",
          costs: task.costs.filter((x) => x.type === "extraCost"),
          type: 6,
          name: "Lisatöö",
        },
      ];
      if (this.templates.find((x) => x.type === 1) !== undefined) {
        types.push({
          id: "axl",
          costs: task.costs.filter(
            (x) => x.type === "workerCost" || x.type === "equipmentCost"
          ),
          type: 1,
          name: "Tehnika + töötaja",
        });
      } else {
        types.push(
          {
            id: "workercosts",
            costs: task.costs.filter((x) => x.type === "workerCost"),
            type: 2,
            name: "Töötaja",
          },
          {
            id: "equipmentcosts",
            costs: task.costs.filter((x) => x.type === "equipmentCost"),
            type: 4,
            name: "Töötaja",
          }
        );
      }
      return types;
    },
  },
  watch: {
    timeFilter: {
      deep: true,
      handler() {
        this.filterTasks();
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
