<template>
  <div class="flex h-full w-full items-center ju">
    <video ref="videoPlayer" class="video-js">
      <source type="video/mp4" :src="source" v-if="source" />
    </video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    source: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      {
        controls: true,
      },
      () => {}
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style>
.video-js {
  max-width: 75vw;
  max-height: 80vh;
}
</style>
