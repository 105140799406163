<template>
  <div class="flex w-full flex-grow gap-x-4 mobile:flex-col">
    <div
      class="data-container mb-auto mobile:w-full"
      :class="hasTemplates ? 'w-3/5' : 'w-full'"
    >
      <div class="data-container-header">
        <h4>Tellimuse tulumallid</h4>
      </div>
      <div class="data-container-content data-container-table">
        <div class="gwd-table nested" v-on-clickaway="() => (this.edit = null)">
          <div class="gwd-table-header project-template-table">
            <span class="mobile:col-span-2">Tüüp</span>
            <span class="mobile:col-span-4">Kirjeldus</span>
            <span class="mobile:col-span-1">Hind</span>
            <span class="mobile:col-span-1">Ühik</span>
            <span class="mobile:col-span-4">KM</span>
            <span class="mobile:col-span-5">Tulukood</span>
            <span />
          </div>
          <div
            v-for="(item, index) in projectTemplates"
            :key="`prjtmp-${index}`"
            class="grid project-template-table w-full items-center p-2 hover:bg-offwhite justify-between mx-auto"
            :style="{
              backgroundColor: item.templateType
                ? item.templateType.backgroundColor
                : null,
            }"
          >
            <div class="mobile:col-span-2">
              <select
                v-model="item.type"
                class="w-full"
                @change="
                  item.templateType =
                    templateTypes.find((x) => x.type === item.type) || null
                "
              >
                <option
                  v-for="type in templateTypes"
                  :key="type.type"
                  :value="type.type"
                >
                  {{ type.description }}
                </option>
              </select>
            </div>
            <div class="mobile:col-span-4">
              <input
                v-model="item.description"
                type="text"
                class="w-full max-w-full"
              />
            </div>
            <div>
              <decimal-input v-model="item.price" class="max-w-full" />
            </div>
            <div>
              <select v-model="item.unit">
                <option :value="'tk'">tk</option>
                <option :value="'h'">h</option>
                <option :value="'m'">m</option>
                <option :value="'km'">km</option>
                <option :value="'l'">l</option>
                <option :value="'m²'">m²</option>
                <option :value="'m³'">m³</option>
                <option :value="'kg'">kg</option>
                <option :value="'t'">t</option>
                <option :value="'paev'">päev</option>
                <option :value="'kuu'">kuu</option>
              </select>
            </div>
            <div class="mobile:col-span-4">
              <select
                v-if="taxTypes"
                v-model="item.vat"
                class="max-w-full truncate"
              >
                <option
                  v-for="taxType in taxTypes"
                  :key="taxType.id"
                  :value="taxType.id"
                >
                  {{
                    taxType
                      ? `${taxType.description} (${
                          taxType.tax_percentage ? taxType.tax_percentage : "0"
                        }%)`
                      : "-"
                  }}
                </option>
              </select>
            </div>
            <div class="mobile:col-span-5">
              <select v-model="item.serviceCode">
                <option :value="null" :key="null">Puudub</option>
                <option
                  v-for="serviceObj in serviceObjects"
                  :key="serviceObj.id"
                  :value="serviceObj"
                  :disabled="serviceObj.service_code.length === 2"
                >
                  {{
                    `${serviceObj.service_code} - ${serviceObj.service_name}`
                  }}
                </option>
              </select>
            </div>
            <div class="flex justify-end">
              <button @click="removeTemplate(index)" class="icon-button danger">
                <img
                  src="/bc21/trash.svg"
                  alt="Delete cost list row"
                  class="filter-to-white h-4 w-4"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="flex p-2 text-sm">
          <button class="btn-primary" @click="newTemplate">
            <span class="label">Lisa mall</span>
          </button>
        </div>
      </div>
    </div>
    <div class="flex w-2/5 flex-col mobile:w-full" v-if="hasTemplates">
      <div class="data-container">
        <div class="data-container-header">
          <gwd-formlabel title="Ettevõtte ja kliendi tulumallid" />
        </div>
        <div class="data-container-content data-container-table">
          <div class="gwd-table nested">
            <div class="gwd-table-header templates-table">
              <span class="mobile:col-span-5">Kirjeldus</span>
              <span class="mobile:col-span-3">Tüüp</span>
              <span class="mobile:col-span-2">Hind</span>
              <span class="mobile:col-span-2">Ühik</span>
              <span class="mobile:col-span-2">KM</span>
              <span class="mobile:col-span-2" />
            </div>
            <div
              v-for="template in companyTemplates.filter(
                (x) => !projectTemplates.map((y) => y.id).includes(x.id)
              )"
              :key="template.id"
              class="gwd-table-row templates-table"
              :style="`background-color: ${template.templateType.backgroundColor}`"
            >
              <span
                class="block truncate w-full mobile:col-span-5"
                :title="template.description"
              >
                {{ template.description }}
              </span>
              <span
                class="block truncate w-full mobile:col-span-3"
                :title="template.templateType.description"
                >{{ template.templateType.description }}
              </span>
              <span class="mobile:col-span-2">{{ template.price }}</span>
              <span class="mobile:col-span-2">{{ template.unit }}</span>
              <span class="mobile:col-span-2">{{
                template.vatType ? `${template.vatType.tax_percentage}%` : ""
              }}</span>
              <div class="justify-end mobile:col-span-2">
                <button
                  class="icon-button primary"
                  @click="addToProject(template)"
                >
                  <span class="typcn typcn-plus text-white" />
                </button>
              </div>
            </div>
            <div
              v-for="template in clientTemplates.filter(
                (x) => !projectTemplates.map((y) => y.id).includes(x.id)
              )"
              :key="template.id"
              class="gwd-table-row templates-table border-t border-black"
              :style="`background-color: ${template.templateType.backgroundColor}`"
            >
              <span
                class="block truncate w-full mobile:col-span-5"
                :title="template.description"
              >
                {{ template.description }}
              </span>
              <span
                class="block truncate w-full mobile:col-span-3"
                :title="template.templateType.description"
                >{{ template.templateType.description }}
              </span>
              <span class="mobile:col-span-2">{{ template.price }}</span>
              <span class="mobile:col-span-2">{{ template.unit }}</span>
              <span class="mobile:col-span-2">{{
                template.vatType ? `${template.vatType.tax_percentage}%` : ""
              }}</span>
              <div class="justify-end flex mobile:col-span-2">
                <button
                  class="icon-button primary"
                  @click="addToProject(template)"
                >
                  <span class="typcn typcn-plus text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DecimalInput from "../../reusable/DecimalInput.vue";
import { set } from "lodash/lodash";
export default {
  name: "NewProjectRevenueTemplates",
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  components: {
    DecimalInput,
  },
  data() {
    return {
      clientTemplates: [],
      companyTemplates: [],
      edit: null,
      projectTemplates: [],
    };
  },
  async mounted() {
    if (!this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    if (!this.serviceObjects)
      await this.$store.dispatch("companyVariables/retrieveServiceObjects");
    if (this.client && Object.keys(this.client).length > 0) {
      this.loadClientCostTemplates(this.client.id);
    }
    this.loadCompanyCostTemplates();
  },
  methods: {
    removeTemplate(index) {
      this.projectTemplates.splice(index, 1);
    },
    loadClientCostTemplates(clientId) {
      if (this.clientId) this.clientTemplates = null;
      this.apiRequest(
        `template/${this.companyId}/costs/templates/?type=client&object_id=${clientId}`,
        "get",
        true
      ).then((res) => {
        this.clientTemplates = res.data;
        for (let costTemplate of this.clientTemplates) {
          set(
            costTemplate,
            "vatType",
            this.taxTypes.find((x) => x.id === costTemplate.vat)
          );
          set(
            costTemplate,
            "templateType",
            this.templateTypes.find((x) => x.type === costTemplate.type) ?? null
          );
          this.addToProject(costTemplate);
        }
      });
    },
    loadCompanyCostTemplates() {
      this.apiRequest(
        `template/${this.companyId}/costs/templates/`,
        "get",
        true
      ).then((res) => {
        this.companyTemplates = res.data;
        for (let costTemplate of this.companyTemplates) {
          set(
            costTemplate,
            "vatType",
            this.taxTypes.find((x) => x.id === costTemplate.vat)
          );
          set(
            costTemplate,
            "templateType",
            this.templateTypes.find((x) => x.type === costTemplate.type) ?? null
          );
        }
      });
    },
    newTemplate() {
      let vat = null;
      const defaultVat = this.taxTypes.find((x) => x.default);
      if (defaultVat) vat = defaultVat.id;
      this.projectTemplates.push({
        description: "",
        type: null,
        price: 0,
        unit: "tk",
        vat: vat,
      });
    },
    addToProject(e) {
      this.projectTemplates.push({
        id: e.id,
        description: e.description,
        type: e.type,
        price: e.price,
        unit: e.unit,
        vat: e.vat,
        serviceCode: e.service_code
          ? this.serviceObjects.find((x) => x.id === e.service_code)
          : null,
        templateType: e.templateType,
      });
    },
    getType(template) {
      if (template) {
        return this.templateTypes.find((x) => x.type === template).description;
      }
      return "";
    },
    saveProjectCostTemplates(projectId) {
      let data = this.projectTemplates.map((x) => {
        x.project = projectId;
        x.vat = x.vatType ? x.vatType.id : null;
        x.service_code = x.serviceCode ? x.serviceCode.id : null;
        return x;
      });
      this.apiRequest(
        `template/${this.companyId}/costs/templates/create/`,
        "post",
        true,
        data
      ).then((res) => {
        if (res.status === 201) this.$emit("saved");
      });
    },
  },
  watch: {
    client() {
      if (this.client) {
        this.loadClientCostTemplates(this.client.id);
      } else {
        this.clientTemplates = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      taxTypes: "companyVariables/taxTypes",
      templateTypes: "companyVariables/templateTypes",
      serviceObjects: "companyVariables/serviceObjects",
    }),
    hasTemplates() {
      return (
        this.clientTemplates.length > 0 || this.companyTemplates.length > 0
      );
    },
  },
};
</script>

<style lang="scss">
.cost-table {
  @apply rounded border-collapse shadow p-2 bg-white w-full;
  thead {
    tr {
      @apply border-b-2 border-offwhite;
      th {
        @apply text-left px-2 py-2 font-semibold;
      }
    }
  }
  tbody {
    @apply rounded-b;
    tr {
      @apply duration-100;
      td {
        @apply px-2 py-2;
        &:not(.no-hover):hover {
          @apply bg-offwhite cursor-pointer bg-opacity-40;
        }
      }
    }
  }
}
.project-template-table {
  @apply gap-x-2;
  grid-template-columns: 0.5fr 1fr 0.2fr 0.2fr 0.5fr 0.5fr 0.1fr;
  @media screen and (max-width: 601px) {
    @apply grid grid-cols-6 gap-x-1 gap-y-1;
  }
}

.templates-table {
  @apply gap-x-2;
  //                     name      price       vatType
  //                           type      unit        buttons
  grid-template-columns: 0.8fr 0.5fr 0.2fr 0.2fr 0.2fr 0.1fr;
  @media screen and (max-width: 601px) {
    @apply grid grid-cols-8 gap-x-1 gap-y-1;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
