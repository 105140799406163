<template>
  <div class="modal" :data-modal-id="key">
    <div class="modal-body modal-large" v-on-clickaway.stop="handleClickaway">
      <div class="modal-header p-2">
        <h4>Lisa klient</h4>
      </div>
      <div class="modal-content flex-col gap-y-2">
        <add-client-form
          class="w-full"
          @clientUpdated="(e) => (client = e)"
          @clientContactsUpdated="(e) => (contacts = e)"
          @editingContact="blockSaving"
          @regCodeNotUnique="regCodeNotUnique = true"
          @nameNotUnique="nameNotUnique = true"
          @similarNames="(e) => (similarNames = e)"
        />
        <div
          v-if="regCodeNotUnique || nameNotUnique || similarNames.length > 0"
          class="error-box w-full items-center gap-x-3"
        >
          <span class="typcn typcn-times text-3xl leading-none" />
          <span class="flex" v-if="regCodeNotUnique">
            Registrikoodiga klient juba eksisteerib
          </span>
          <span class="flex" v-if="nameNotUnique">
            Nimi pole unikaalne, kontrolli et ei sisestata topelt klienti
          </span>
          <span class="flex" v-if="similarNames.length > 0">
            Sarnased nimed:
            {{
              similarNames
                .map((x) => (x[1] ? `${x[0]} (alias: ${x[1]})` : x[0]))
                .join(" | ")
            }}
          </span>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <div v-if="!saving" class="flex items-center gap-x-3">
          <button
            :disabled="saveBlock || $v.$invalid"
            :class="!saveBlock && !$v.$invalid ? 'btn-primary' : 'btn-disabled'"
            @click="saveClient"
          >
            Salvesta
          </button>
          <button
            class="btn-danger"
            @click.stop="() => $store.dispatch('modals/addClientModal/close')"
          >
            Tühista
          </button>
        </div>
        <saving-loader
          v-else
          :text="{
            saving: 'Salvestamine...',
            saved: 'Salvestatud',
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AddClientForm from "./AddClientForm.vue";
import { mapGetters } from "vuex";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import { generateModalKey } from "@/assets/utils/commonMath";
import EventBus from "@/assets/mixins/EventBus";
import SavingLoader from "@/components/reusable/SavingLoader.vue";
export default {
  components: {
    SavingLoader,
    AddClientForm,
  },
  data() {
    return {
      client: null,
      contacts: [],
      saveBlock: false,
      key: null,
      saving: false,
      regCodeNotUnique: false,
      nameNotUnique: false,
      similarNames: [],
    };
  },
  mounted() {
    this.key = this.generateModalKey();
  },
  methods: {
    generateModalKey,
    saveClient() {
      this.saving = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.apiRequest(
          `company/${this.companyId}/clients/add/`,
          "post",
          true,
          this.client
        )
          .then((res) => {
            if (res.status === 201) {
              let clientId = res.data.client_id;

              if (this.contacts.length > 0) {
                this.saveContacts(clientId)
                  .then(() => {
                    EventBus.$emit("clientAdded", res.data.client_id);
                    this.$emit("close");
                  })
                  .catch((err) => console.error(err));
              } else {
                EventBus.$emit("clientAdded", res.data.client_id);
                this.$emit("close");
              }
            }
          })
          .catch((err) => {
            this.saving = false;
            if (err.response.status === 400) {
              if (err.response.data.code === 10) {
                this.$store.dispatch("messageHandler/throwMessage", {
                  type: "error",
                  text: "Kliendi salvestamine ebaõnnestus, sama registrikoodiga klient juba eksisteerib!",
                  ttl: 5,
                });
              }
            } else {
              this.$store.dispatch("messageHandler/throwMessage", {
                type: "error",
                text: "Kliendi salvestamine ebaõnnestus",
                ttl: 15,
              });
            }
          });
      }
    },
    saveContacts(clientId) {
      return new Promise((resolve, reject) =>
        this.apiRequest(
          `company/${this.companyId}/client/${clientId}/contacts/add/`,
          "post",
          true,
          this.contacts
        )
          .then((res) => {
            if (res.status === 200) {
              this.$emit("clientSaved", clientId);
              resolve();
            }
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          })
      );
    },
    blockSaving(e) {
      this.saveBlock = e;
    },
    handleClickaway(event) {
      if (event.target.dataset.modalId === this.key)
        this.$store.dispatch("modals/addClientModal/close");
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  validations() {
    if (this.client && this.client.is_company) {
      return {
        client: {
          name: {
            required,
          },
          register_code: {
            required: requiredIf(() => {
              return ["EST", "Eesti", null].includes(this.client.country);
            }),
          },
          email: {
            email,
          },
        },
      };
    }
    return {
      client: {
        name: {
          required,
        },
        email: {
          email,
        },
      },
    };
  },
};
</script>
<style lang="scss"></style>
