<template>
  <div class="modal">
    <div class="modal-body modal-full" v-on-clickaway:mousedown="closeModal">
      <div class="modal-header">
        <div class="modal-title">
          <h3 class="self-center">Lisa töö</h3>
        </div>
      </div>
      <div class="modal-content flex-col gap-y-4" ref="modalContent">
        <add-task-form
          @taskSaved="handleSaveTask"
          ref="addTaskForm"
          class="z-10 relative"
        />
        <div class="relative">
          <worker-selector
            v-show="selectedWorkers.length === 0 || showWorkerSelector"
            :start-time="startTime"
            :end-time="endTime"
            :start-location="fields.location"
            :end-location="fields.endLocation"
            :selected-workers="selectedWorkers.map((x) => x.worker)"
            :full-width="true"
            @setWorkers="handleSetWorkers"
            @removeWorker="handleRemoveWorker"
            @workerAmountChanged="handleWorkerCountChanged"
            @attributeStringChanged="handleAttributeStringChanged"
            ref="workerSelector"
            class="text-sm"
          />
          <assignment-manager
            v-show="selectedWorkers.length > 0 && !showWorkerSelector"
            @removeWorker="handleRemoveWorker"
            @clearWorkers="() => handleSetWorkers([])"
            @showWorkerSelector="showWorkerSelector = true"
            ref="assignmentManager"
          />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <div class="flex gap-x-3">
          <button
            class="confirm-button relative"
            @click="saveTask"
            :disabled="
              (!fields.title && !fields.description) || saving || !startTime
            "
            :class="
              (fields.title || fields.description) && !saving && startTime
                ? 'btn-primary'
                : 'btn-disabled'
            "
          >
            <span v-if="!saving" class="label">Salvesta</span>
            <ClipLoader size="16px" v-else />
            <ul
              v-if="(!fields.title && !fields.description) || !startTime"
              class="confirm-checklist"
            >
              <li v-if="!fields.title && !fields.description">
                Kas pealkiri või kirjeldus peab olema täidetud
              </li>
              <li v-if="!startTime">Tööl peab olema kuupäev märgitud</li>
            </ul>
          </button>
          <button
            class="confirm-button relative"
            @click="saveTaskWithoutTime"
            :class="
              (fields.title || fields.description) && !saving
                ? 'btn-pending'
                : 'btn-disabled'
            "
            :disabled="(!fields.title && !fields.description) || saving"
          >
            <span v-if="!saving" class="label">Salvesta ajatult</span>
            <ClipLoader size="16px" v-else />
            <ul
              v-if="!fields.title && !fields.description"
              class="confirm-checklist"
            >
              <li v-if="!fields.title && !fields.description">
                Kas pealkiri või kirjeldus peab olema täidetud
              </li>
            </ul>
          </button>
          <button
            v-if="workerOverride"
            @click="saveTask()"
            :disabled="!fields.title || saving"
            :class="fields.title && !saving ? 'btn-primary' : 'btn-disabled'"
          >
            <span v-if="!saving">Salvesta (Töötajatel on aeg mis kordub)</span>
            <ClipLoader size="16px" v-else />
          </button>
          <button class="btn-danger" @click="closeModal(false)">Sulge</button>
        </div>
      </div>
      <project-selector
        v-if="showProjectSelector"
        :convert="false"
        @closeModal="
          $store.commit('modals/addTaskModal/setShowProjectSelector', false)
        "
        @projectAdded="setProject"
        class="z-999"
      />
      <new-project-modal
        v-if="showNewProjectModal"
        :task-data="fields"
        @closeModal="
          $store.commit('modals/addTaskModal/setShowNewProjectModal', false)
        "
        @projectAdded="setProject"
        class="z-999"
      />
      <add-client-modal
        v-if="
          $store.state.modals.addClientModal.show &&
          $store.state.modals.addClientModal.taskModalOpen
        "
        @close="$store.commit('modals/addClientModal/close')"
      />
      <confirm-close-modal
        v-if="closeConfirmation"
        @confirm="$store.dispatch('modals/addTaskModal/close')"
        @closeModal="closeConfirmation = false"
      />
      <client-selector-modal
        v-if="showPayerSelector"
        :select-client="false"
        @closeModal="
          $store.dispatch('modals/addTaskModal/showPayerSelector', false)
        "
        @clientSelected="
          (e) => {
            fields.payer = e;
            $store.dispatch('modals/addTaskModal/showPayerSelector', false);
          }
        "
      />
    </div>
  </div>
</template>

<script>
import AddTaskForm from "./AddTaskForm";
import WorkerSelector from "./WorkerSelector.vue";
import AssignmentManager from "./assignmentmanager/AssignmentManager";
import ProjectSelector from "@/components/tasks/ProjectSelector";
import { EventBus } from "@/assets/mixins/GewodoMixins";
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import NewProjectModal from "@/components/project/NewProjectModal.vue";
import AddClientModal from "@/components/company/clients/AddClientModal.vue";
import ConfirmCloseModal from "@/components/reusable/ConfirmCloseModal.vue";
import ClientSelectorModal from "@/components/project/ClientSelectorModal.vue";
// import moment from "moment/moment";
export default {
  components: {
    ClientSelectorModal,
    ConfirmCloseModal,
    AddClientModal,
    NewProjectModal,
    AddTaskForm,
    WorkerSelector,
    AssignmentManager,
    ProjectSelector,
    ClipLoader,
  },
  data() {
    return {
      showWorkerSelector: false,
      closeConfirmation: false,
    };
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  methods: {
    closeModal(event) {
      if (
        (event.target === undefined || !event.target.dataset.modalId) &&
        !this.showNewProjectModal
      )
        this.closeConfirmation = true;
    },
    setProject(project) {
      this.$store.dispatch("modals/addTaskModal/setProject", project);
    },
    saveTask() {
      if (
        this.$refs.workerSelector &&
        this.$refs.workerSelector.hasOneWorker &&
        (this.showWorkerSelector || this.selectedWorkers.length === 0)
      )
        this.$refs.workerSelector.confirmSelection();
      this.$store.dispatch("modals/addTaskModal/saveTask", false);
    },
    saveTaskWithoutTime() {
      this.$store.dispatch("modals/addTaskModal/saveTask", true);
    },
    handleSaveTask() {
      EventBus.$emit("updateTasks");
      this.closeModal();
    },
    handleRemoveWorker(index) {
      this.$store.commit("modals/addTaskModal/removeWorker", index);
    },
    handleSetWorkers(arr) {
      this.$store.commit("modals/addTaskModal/setWorkers", arr);
      this.showWorkerSelector = false;
    },
    handleAttributeStringChanged(attrString) {
      this.$store.commit("modals/addTaskModal/setAttributeString", attrString);
    },
    handleWorkerCountChanged(count) {
      this.$store.commit("modals/addTaskModal/setWorkerCount", count);
    },
  },
  computed: {
    ...mapGetters({
      startTime: "modals/addTaskModal/startTimeDb",
      endTime: "modals/addTaskModal/endTimeDb",
      selectedWorkers: "modals/addTaskModal/selectedWorkers",
      showProjectSelector: "modals/addTaskModal/showProjectSelector",
      showNewProjectModal: "modals/addTaskModal/showNewProjectModal",
      showPayerSelector: "modals/addTaskModal/showPayerSelector",
      fields: "modals/addTaskModal/fields",
      saving: "modals/addTaskModal/saving",
      workerOverride: "modals/addTaskModal/workerOverride",
    }),
  },
};
</script>

<style lang="scss">
.z-999 {
  z-index: 999 !important;
}
</style>
