<template>
  <div class="gwd-table-row equipment-assignments-table">
    <span class="mobile:col-span-2">
      {{ equipmentAssignment.equipment.name }}</span
    >
    <span class="mobile:col-span-2">
      {{
        equipmentAssignment.equipment.worker
          ? equipmentAssignment.equipment.worker.worker_name
          : "-"
      }}
    </span>
    <span v-if="!edit">{{
      equipmentAssignment.amount ? equipmentAssignment.amount : "-"
    }}</span>
    <decimal-input v-else v-model="editables.amount" />
    <span v-if="!edit"> {{ equipmentAssignment.cost }}</span>
    <decimal-input v-else v-model="editables.cost" />
    <span v-if="!edit"> {{ equipmentAssignment.price }}</span>
    <decimal-input v-else v-model="editables.price" />

    <span v-if="!edit">{{
      equipmentAssignment.cost_coefficient
        ? equipmentAssignment.cost_coefficient
        : "-"
    }}</span>
    <decimal-input v-else v-model="editables.cost_coefficient" />
    <span v-if="!edit" class="mobile:col-span-2">{{
      equipmentAssignment.vatType
        ? equipmentAssignment.vatType.description
        : "-"
    }}</span>
    <select v-else v-model="editables.vatType" class="mobile:col-span-2">
      <option
        v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
        :key="taxType.id"
        :value="taxType"
      >
        {{ `${taxType.description}` }}
      </option>
    </select>
    <span class="mobile:col-span-2">
      {{
        ["Tükihind", "Tunnihind", "Kilomeetrihind"][
          equipmentAssignment.type - 1
        ]
      }}
    </span>
    <div class="flex justify-end mobile:col-span-4">
      <div v-if="isManager" class="flex items-center gap-x-1">
        <button
          v-if="!edit"
          class="btn-primary"
          @click="
            $store.commit('modals/taskModal/setShowNewEquipmentCostModal', {
              show: true,
              equipmentAssignment: equipmentAssignment,
            })
          "
        >
          Kuluks
        </button>
        <button v-if="!edit" class="btn-primary" @click="edit = true">
          <span class="typcn typcn-edit" />
        </button>
        <button v-if="edit" class="btn-primary" @click="editAssignment">
          <span class="typcn typcn-tick" />
        </button>
        <button v-if="edit" class="btn-danger" @click="enableDelete">
          Kustuta
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import { mapGetters } from "vuex";
import DecimalInput from "@/components/reusable/DecimalInput.vue";

export default {
  name: "EquipmentAssignment",
  components: { DecimalInput },
  props: {
    equipmentAssignment: {
      type: Object,
      default: null,
    },
    taxTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      editables: null,
      edit: false,
    };
  },
  methods: {
    enableDelete() {
      this.$emit("enableDelete", this.equipmentAssignment);
    },
    editAssignment() {
      if (!isEqual(this.editables, this.equipmentAssignment)) {
        this.$emit("editAssignment", this.editables);
      }
      this.edit = false;
    },
  },
  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.equipmentAssignment));
  },
  computed: {
    ...mapGetters({
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
    }),
  },
};
</script>

<style scoped lang="scss">
input {
  width: 95%;
}
select {
  width: 75%;
  option {
    width: 75%;
  }
}
</style>
