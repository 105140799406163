<template>
  <div class="modal">
    <div class="modal-body" v-on-clickaway="closeModal">
      <div class="modal-header">
        <div class="modal-title">
          <h3>Loo uus tellimus</h3>
        </div>
      </div>
      <div class="modal-content flex-col">
        <div class="flex gap-x-4 mobile:gap-x-0 mb-4 mobile:flex-col">
          <div class="data-container flex-1">
            <div class="data-container-header">
              <h3>Tellimuse detailid</h3>
            </div>
            <div class="data-container-content gwd-form">
              <div class="gwd-form-group">
                <gwd-formlabel
                  title="Pealkiri"
                  :required="true"
                  :valid="!$v.project.title.$invalid"
                  :error="$v.project.title.$error"
                />
                <gwd-textarea
                  v-model="project.title"
                  :error="$v.$error && $v.project.title.$invalid"
                />
              </div>
              <div class="gwd-form-group">
                <gwd-formlabel title="Kirjeldus" :required="false" />
                <gwd-textarea v-model="project.details" :max-length="2048" />
              </div>
              <div class="gwd-form-group mb-2">
                <gwd-formlabel
                  title="Info arve koostamisel"
                  :required="false"
                />
                <gwd-textarea
                  v-model="project.invoice_information_text"
                  :max-length="512"
                />
              </div>
              <div class="gwd-form-group">
                <planning-location-selector
                  v-model="project.location"
                  ref="locationSelector"
                >
                  Tellimuse asukoht
                </planning-location-selector>
              </div>
            </div>
          </div>
          <div class="data-container flex-1">
            <div class="data-container-header gap-x-2">
              <gwd-formlabel
                :heading-level="3"
                title="Klient"
                :required="true"
                :valid="$v.client.name.required"
                :error="$v.client.name.$error"
              />
              <gwd-formlabel title="Erinev maksja" class="ml-auto" />
              <gwd-checkbox v-model="separatePayer" />
            </div>
            <div class="data-container-content nopad">
              <client-selector
                :project="project"
                ref="clientSelector"
                @clientDataUpdated="handleClientDataUpdate"
                @clientContactsUpdated="handleClientContactUpdate"
                @blockSaving="handleBlockSaving"
              />
            </div>
          </div>
          <div class="data-container flex-1" v-if="separatePayer">
            <div class="data-container-header gap-x-2">
              <gwd-formlabel
                :heading-level="3"
                title="Maksja"
                :required="true"
                :valid="payer"
              />
            </div>
            <div class="data-container-content nopad">
              <client-selector
                :project="project"
                :must-be-able-to-pay="true"
                @clientDataUpdated="handlePayerDataUpdate"
                @clientContactsUpdated="handlePayerContactUpdate"
                @blockSaving="handleBlockSaving"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col mb-4 w-full">
          <new-project-revenue-templates
            :client="client"
            :payer="payer"
            ref="projectRevenueTemplates"
          />
        </div>
      </div>
      <div class="modal-bottom-bar">
        <div v-if="!savingBlocked" class="flex items-center">
          <button
            class="mr-3"
            :class="$v.$invalid ? 'btn-disabled' : 'btn-primary'"
            @click="$v.$invalid ? $v.$touch() : saveProject()"
          >
            <span class="icon typcn typcn-tick" />
            <span class="label">Salvesta</span>
          </button>
          <button class="btn-danger mr-3" @click="closeModal">
            <span class="icon typcn typcn-times" />
            <span class="label">Sulge</span>
          </button>
          <button
            class="btn-primary"
            v-if="taskData"
            @click="copyTaskVariables"
          >
            <span class="icon typcn typcn-tick" />
            <span class="label">Kopeeri töö väljad</span>
          </button>
        </div>
        <div v-else>
          <saving-loader
            :saving="savingBlocked"
            :text="{
              saving: 'Salvestamine...',
              saved: 'Salvestatud',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlanningLocationSelector from "@/components/tasks/planning/PlanningLocationSelector.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import ClientSelector from "@/components/company/clients/ClientSelector.vue";
import { mapGetters } from "vuex";
import SavingLoader from "@/components/reusable/SavingLoader.vue";
import NewProjectRevenueTemplates from "@/components/project/templates/NewProjectRevenueTemplates.vue";

export default {
  name: "NewProjectModal",
  props: {
    taskData: { type: Object, default: null },
  },
  components: {
    NewProjectRevenueTemplates,
    SavingLoader,
    ClientSelector,
    PlanningLocationSelector,
  },
  data() {
    return {
      client: null,
      payer: null,
      project: {
        title: "",
        details: "",
        invoice_information_text: "",
        location: null,
        date: null,
        client_is_company: false,
        publish: false,
      },
      savingBlocked: false,
      separatePayer: false,
    };
  },
  methods: {
    closeModal(event) {
      if (
        !event.target.dataset.modalId &&
        !this.$store.state.modals.addClientModal.show
      )
        this.$emit("closeModal");
    },
    saveProject() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.client) this.project.client = this.client.id;
        if (this.payer) this.project.payer = this.payer.id;
        this.project.publish = true;
        this.savingBlocked = true;
        this.apiRequest(
          `company/${this.companyId}/draft/add/`,
          "post",
          true,
          this.project
        )
          .then((res) => {
            if (res.status === 201) {
              this.$emit("projectAdded", res.data);
              this.$refs.projectRevenueTemplates.saveProjectCostTemplates(
                res.data.uuid
              );
              this.$emit("closeModal");
            }
          })
          .catch((err) => {
            this.savingBlocked = false;
            if (
              err.response.status === 409 ||
              { err }.err.response.status === 409
            ) {
              this.$store.dispatch("messageHandler/throwMessage", {
                text: "Salvestamine ebaõnnestus! Klient peab eksisteerima",
                type: "error",
                ttl: 15,
              });
            } else {
              this.$store.dispatch("messageHandler/throwMessage", {
                type: "error",
                error: err,
                text: "Midagi läks valesti",
              });
            }
          });
      }
    },
    copyTaskVariables() {
      this.project.title = this.taskData.title;
      this.project.details = this.taskData.description;
      if (this.taskData.location) {
        this.$refs.locationSelector.selectLocation(this.taskData.location);
      }
    },
    handleClientDataUpdate(e) {
      this.client = e;
    },
    handleClientContactUpdate(e) {
      this.clientContacts = e;
    },
    handlePayerDataUpdate(e) {
      this.payer = e;
    },
    handlePayerContactUpdate(e) {
      this.payerContacts = e;
    },
    handleBlockSaving(e) {
      this.savingBlocked = e;
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
  },
  validations() {
    return {
      project: {
        title: {
          required,
        },
      },
      client: {
        name: {
          required: requiredIf(() => !this.existingClient),
        },
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
