import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store";
import Vuelidate from "vuelidate";
import Vue2Touch from "vue2-touch-events";
import { RequestHandler, UserRoleParser } from "@/assets/mixins/GewodoMixins";
import "@/assets/typicons/typicons.min.css";
import "@/assets/scss/tailwind.scss";
import "leaflet/dist/leaflet.css";
import Checkbox from "@/components/globals/CustomCheckbox";
import FormLabel from "@/components/globals/FormLabel";
import GwdTextarea from "@/components/globals/CustomTextarea";
import IconHeader from "@/components/globals/IconHeader";
import GwdDateSelector from "@/components/globals/DateSelector";
import notifications from "vue-native-notification";
import VueNativeSock from "vue-native-websocket";
// import * as Sentry from "@sentry/vue";
import { directive as onClickaway } from "vue-clickaway2";
import config from "../gewodo.config.json";
import CustomButton from "@/components/globals/CustomButton.vue";

Vue.use(Vuelidate);
Vue.use(Vue2Touch, {
  swipeTolerance: 60,
});
Vue.use(notifications, {
  requestOnNotify: true,
});
Vue.use(VueNativeSock, `${process.env.VUE_APP_WS_URL}profile/`, {
  connectManually: true,
  format: "json",
  store: store,
});

Vue.prototype.config = config;

Vue.directive("onClickaway", onClickaway);

Vue.component("GwdCheckbox", Checkbox);
Vue.component("GwdFormlabel", FormLabel);
Vue.component("GwdTextarea", GwdTextarea);
Vue.component("GwdIconheader", IconHeader);
Vue.component("GwdDateselector", GwdDateSelector);
Vue.component("GwdButton", CustomButton);

Vue.mixin(RequestHandler);
Vue.mixin(UserRoleParser);

if (window.Cypress) {
  window.store = store;
}
Vue.config.productionTip = false;
//
// Sentry.init({
//   Vue,
//   dsn: "https://45ed2b627e994e7db2aef2f707eba4ae@o4504852587020288.ingest.sentry.io/4504852588199936",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "https://gewodo.ava.ee",
//         "https://ava-invoice.gewodo.com",
//       ],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, //If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   enabled: false,
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
