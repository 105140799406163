<template>
  <div
    class="flex flex-col w-full mobile:w-1/4 mobile:justify-center mobile:items-center"
    v-if="
      permissions.includes(userPermission) &&
      accountingPermissions.includes(accountingPermission)
    "
  >
    <router-link
      :to="target"
      class="dashboard-sidebar-item"
      @click.native="handleSameRoute"
    >
      <span class="mobile:order-2">{{ text }}</span>
      <img :src="icon" :alt="`${text} icon`" />
    </router-link>
    <transition name="fade" :duration="100">
      <div
        class="flex flex-col items-start w-full"
        v-if="submenuFiltered.length > 0 && isCurrentRoute"
      >
        <router-link
          v-for="(item, index) in submenuFiltered"
          :key="index"
          :to="item.target"
          class="submenu-item"
        >
          {{ item.title }}
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/assets/mixins/EventBus";

export default {
  props: {
    target: {
      type: String,
      default: "/",
    },
    icon: {
      type: String,
      default: "/bc21/märkmik.svg",
    },
    text: {
      type: String,
      default: "Placeholder",
    },
    permissions: {
      type: Array,
      default: () => {
        return ["R0", "R1", "R2", "R3", "R4", "R5"];
      },
    },
    accountingPermissions: {
      type: Array,
      default: () => ["R0", "R1", "R2", "R3"],
    },
    submenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    handleSameRoute(event) {
      event.preventDefault();

      if (event.metaKey || event.ctrlKey) {
        const route = this.$router.resolve({
          path: this.target,
        });
        window.open(route.href, "_blank");
        return;
      }
      if (this.isCurrentRoute && this.target === "/calendar") {
        EventBus.$emit("resetToDefaultView");
      }
    },
  },
  computed: {
    ...mapGetters({
      userPermission: "companyData/userPermission",
      accountingPermission: "companyData/userAccountingPermission",
    }),
    isCurrentRoute() {
      const isTopRoute = this.$route.fullPath.includes(this.target);
      const isRoute = this.submenu
        .map((x) => x.target)
        .some((x) => x.includes(this.$route.fullPath));
      const isSubRoute = this.submenu
        .map((x) => x.target)
        .some((x) => this.$route.fullPath.includes(x));

      return isRoute || isSubRoute || isTopRoute;
    },
    submenuFiltered() {
      return this.submenu.filter((x) => {
        if (x.accountingPermissions && x.permissions)
          return (
            x.accountingPermissions.includes(this.accountingPermission) &&
            x.permissions.includes(this.userPermission)
          );
        if (x.accountingPermissions)
          return x.accountingPermissions.includes(this.accountingPermission);
        if (x.permissions) return x.permissions.includes(this.userPermission);
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.submenu-item {
  @apply flex p-2 bg-offwhite justify-center text-sm font-medium w-full;
  &:hover {
    @apply bg-offwhite-dark;
  }
  &.router-link-active {
    @apply bg-offwhite-dark border-r-4 border-primary text-primary;
  }
}
</style>
