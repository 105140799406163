<template>
  <div
    class="flex flex-col bg-offwhite rounded cursor-pointer shadow"
    @click="!edit ? (expanded = !expanded) : ''"
  >
    <div
      class="client-contact-card"
      :class="
        expanded
          ? 'bg-offwhite-dark rounded-t'
          : 'hover:bg-offwhite-dark rounded'
      "
    >
      <div class="flex justify-center">
        <div
          class="bg-grey-dark rounded-full h-6 w-6 flex items-center justify-center"
        >
          <img
            src="/bc21/kliendid.svg"
            class="h-4 w-4 filter-to-white"
            alt="Clients icon"
          />
        </div>
      </div>
      <div class="flex">
        <h4>{{ contact.name }}</h4>
      </div>
      <div class="flex">{{ contact.role }}</div>
      <div class="flex">
        <span
          class="typcn-chevron-right typcn transform duration-100"
          :class="expanded ? '-rotate-90' : 'rotate-90'"
        />
      </div>
    </div>
    <div class="flex flex-col px-1 py-2 gap-y-2" v-if="expanded">
      <div v-if="edit" class="flex items-center">
        <div
          class="flex rounded-full p-2 border-primary border-2 items-center justify-center mr-2"
        >
          <img
            src="/bc21/kliendid.svg"
            class="filter-to-primary h-3 w-3"
            alt="Name icon"
          />
        </div>
        <input type="text" v-model="contactCopy.name" class="bg-white" />
      </div>
      <div class="flex items-center" v-if="edit">
        <div
          class="flex rounded-full p-2 border-primary border-2 items-center justify-center mr-2"
        >
          <img
            src="/bc21/dashboard.svg"
            class="filter-to-primary h-3 w-3"
            alt="Role icon"
          />
        </div>
        <input type="text" v-model="contactCopy.role" class="bg-white" />
      </div>
      <div class="flex items-center" v-if="contact.info || edit">
        <div
          class="flex rounded-full p-2 border-primary border-2 items-center justify-center mr-2"
        >
          <img
            src="/bc21/info.svg"
            class="filter-to-primary h-3 w-3"
            alt="Info icon"
          />
        </div>
        <span v-if="!edit">{{ contact.info }}</span>
        <input
          v-else
          type="text"
          class="w-full bg-white"
          v-model="contactCopy.info"
        />
      </div>
      <div class="flex items-center" v-if="contact.telephone_number || edit">
        <div
          class="flex rounded-full p-2 border-primary border-2 items-center justify-center mr-2"
        >
          <img
            src="/bc21/phone.svg"
            class="filter-to-primary h-3 w-3"
            alt="Phone icon"
          />
        </div>
        <a
          v-if="!edit"
          class="hover:text-primary cursor-pointer"
          :href="'tel:' + contact.telephone_number"
          >{{ contact.telephone_number }}
        </a>
        <input
          v-else
          type="text"
          class="bg-white"
          v-model="contactCopy.telephone_number"
        />
      </div>
      <div class="flex items-center w-full" v-if="contact.email || edit">
        <div
          class="flex rounded-full p-2 border-primary border-2 items-center justify-center mr-2"
        >
          <img
            src="/bc21/mail.svg"
            class="filter-to-primary h-3 w-3"
            alt="Mail icon"
          />
        </div>
        <a
          v-if="!edit"
          :href="'mailto:' + contact.email"
          class="hover:text-primary cursor-pointer"
          >{{ contact.email }}
        </a>
        <input v-else class="bg-white w-full" v-model="emailInput" />
      </div>
      <div
        class="flex items-center gap-x-3"
        v-if="(isAdmin || isManager) && !hideButtons"
      >
        <button class="btn-primary" @click.stop="edit = true" v-if="!edit">
          <span class="label">Muuda</span>
        </button>
        <button
          v-if="!edit"
          class="btn-danger"
          @click.stop="deleteContactConfirmation = true"
        >
          <span class="label">Kustuta</span>
        </button>
        <button
          :class="hasChanges ? 'btn-primary' : 'btn-disabled'"
          :disabled="!hasChanges"
          @click.stop="updateContact"
          v-if="edit"
        >
          <span class="label">Salvesta</span>
        </button>
        <button class="btn-danger" @click.stop="clear" v-if="edit">
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
    <confirm-modal
      text="Olete kindel, et soovite kliendi kontakti kustutada?"
      v-if="deleteContactConfirmation"
      @closeModal="deleteContactConfirmation = false"
      @confirm="deleteContact"
    />
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
import { isEqual } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: { ConfirmModal },
  props: {
    contact: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
      deleteContactConfirmation: false,
      edit: false,
      contactCopy: null,
      emailInput: "",
    };
  },
  methods: {
    deleteContact() {
      this.$emit("deleteClientContact", this.contact);
    },
    updateContact() {
      this.$emit("editClientContact", this.contactCopy);
      this.edit = false;
    },
    clear() {
      this.edit = false;
      this.contactCopy = JSON.parse(JSON.stringify(this.contact));
      this.emailInput = this.contactCopy.email;
    },
  },
  mounted() {
    this.contactCopy = JSON.parse(JSON.stringify(this.contact));
    this.emailInput = this.contactCopy.email;
  },
  watch: {
    emailInput() {
      this.contactCopy.email = this.emailLowercased;
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
    }),
    hasChanges() {
      return !isEqual(this.contact, this.contactCopy);
    },
    emailLowercased() {
      return this.emailInput.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.client-contact-card {
  @apply grid items-center gap-x-2 py-1 px-1;
  grid-template-columns: 0.2fr 2fr 1fr 0.3fr;
}
</style>
