<template>
  <div v-if="editables" class="gwd-table-row supplier-cost">
    <span>Planeeritud: {{ planningCost.supplier.name }}</span>
    <div class="flex">
      <span v-if="!edit">{{ planningCost.name }}</span>
      <input v-else type="text" v-model="editables.name" />
    </div>
    <span v-if="!edit">{{ planningCost.amount }} {{ planningCost.unit }}</span>
    <div v-else class="flex items-center">
      <decimal-input v-model="editables.amount" class="ml-auto input-narrow" />
      <select class="input-narrow" v-model="editables.unit">
        <option v-for="unit in units" :value="unit" :key="unit">
          {{ unit }}
        </option>
      </select>
    </div>
    <div v-if="isManager || isAdmin">
      <span v-if="!edit">{{ planningCost.cost }} €</span>
      <decimal-input v-else v-model="editables.cost" class="input-narrow" />
    </div>
    <div v-else>-</div>
    <div v-if="isManager || isAdmin">
      <span v-if="!edit">{{ planningCost.price }} €</span>
      <decimal-input v-else v-model="editables.price" class="input-narrow" />
    </div>
    <div v-else>-</div>
    <div>
      <span v-if="!edit">
        {{ planningCost.vatType ? `${planningCost.vatType.description}` : "-" }}
      </span>
      <select v-else class="input-narrow w-full" v-model="editables.vatType">
        <option
          v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
          :key="taxType.id"
          :value="taxType"
        >
          {{ `${taxType.description}` }}
        </option>
      </select>
    </div>
    <span>{{ round2(planningCost.price * planningCost.amount) }} €</span>
    <div
      v-if="isAdmin || isManager"
      class="flex items-center justify-end gap-x-2"
    >
      <button v-if="!edit" class="btn-primary" @click="convertToCost">
        <span class="label">Kuluks</span>
      </button>
      <button v-if="!edit" class="icon-button primary" @click="edit = true">
        <span class="typcn typcn-edit text-white" />
      </button>
      <button v-if="!edit" class="icon-button danger" @click="deletePlanning">
        <span class="typcn typcn-trash text-white" />
      </button>
      <button v-if="edit" class="btn-primary" @click="updatePlanningCost">
        <span class="label">Salvesta</span>
      </button>
      <button v-if="edit" class="btn-danger" @click="resetEditables">
        <span class="label">Tühista</span>
      </button>
    </div>
  </div>
</template>

<script>
import { round2 } from "@/assets/utils/commonMath";
import { clone } from "lodash";
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { mapGetters } from "vuex";

export default {
  name: "SupplierCostPlanning",
  components: { DecimalInput },
  props: {
    planningCost: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      edit: false,
      editables: null,
    };
  },
  methods: {
    round2,
    deletePlanning() {
      this.$emit("deletePlanningCost", this.planningCost);
    },
    resetEditables() {
      this.editables = clone(this.planningCost);
      this.edit = false;
    },
    updatePlanningCost() {
      this.$emit("updatePlanningCost", this.editables);
      this.edit = false;
    },
    convertToCost() {
      this.$emit("convertToCost", this.planningCost);
    },
  },
  mounted() {
    this.editables = clone(this.planningCost);
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
      units: "companyVariables/units",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
    }),
  },
};
</script>

<style scoped lang="scss">
.input-narrow {
  width: 80%;
}
</style>
