<template>
  <input
    type="text"
    inputmode="decimal"
    :placeholder="placeholder"
    @input="handleInput"
    :value="value"
    ref="inputWindow"
  />
</template>
<script>
export default {
  props: {
    model: {
      type: [Number, String],
      prop: "value",
      event: "change",
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    decimalPoints: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      inputContent: "",
    };
  },
  methods: {
    handleInput(e) {
      const input = e.target;
      const pos = e.target.selectionStart;
      const sanitizedValue = this.sanitizeInput(e.target.value);

      input.value = sanitizedValue;

      this.$emit("input", sanitizedValue);
      this.$emit("change", parseFloat(sanitizedValue) || null);
      this.$nextTick(() => {
        input.setSelectionRange(pos, pos);
      });
    },
    sanitizeInput(value) {
      const separators = [",", "."];
      const validCharacters = /[0-9,.-]/;
      if (value === "." || value === ",") return "0.";
      value = value.replace(",", ".");
      let stringArr = value.split("");
      if (!validCharacters.test(stringArr[stringArr.length - 1])) {
        return value.substring(0, value.length - 1);
      }
      if (stringArr.filter((e) => separators.includes(e)).length > 1) {
        return value.substring(0, value.length - 1);
      }
      if (stringArr.includes(".")) {
        let separatorPos = stringArr.indexOf(".");
        if (value.length > separatorPos + 1 + this.decimalPoints)
          return value.substring(0, separatorPos + 1 + this.decimalPoints);
      }
      return value;
    },
    computed() {},
  },
};
</script>
<style lang="scss"></style>
