<template>
  <div
    v-if="assignment"
    class="flex flex-col w-full"
    v-on-clickaway="
      () => {
        showStartChange = false;
        showEndChange = false;
      }
    "
  >
    <div
      class="gwd-table-row worker-table gap-x-2 pad-sm"
      v-for="(timer, index) in timers"
      :key="timer.id"
    >
      <div
        v-if="index === 0"
        class="flex font-medium cursor-pointer mobile:col-span-5"
        @click="navigateToWorker(assignment.worker.id)"
      >
        {{ assignment.worker.worker_name }}
      </div>
      <span v-else />
      <div v-if="index === 0" class="flex items-center justify-between gap-x-2">
        <time-selector
          v-model="assignment.start_time"
          v-if="assignment.start_time"
          pos="t"
          width="8"
        />
        <span
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          v-else
          @click="
            () =>
              (assignment.start_time = {
                hour: moment(taskData.start_time).hour(),
                minute: moment(taskData.start_time).minute(),
              })
          "
        >
          {{ `${moment(taskData.start_time).format("HH:mm")}` }}
        </span>
        <button
          @click="assignment.start_time = null"
          class="btn-danger w-5"
          v-if="assignment.start_time"
        >
          <span class="typcn typcn-times leading-none" />
        </button>
      </div>
      <div v-else />
      <div v-if="index === 0" class="flex items-center justify-between gap-x-2">
        <time-selector
          v-model="assignment.end_time"
          v-if="assignment.end_time"
          pos="t"
          width="10"
        />
        <span
          v-else
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          @click="
            () =>
              (assignment.end_time = {
                hour: moment(taskData.end_time).hour(),
                minute: moment(taskData.end_time).minute(),
              })
          "
        >
          {{ `${moment(taskData.end_time).format("HH:mm")}` }}
        </span>
        <button
          @click="assignment.end_time = null"
          class="btn-danger w-5"
          v-if="assignment.end_time"
        >
          <span class="typcn typcn-times leading-none" />
        </button>
      </div>
      <div v-else />
      <div class="flex items-center gap-x-1">
        <span
          v-if="!showStartChange"
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          @click="showStartChange = true"
        >
          {{ moment(timer.stopper_start).format("HH:mm") }}
        </span>
        <time-selector v-else v-model="timer.start_time" pos="t" width="10" />
      </div>
      <div class="flex items-center gap-x-1">
        <span
          v-if="!showEndChange"
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          @click="timer.stopper_end ? (showEndChange = true) : ''"
        >
          {{
            timer.stopper_end
              ? moment(timer.stopper_end).format("HH:mm")
              : "Käigus"
          }}
        </span>
        <time-selector v-else v-model="timer.end_time" pos="t" width="10" />
      </div>
      <div class="flex items-center gap-x-1">
        <span
          class="h-3 w-3 bg-green rounded-full"
          title="Töötajal on aktiivne taimer"
          v-if="timers.some((x) => !x.stopper_end)"
        />
        <span>
          {{ parseTimerData([timer], assignment.worker.profile) }}
        </span>
      </div>
      <div class="flex items-center gap-x-1">
        <span> {{ round2(timer.cost * timer.amount) }} € </span>
      </div>
      <div class="flex">
        <div class="flex items-center gap-x-1" v-if="timer.confirmed">
          <span>Kinnitatud</span>
          <span class="typcn typcn-tick" />
        </div>
        <button
          v-if="!timer.confirmed && timer.stopper_end"
          @click="confirmTime(timer)"
          class="btn-primary"
        >
          <span class="label">Kinnita</span>
        </button>
        <button
          v-if="timer.stopper_end === null"
          class="btn-attention"
          @click="endStopperAsManager(timer)"
        >
          Lõpeta
        </button>
      </div>
      <div
        v-if="index === 0"
        class="flex box-border items-center justify-between mobile:justify-start truncate mobile:col-span-5"
      >
        <span class="desktop-hidden h-3 w-3 mr-1 flex-shrink-0">
          <img
            class="h-3 w-3"
            src="/bc21/placeholder.svg"
            alt="location icon"
          />
        </span>
        <span
          v-if="assignment.location"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `location`,
              currLocation: assignment.location,
            })
          "
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
          :title="assignment.location.long_address"
        >
          {{ assignment.location.long_address }}
        </span>
        <span
          v-else
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer w-full"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `location`,
              currLocation: null,
            })
          "
          :title="taskData.location ? taskData.location.long_address : '-'"
        >
          {{ taskData.location ? taskData.location.long_address : "-" }}
        </span>
        <button
          class="btn-danger w-5"
          @click="assignment.location = null"
          v-if="assignment.location"
        >
          <span class="typcn typcn-times" />
        </button>
      </div>
      <div v-else />
      <div
        v-if="index === 0"
        class="flex box-border items-center justify-between mobile:justify-start truncate mobile:col-span-5"
      >
        <span class="desktop-hidden h-3 w-3 mr-1 flex-shrink-0">
          <img
            class="h-full w-full"
            src="/bc21/placeholder.svg"
            alt="location icon"
          />
        </span>
        <span
          v-if="assignment.end_location"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `end_location`,
              currLocation: assignment.end_location,
            })
          "
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full w-full cursor-pointer"
          :title="assignment.end_location.long_address"
        >
          {{ assignment.end_location.long_address }}
        </span>
        <span
          v-else
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer w-full"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `end_location`,
              currLocation: null,
            })
          "
          :title="
            taskData.end_location ? taskData.end_location.long_address : '-'
          "
        >
          {{ taskData.end_location ? taskData.end_location.long_address : "-" }}
        </span>
        <button
          class="btn-danger w-5"
          @click="assignment.end_location = null"
          v-if="assignment.end_location"
        >
          <span class="typcn typcn-times" />
        </button>
      </div>
      <div v-else />
      <div v-if="index === 0" class="flex mobile:col-span-5">
        <gwd-textarea
          v-model="assignment.description"
          placeholder="Kirjeldus"
          class="w-full mobile:mr-3"
        />
        <button
          class="desktop:hidden desktop-hidden ml-auto btn-danger safari-desktop-hide"
          @click="deleteAssignment"
        >
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete worker"
          />
        </button>
      </div>
      <div v-else />
      <div v-if="index === 0" class="flex justify-center mobile:hidden">
        <button class="btn-danger" @click="deleteAssignment">
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete worker"
          />
        </button>
      </div>
      <div v-else />
    </div>
    <div
      v-if="timers.length === 0"
      class="gwd-table-row worker-table gap-x-2 pad-sm"
    >
      <div
        class="flex font-medium cursor-pointer mobile:col-span-5"
        @click="navigateToWorker(assignment.worker.id)"
      >
        {{ assignment.worker.worker_name }}
      </div>
      <div class="flex items-center justify-between gap-x-2">
        <time-selector
          v-model="assignment.start_time"
          v-if="assignment.start_time"
          pos="t"
          width="8"
        />
        <span
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          v-else
          @click="
            () =>
              (assignment.start_time = {
                hour: moment(taskData.start_time).hour(),
                minute: moment(taskData.start_time).minute(),
              })
          "
        >
          {{ `${moment(taskData.start_time).format("HH:mm")}` }}
        </span>
        <button
          @click="assignment.start_time = null"
          class="btn-danger w-5"
          v-if="assignment.start_time"
        >
          <span class="typcn typcn-times leading-none" />
        </button>
      </div>
      <div class="flex items-center justify-between gap-x-2">
        <time-selector
          v-model="assignment.end_time"
          v-if="assignment.end_time"
          pos="t"
          width="10"
        />
        <span
          v-else
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          @click="
            () =>
              (assignment.end_time = {
                hour: moment(taskData.end_time).hour(),
                minute: moment(taskData.end_time).minute(),
              })
          "
        >
          {{ `${moment(taskData.end_time).format("HH:mm")}` }}
        </span>
        <button
          @click="assignment.end_time = null"
          class="btn-danger w-5"
          v-if="assignment.end_time"
        >
          <span class="typcn typcn-times leading-none" />
        </button>
      </div>
      <div class="flex">
        <span
          v-if="!showStartChange"
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          @click="
            () => {
              showStartChange = true;
              showEndChange = true;
            }
          "
        >
          -
        </span>
        <time-selector
          v-if="showStartChange"
          v-model="assignment.newStartTime"
          pos="t"
          width="8"
        />
      </div>
      <div class="flex">
        <span
          v-if="!showEndChange"
          class="w-full h-full hover:bg-offwhite hover:p-1 rounded duration-200 cursor-pointer"
          @click="
            () => {
              showStartChange = true;
              showEndChange = true;
            }
          "
        >
          -
        </span>
        <time-selector
          v-if="showEndChange"
          v-model="assignment.newEndTime"
          pos="t"
          width="8"
        />
      </div>
      <div class="flex">-</div>
      <div class="flex">-</div>
      <div class="flex">
        <button
          v-if="showStartChange && showEndChange"
          class="btn-primary"
          @click="addWorkerTime"
        >
          <span class="label">Lisa aeg</span>
        </button>
      </div>
      <div
        class="flex box-border items-center justify-between mobile:justify-start truncate mobile:col-span-5"
      >
        <span class="desktop-hidden h-3 w-3 mr-1 flex-shrink-0">
          <img
            class="h-3 w-3"
            src="/bc21/placeholder.svg"
            alt="location icon"
          />
        </span>
        <span
          v-if="assignment.location"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `location`,
              currLocation: assignment.location,
            })
          "
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer"
          :title="assignment.location.long_address"
        >
          {{ assignment.location.long_address }}
        </span>
        <span
          v-else
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer w-full"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `location`,
              currLocation: null,
            })
          "
          :title="taskData.location ? taskData.location.long_address : '-'"
        >
          {{ taskData.location ? taskData.location.long_address : "-" }}
        </span>
        <button
          class="btn-danger w-5"
          @click="assignment.location = null"
          v-if="assignment.location"
        >
          <span class="typcn typcn-times" />
        </button>
      </div>
      <div
        class="flex box-border items-center justify-between mobile:justify-start truncate mobile:col-span-5"
      >
        <span class="desktop-hidden h-3 w-3 mr-1 flex-shrink-0">
          <img
            class="h-full w-full"
            src="/bc21/placeholder.svg"
            alt="location icon"
          />
        </span>
        <span
          v-if="assignment.end_location"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `end_location`,
              currLocation: assignment.end_location,
            })
          "
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full w-full cursor-pointer"
          :title="assignment.end_location.long_address"
        >
          {{ assignment.end_location.long_address }}
        </span>
        <span
          v-else
          class="flex truncate py-1 hover:px-2 hover:bg-offwhite duration-200 rounded h-full cursor-pointer w-full"
          @click="
            $store.dispatch(`modals/taskModal/openLocationModal`, {
              assignmentId: assignment.id,
              field: `end_location`,
              currLocation: null,
            })
          "
          :title="
            taskData.end_location ? taskData.end_location.long_address : '-'
          "
        >
          {{ taskData.end_location ? taskData.end_location.long_address : "-" }}
        </span>
        <button
          class="btn-danger w-5"
          @click="assignment.end_location = null"
          v-if="assignment.end_location"
        >
          <span class="typcn typcn-times" />
        </button>
      </div>
      <div class="flex mobile:col-span-5">
        <gwd-textarea
          v-model="assignment.description"
          placeholder="Kirjeldus"
          class="w-full mobile:mr-3"
        />
        <button
          class="desktop:hidden desktop-hidden ml-auto btn-danger safari-desktop-hide"
          @click="deleteAssignment"
        >
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete worker"
          />
        </button>
      </div>
      <div class="flex justify-center mobile:hidden">
        <button class="btn-danger" @click="deleteAssignment">
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete worker"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { parseTimerData } from "@/assets/utils/commonTransforms";
import { round2 } from "@/assets/utils/commonMath";

export default {
  name: "WorkerAssignment",
  components: { TimeSelector },
  props: {
    assignmentId: {
      type: Number,
      required: true,
    },
    timers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment: moment,
      assignment: null,
      showStartChange: false,
      showEndChange: false,
    };
  },
  mounted() {
    this.assignment = this.taskData.sub_tasks.find(
      (x) => x.id === this.assignmentId
    );
  },
  methods: {
    round2,
    parseTimerData,
    confirmTime(timer) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${this.assignment.worker.id}/${timer.cost_id}/confirm/`,
        "post",
        true
      ).then(() => {
        this.$store.dispatch(
          "modals/taskModal/retrieveTimerData",
          this.taskData.id
        );
      });
    },
    navigateToWorker(id) {
      this.$router.push(`/workers/${id}?task_id=${this.taskData.id}`);
      this.$store.dispatch("modals/taskModal/close", this.taskData.id);
    },
    deleteAssignment() {
      this.$store.dispatch(
        "modals/taskModal/removeAssignment",
        this.assignment
      );
      this.$emit("reloadAssignments");
    },
    addWorkerTime() {
      this.apiRequest(`tasks/${this.companyId}/stopper/create/`, "post", true, {
        worker: this.assignment.worker.id,
        stopper_start: moment(
          `${moment(this.taskData.start_time).format("YYYY-MM-DD")} ${
            this.assignment.newStartTime.hour
          }:${String(this.assignment.newStartTime.minute).padStart(2, "0")}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        stopper_end: moment(
          `${moment(this.taskData.start_time).format("YYYY-MM-DD")} ${
            this.assignment.newEndTime.hour
          }:${String(this.assignment.newEndTime.minute).padStart(2, "0")}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        task: this.taskData.id,
        project: this.taskData.related_object?.object_uuid ?? null,
      })
        .then((res) => {
          if (res.status === 201) {
            this.$store.dispatch(
              "modals/taskModal/retrieveTimerData",
              this.taskData.id
            );
            this.saving = false;
            this.showStartChange = false;
            this.showEndChange = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 10,
          });
          this.saving = false;
        });
    },
    endStopperAsManager(timer) {
      this.apiRequest(
        `tasks/${this.taskData.id}/stopper/${timer.id}/stop/`,
        "put",
        true,
        {}
      ).then((res) => {
        if (res.status === 200) {
          this.$store
            .dispatch("modals/taskModal/retrieveTimerData", this.taskData.id)
            .then(() => {
              this.localTimerData = [];
            });
          this.$emit("reloadAssignments");
        }
      });
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
