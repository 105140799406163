var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table"},[_c('div',{staticClass:"gwd-table-header narrow-cost-list"},[_c('span',[_vm._v("Tüüp")]),_c('span',[_vm._v("Kirjeldus")]),_c('span',[_vm._v("Kuupäev")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v("Kulu/Hinna summa")]),_c('span',{staticClass:"flex justify-end"},[_c('button',{staticClass:"btn-primary",on:{"click":_vm.toggleShowAdded}},[_vm._v(" "+_vm._s(_vm.showAdded ? "Peida" : "Näita")+" ")])])]),_vm._l(([
      {
        id: 'resourceCosts',
        costs: _vm.filteredCosts.filter(function (x) { return x.type === 'resourceCost'; }),
        type: 3,
        name: 'Ressurss',
      },
      {
        id: 'supplierCosts',
        costs: _vm.filteredCosts.filter(function (x) { return x.type === 'supplierCost'; }),
        type: 5,
        name: 'Hankija',
      },
      {
        id: 'extraCosts',
        costs: _vm.filteredCosts.filter(function (x) { return x.type === 'extraCost'; }),
        type: 6,
        name: 'Lisatöö',
      },
      {
        id: 'equipmentCosts',
        costs: _vm.filteredCosts.filter(function (x) { return x.type === 'equipmentCost'; }),
        type: 4,
        name: 'Tehnika',
      } ]),function(templateType){return _c('template-cost',{key:templateType.id,staticClass:"flex",attrs:{"id":templateType.id,"template":_vm.templates.find(function (x) { return x.type === templateType.type; }),"costs":templateType.costs,"name-prefix":templateType.name,"added-cost-ids":_vm.addedCostIds,"show-added":_vm.showAdded,"task":null,"combine-costs-active":_vm.combineCostsActive,"combined-costs":_vm.combinedCosts,"mark-as-paid":_vm.markAsPaid,"status":_vm.status,"type":templateType.type},on:{"addItem":_vm.addItem,"selectTemplate":_vm.selectTemplate,"addToCombinedCosts":_vm.addToCombinedCosts}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }