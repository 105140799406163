<template>
  <div v-if="parameters" class="data-container">
    <div class="data-container-header gap-x-3">
      <h3>Kuupäev</h3>
      <button
        @click="prevWeek"
        :class="
          moment(parameters.startDate).isSame(moment(), 'week')
            ? 'btn-disabled'
            : 'btn-primary'
        "
        :disabled="moment(parameters.startDate).isSame(moment(), 'week')"
      >
        Tagasi
      </button>
      <button class="btn-primary" @click="nextWeek">Edasi</button>
      <span>
        Nädal {{ moment(parameters.startDate).week() }} ({{
          moment(parameters.startDate).year()
        }})
      </span>
    </div>
    <div class="data-container-content overflow-x-scroll">
      <div class="date-grid">
        <div
          class="date-container"
          v-for="(date, index) in dateArray"
          :key="index"
          :class="[
            selectedDate &&
            moment(selectedDate).isSame(moment(date.date), 'day')
              ? 'selected'
              : '',
          ]"
          @click="selectDate(date.date)"
        >
          <div class="date-container-text">
            <h4 class="date-text">
              {{ date.date.format("dd") }}
              {{ date.date.format("DD.MM.YYYY") }}
            </h4>
            <div class="grid grid-cols-8 items-center gap-x-1">
              <div
                v-for="(times, hour) in date.occupations"
                :key="hour"
                class="flex w-full h-full justify-center"
                :class="getColorClass(workerAmount - times)"
              >
                {{ workerAmount - times }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      dateArray: null,
      dateAmount: 14,
      startDate: null,
      moment: moment,
      workerAmount: 0,
    };
  },
  async mounted() {
    if (!this.companyWorkers)
      await this.$store.dispatch("companyVariables/retrieveWorkers");

    if (!this.parameters.startDate) {
      this.startDate = moment();
      this.selectDate(this.startDate);
    } else {
      this.startDate = this.parameters.startDate;
    }
    this.generateDateArray();
  },
  methods: {
    getColorClass(times) {
      const percentage = (times / this.workerAmount) * 100;

      if (percentage >= 50) {
        return "bg-green"; // or 'text-green-500' for text color
      } else if (percentage >= 25) {
        return "bg-attention";
      } else if (percentage >= 0) {
        return "bg-copper-light";
      } else {
        return "bg-danger";
      }
    },

    generateDateArray() {
      this.dateArray = [];
      this.apiRequest(
        `calendar/${
          this.companyId
        }/tasks/occupation/?start_date=${this.startDate.format("YYYY-MM-DD")}`,
        "get",
        true
      ).then((res) => {
        for (const [date, occupations] of Object.entries(res.data)) {
          this.dateArray.push({
            date: moment(date),
            occupations: occupations,
          });
        }
        this.workerAmount = this.companyWorkers.filter((x) =>
          ["R4", "R5"].includes(x.permissions)
        ).length;
      });
    },
    selectDate(date) {
      this.$store.dispatch("modals/taskPlanningModal/setDate", date);
    },
    nextWeek() {
      this.startDate.add(1, "week");
      this.generateDateArray();
    },
    prevWeek() {
      if (
        moment(this.startDate)
          .subtract(1, "week")
          .isSameOrBefore(moment(), "week")
      )
        this.startDate = moment();
      else this.startDate.subtract(1, "week");
      this.generateDateArray();
    },
  },
  computed: {
    ...mapState({
      parameters: (state) => state.modals.taskPlanningModal.parameters,
    }),
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      selectedDate: "modals/taskPlanningModal/selectedDate",
      companyWorkers: "companyVariables/companyWorkers",
    }),
  },
};
</script>
<style scoped lang="scss">
.date-grid {
  @apply grid grid-cols-7 bg-offwhite rounded;
  .date-container {
    @apply flex items-center p-2 cursor-pointer hover:bg-primary hover:bg-opacity-90 hover:text-offwhite duration-100 border-r border-offwhite-dark gap-x-2;
    .date-container-text {
      @apply flex flex-col flex-grow;
      .job-count-text {
        @apply text-xs;
      }
    }
    &:nth-child(1) {
      @apply rounded-tl;
    }
    &:nth-child(7) {
      @apply rounded-tr border-none;
    }
    &:nth-child(8) {
      @apply rounded-bl;
    }
    &:nth-child(14) {
      @apply rounded-br border-none;
    }
    &:nth-child(n-7) {
      @apply border-b;
    }
    &.selected {
      @apply bg-primary text-white;
    }
  }
}
</style>
