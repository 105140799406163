<template>
  <div class="gwd-table nested w-full">
    <div class="gwd-table-header revenue-list-row">
      <span>Programmikood</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>Hind</span>
      <span>Summa (-km)</span>
      <span>KM tüüp</span>
      <span>Summa</span>
    </div>
    <div
      v-for="item in revenues"
      :key="item.id"
      class="gwd-table-row flex-col cursor-pointer"
      @click="item.expanded = !item.expanded"
    >
      <div class="flex revenue-list-row">
        <div class="flex pr-2 truncate">
          <span
            class="truncate"
            :title="item.serviceObj && item.serviceObj.service_name"
          >
            {{
              item.serviceObj && item.serviceObj.service_code
                ? `${item.serviceObj.service_code} - ${item.serviceObj.service_name}`
                : "-"
            }}
          </span>
        </div>
        <div class="flex pr-2 truncate">
          <span :title="item.description" class="truncate">{{
            item.description
          }}</span>
        </div>
        <div class="pr-2 flex items-center">
          <span class="pr-2">{{ item.amount }}</span>
          <span class="flex">{{ item.unit }}</span>
        </div>
        <div class="pr-2">
          <span>
            {{
              `${item.price}${currency ? currency.sign : "€"} ${
                currencyRate && currency.code !== "EUR" && currency
                  ? `(${convertCurrency(item.price, currencyRate)}€)`
                  : ""
              }`
            }}
          </span>
        </div>
        <div class="no-hover pr-2">
          <span>
            {{
              `${round2(item.amount * item.price)}${
                currency ? currency.sign : "€"
              }`
            }}
          </span>
        </div>
        <div class="pr-2 truncate">
          <span class="max-w-full truncate">
            {{
              item.taxType
                ? `${item.taxType.description} (${
                    item.taxType.accounting_code
                      ? item.taxType.accounting_code
                      : "-"
                  })`
                : "-"
            }}
          </span>
        </div>
        <div>
          <span>
            {{
              `~${item.total}${currency ? currency.sign : "€"} ${
                currencyRate && currency.code !== "EUR" && currency
                  ? "(" + convertCurrency(item.total, currencyRate) + "€)"
                  : ""
              }`
            }}
          </span>
        </div>
        <button class="icon-button attention" v-if="item.costs.length > 0">
          <span class="typcn typcn-credit-card leading-none text-xl pb-1" />
        </button>
      </div>
      <div
        class="flex flex-col border-b border-bordergrey px-1 pb-2"
        v-if="item.costs.length > 0 && item.expanded"
      >
        <div
          class="grid bg-offwhite items-center font-medium invoice-costs-table"
        >
          <h4>Tüüp</h4>
          <h4>Kirjeldus</h4>
          <h4>Kogus</h4>
          <h4>Kogukulu</h4>
          <h4>Koguhind</h4>
        </div>
        <div
          class="items-center invoice-costs-table nopad"
          v-for="cost in item.costs"
          :key="`${cost.type}${cost.id}`"
          :class="cost.related_invoices.length > 0 ? 'bg-attention/50' : ''"
        >
          <div
            class="flex items-center h-full"
            v-html="parseCostColorAndName(cost.type)"
          />
          <span class="truncate pr-1" :title="cost.description">
            {{ cost.description }}
          </span>
          <div class="flex">
            <span class="mr-1"> {{ cost.amount }}</span>
            <span> {{ cost.unit }}</span>
          </div>
          <span>{{ cost.total_cost }}€</span>
          <span>{{ cost.total_price }}€</span>
        </div>
        <div class="invoice-costs-table nopad">
          <span />
          <span />
          <span />
          <span>
            {{
              round2(
                item.costs.reduce(
                  (prev, curr) => prev + parseFloat(curr.total_cost),
                  0
                )
              )
            }}
            €
          </span>
          <span>
            {{
              round2(
                item.costs.reduce(
                  (prev, curr) => prev + parseFloat(curr.total_price),
                  0
                )
              )
            }}
            €
          </span>
        </div>
      </div>
    </div>

    <!-- Totals Row -->
    <div
      class="gwd-table-row revenue-list-row w-full p-2 bg-offwhite rounded-b"
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div>
        {{ `${listSum}${currency ? currency.sign : "€"} ${listSumConverted}` }}
      </div>
      {{ `${listTax}${currency ? currency.sign : "€"} ${listTaxConverted}` }}
      <div>
        {{
          `${listTotal}${currency ? currency.sign : "€"} ${listTotalConverted}`
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { convertCurrency, round2 } from "@/assets/utils/commonMath";
import CostTypeParser from "@/assets/mixins/CostTypeParser";

export default {
  name: "RevenueWithCostsList",
  props: {
    revenues: {
      type: Array,
      required: true,
    },
    currency: {
      type: Object,
      default: null,
    },
    currencyRate: {
      type: String,
      default: null,
    },
  },
  mixins: [CostTypeParser],
  methods: {
    round2,
    convertCurrency,
  },
  computed: {
    listSum() {
      if (!this.revenues) return 0;
      return this.revenues.length > 0
        ? round2(
            this.revenues.reduce(
              (prev, curr) =>
                parseFloat(prev) + round2(curr.amount * curr.price),
              0
            )
          )
        : 0;
    },
    listTax() {
      if (!this.revenues) return 0;
      let taxes = {};
      for (let revenue of this.revenues) {
        const vat = revenue.taxType
          ? revenue.taxType.tax_percentage ?? "0"
          : "0";
        if (Object.keys(taxes).includes(vat.toString())) {
          taxes[vat] = taxes[vat] + round2(revenue.amount * revenue.price);
        } else {
          taxes[vat] = round2(revenue.amount * revenue.price);
        }
      }
      let taxSum = 0;
      for (const [vatPer, vatSum] of Object.entries(taxes)) {
        if (vatPer === "0") continue;
        taxSum += round2((vatSum * vatPer) / 100);
      }
      return round2(taxSum);
    },
    listTotal() {
      return round2(this.listSum + this.listTax);
    },
    listSumConverted() {
      if (!this.currency || this.currency.code === "EUR" || !this.currencyRate)
        return "";
      return `(${this.convertCurrency(this.listSum, this.currencyRate)}€)`;
    },
    listTotalConverted() {
      if (!this.currencyRate) return "";
      return `(${this.convertCurrency(this.listTotal, this.currencyRate)}€)`;
    },
    listTaxConverted() {
      if (!this.currencyRate) return "";
      return `(${this.convertCurrency(this.listTax, this.currencyRate)}€)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.revenue-list-row {
  @apply grid;
  grid-template-columns: 0.8fr 1fr 0.3fr 0.3fr 0.4fr 0.5fr 0.3fr 0.1fr;
}
.invoice-costs-table {
  @apply grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
}
.revenue-w-costs {
  @apply h-5 w-5 rounded-full absolute flex justify-center items-center font-bold text-white bg-attention;
  top: 0;
  right: 0;
}
</style>
