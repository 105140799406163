export const convertCurrency = (value, rate) => {
  return Math.round((parseFloat(value) / parseFloat(rate)) * 100) / 100;
};

export const round2 = (value) => {
  return DecimalPrecision2.round(value, 2);
};
export const round4 = (value) => {
  return DecimalPrecision2.round(value, 4);
};

export const generateModalKey = () => {
  return String(Math.random() * 10000000);
};
export const DecimalPrecision2 = (function () {
  if (Number.EPSILON === undefined) {
    Number.EPSILON = Math.pow(2, -52);
  }
  if (Math.sign === undefined) {
    Math.sign = function (x) {
      return (x > 0) - (x < 0) || +x;
    };
  }
  return {
    // Decimal round (half away from zero)
    round: function (num, decimalPlaces = 2) {
      const p = Math.pow(10, decimalPlaces || 0);
      return Math.round(num * p * (1 + Number.EPSILON)) / p;
    },
    // Decimal ceil
    ceil: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      return Math.ceil(num * p * (1 - Math.sign(num) * Number.EPSILON)) / p;
    },
    // Decimal floor
    floor: function (num, decimalPlaces) {
      const p = Math.pow(10, decimalPlaces || 0);
      return Math.floor(num * p * (1 + Math.sign(num) * Number.EPSILON)) / p;
    },
    // Decimal trunc
    trunc: function (num, decimalPlaces) {
      return (num < 0 ? this.ceil : this.floor)(num, decimalPlaces);
    },
    // Format using fixed-point notation
    toFixed: function (num, decimalPlaces) {
      return this.round(num, decimalPlaces).toFixed(decimalPlaces);
    },
  };
})();

export const priceWithVat = (cost) => {
  let vatPercentage = cost.vatType ? cost.vatType.tax_percentage : "0";
  if (!vatPercentage) {
    return round2(cost.price * cost.amount);
  }
  return round2(
    cost.price * cost.amount +
      (cost.price * cost.amount * parseFloat(vatPercentage)) / 100
  );
};

export const costWithCoef = (cost) => {
  if (!cost.cost_coefficient) return round2(cost.cost * cost.amount);
  if (cost.cost_coefficient === 0) return 0;
  return round2(cost.cost * cost.amount * cost.cost_coefficient);
};

export const workerTimeWithCoef = (cost) => {
  if (!cost.cost_coefficient) return round2(cost.cost * cost.amount);
  if (cost.cost_coefficient === 0) return 0;
  return round2(cost.cost * cost.real_times.amount * cost.cost_coefficient);
};

export const priceWithVatWorkerTime = (cost) => {
  return round2(
    cost.price * cost.real_times.amount +
      (cost.price *
        cost.real_times.amount *
        parseFloat(cost.vatType ? cost.vatType.tax_percentage : "0")) /
        100
  );
};

export const costWithVatWorkerTime = (cost) => {
  return round2(cost.cost * cost.real_times.amount * cost.cost_coefficient);
};
