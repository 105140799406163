<template>
  <div
    @click="toggleSelectedProject"
    class="gwd-table-row task-project-selector cursor-pointer"
    :class="
      selectedProject === this.project.uuid ? 'bg-primary bg-opacity-10' : ''
    "
  >
    <div v-html="parseProjectStatus(project.status)"></div>
    <h4 class="truncate">{{ project.title }}</h4>
    <span class="truncate">{{ project.client_name }}</span>
    <span class="text-sm truncate">
      {{ project.location ? project.location.long_address : "-" }}
    </span>
    <div class="mobile:hidden">
      <button class="btn-primary" @click.stop.prevent="openProjectNewTab">
        <span class="label">Vaata tellimust</span>
      </button>
    </div>
  </div>
</template>

<script>
import StatusParser from "@/assets/mixins/StatusParser";

export default {
  name: "TaskProject",
  props: {
    project: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isFirst: Boolean,
    selectedProject: { type: String, default: "" },
  },
  mixins: [StatusParser],
  methods: {
    toggleSelectedProject() {
      this.$emit("projectSelected", this.project);
    },
    openProjectNewTab() {
      const routeData = this.$router.resolve({
        path: `/projects/${this.project.uuid}/`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
