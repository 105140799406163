import StoreRequestHandler from "../../../assets/mixins/StoreRequestHandler";

const initialState = {
  page: 1,
  perPage: 10,
  searchCategory: localStorage.getItem("invoiceSearchCategory") ?? "name",
  searchQuery: "",
  searchTimer: null,
  filter: {
    checked: JSON.parse(localStorage.getItem("invoiceFilterChecked")) ?? false,
    unchecked:
      JSON.parse(localStorage.getItem("invoiceFilterUnchecked")) ?? true,
    exported:
      JSON.parse(localStorage.getItem("invoiceFilterExported")) ?? false,
  },
  orderBy: localStorage.getItem("invoiceOrderBy") ?? null,
};

const generateUrlParams = (state) => {
  let params = [];
  if (state.filter.checked && !state.filter.unchecked)
    params.push("&checked=true");
  else if (!state.filter.checked && state.filter.unchecked)
    params.push("&checked=false");

  if (!state.filter.exported) params.push("&e=false");

  if (state.searchQuery) {
    if (state.searchCategory === "name")
      params.push(`&name=${state.searchQuery}`);
    if (state.searchCategory === "invoice_nr")
      params.push(`&invoice_nr=${state.searchQuery}`);
    if (state.searchCategory === "queue_number")
      params.push(`&queue_number=${state.searchQuery}`);
  }

  if (state.orderBy) params.push(`&order_by=${state.orderBy}`);

  return params;
};

export const invoiceList = {
  namespaced: true,
  state: initialState,
  mutations: {
    setPage(state, page) {
      state.page = page;
    },
    setSearchCategory(state, value) {
      state.searchCategory = value;
    },
    setSearchQuery(state, value) {
      state.searchQuery = value;
      if (state.searchTimer) {
        clearTimeout(state.searchTimer);
      }
      state.searchQuery = value;

      // Set new timer to clear after 5 minutes
      state.searchTimer = setTimeout(() => {
        state.searchQuery = "";
      }, 5 * 60 * 1000); // 5 minutes
    },
    setOrderBy(state, value) {
      state.orderBy = value;
    },
  },
  actions: {
    retrieveInvoices({ state, rootGetters }) {
      let url = `invoice/${rootGetters["companyData/activeCompanyUuid"]}/invoices/?e_invoice_checked=true&page=${state.page}`;

      generateUrlParams(state).forEach((x) => (url += x));

      return StoreRequestHandler.apiRequest(url, "get", true);
    },
    setPage({ commit }, page) {
      commit("setPage", page);
    },
    clearSearchQuery({ commit }) {
      commit("setSearchQuery", "");
    },
  },
  getters: {
    page: (state) => state.page,
    filterState: (state) => state.filter,
    orderBy: (state) => state.orderBy,
  },
};
