var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table nested h-full"},[_c('div',{staticClass:"gwd-table-header"},[_vm._v("Lisatööd")]),_c('div',{staticClass:"gwd-table-header",class:_vm.isAdmin || _vm.isManager ? 'extra-cost-table' : 'extra-cost-table-worker'},[_c('span',{staticClass:"mobile:hidden"},[_vm._v("Töötaja")]),_c('span',[_vm._v("Kirjeldus")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v(_vm._s(_vm.isManager ? "Kulu" : "Ühiku hind"))]),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Koefitsent")]):_vm._e(),_c('span',[_vm._v("Töötasu")]),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Kogukulu")]):_vm._e(),_c('span',[_vm._v("Lisainfo")]),_c('span',[_vm._v("Kinnitatud")])]),_c('div',{staticClass:"gwd-table-row-wrapper mobile:overflow-x-hidden"},[_vm._l((_vm.extraWorkPlannings),function(extraCostPlanning){return _c('extra-cost-planning',{key:extraCostPlanning.id,attrs:{"extra-cost-planning":extraCostPlanning},on:{"deletePlanningCost":function (planning) { return (_vm.planningToDelete = planning); },"updatePlanningCost":_vm.updatePlanningCost}})}),(_vm.extraWorkPlannings.length > 0)?_c('div',{staticClass:"gwd-table-header nopad"}):_vm._e(),_vm._l((_vm.taskExtraCosts),function(extraCost){return _c('extra-cost',{key:extraCost.id,class:extraCost.added_by.id !==
        (extraCost.worker ? extraCost.worker.id : _vm.workerId)
          ? 'bg-danger-dark/25'
          : extraCost.editions && extraCost.editions.length > 0
          ? 'bg-attention/25'
          : '',attrs:{"extra-cost":extraCost,"is-admin":_vm.isAdmin,"is-manager":_vm.isManager},on:{"confirmExtraCost":function (e) { return (_vm.costToConfirm = e); },"unconfirm":function (e) { return (_vm.costToUnConfirm = e); },"editCost":_vm.editCost,"deleteCost":_vm.deleteCost,"loadTaskExtraCosts":_vm.loadTaskExtraCosts}})}),(_vm.taskExtraCosts.length === 0 && _vm.extraWorkPlannings.length === 0)?_c('span',{staticClass:"p-2"},[_vm._v(" Määratud lisatöid pole. ")]):_vm._e()],2),_c('div',{staticClass:"gwd-table-bottom-bar gap-x-3"},[(['R0', 'R1', 'R2'].includes(_vm.userPermissions))?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddExtraCostModal', true)}}},[_vm._v(" Planeeri lisatöö ")]):_vm._e(),(
        _vm.isAdmin ||
        _vm.isManager ||
        (['R4', 'R5'].includes(_vm.userPermissions) &&
          _vm.moment(_vm.taskTime.end).diff(_vm.moment(), 'hours') >= -12 &&
          !_vm.taskConfirmed)
      )?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddExtraCostModal', false)}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.isAdmin || _vm.isManager ? "Lisa kulu" : "Lisa tõste/lisatasu"))])]):_vm._e(),(_vm.isAdmin || _vm.isManager)?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddMultipleExtraCostModal')}}},[_c('span',{staticClass:"label"},[_vm._v("Lisa mitmele korraga")])]):_vm._e()]),(_vm.planningToDelete)?_c('delete-confirm-modal',{on:{"confirmDelete":_vm.deletePlannedCost,"closeModal":function($event){_vm.planningToDelete = null}}}):_vm._e(),(_vm.costToConfirm)?_c('confirm-modal',{attrs:{"text":'Olete kindel, et soovite kulu kinnitada?'},on:{"confirm":function($event){return _vm.confirmWorkerExtraCost(_vm.costToConfirm)},"closeModal":function($event){_vm.costToConfirm = null}}}):_vm._e(),(_vm.costToUnConfirm)?_c('delete-confirm-modal',{attrs:{"text":'Olete kindel, et soovite kulu kinnituse maha võtta? Ei peaks aga njah, ei saa tekistada teid. Ma olen silt, mitte politsei'},on:{"confirmDelete":function($event){return _vm.unConfirmWorkerExtraCost(_vm.costToUnConfirm)},"closeModal":function($event){_vm.costToUnConfirm = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }