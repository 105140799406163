var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-container-content"},[_c('div',{staticClass:"flex flex-row items-center mb-2"},[_c('span',{staticClass:"font-medium mr-3"},[_vm._v("Kliendi makse")]),_c('div',{staticClass:"flex flex-grow items-center cursor-pointer duration-100 px-2 py-1 justify-center border-offwhite-dark",class:_vm.paymentInfo.payment_type === 1
          ? 'bg-primary text-white'
          : 'text-grey-dark',on:{"click":function($event){_vm.paymentInfo.payment_type = 1}}},[_vm._v(" Sularaha ")]),_c('div',{staticClass:"flex flex-grow items-center cursor-pointer duration-100 px-2 py-1 justify-center border-l border-offwhite-dark",class:_vm.paymentInfo.payment_type === 2
          ? 'bg-primary text-white'
          : 'text-grey-dark',on:{"click":function($event){_vm.paymentInfo.payment_type = 2}}},[_vm._v(" Kaardimakse ")])]),(_vm.paymentInfo.payment_type)?_c('div',{staticClass:"flex flex-row items-center mb-2"},[_c('decimal-input',{attrs:{"decimal-points":2},model:{value:(_vm.paymentInfo.payment_amount),callback:function ($$v) {_vm.$set(_vm.paymentInfo, "payment_amount", $$v)},expression:"paymentInfo.payment_amount"}}),_c('div',{staticClass:"flex mx-3"},[(!_vm.saving)?_c('button',{class:_vm.paymentInfo.payment_type && _vm.paymentInfo.payment_amount
            ? 'btn-primary'
            : 'btn-disabled',on:{"click":function($event){_vm.paymentInfo.payment_type && _vm.paymentInfo.payment_amount
            ? _vm.setPaymentInformation()
            : ''}}},[_vm._v(" Kinnita ")]):_c('saving-loader',{attrs:{"saving":_vm.saving}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }