<template>
  <div class="gwd-table nested h-full">
    <div class="gwd-table-header">Planeeritud ressurssid</div>
    <div
      class="gwd-table-header gap-x-2"
      :class="
        isManager || isAdmin
          ? 'resource-assignment-table'
          : 'resource-assignment-table-worker'
      "
    >
      <span :class="isAdmin || isManager ? 'mobile:col-span-2' : ''">
        Ressurssi nimi
      </span>
      <span>Kogus</span>
      <span>Tagastatav kogus</span>
      <span :class="isAdmin || isManager ? 'mobile:col-span-2' : ''">
        Info
      </span>
      <span />
    </div>
    <div class="gwd-table-row-wrapper mobile:overflow-x-hidden">
      <div
        v-for="resourceAssignment in taskResources.filter((x) => x.converted)"
        :key="resourceAssignment.id"
        class="gwd-table-row gap-x-2"
        :class="
          isManager || isAdmin
            ? 'resource-assignment-table'
            : 'resource-assignment-table-worker'
        "
      >
        <span> {{ resourceAssignment.resource.name }}</span>
        <span>
          {{ resourceAssignment.assigned_amount }}
          {{ resourceAssignment.resource.unit }}
        </span>
        <span>{{ resourceAssignment.returned_amount }}</span>
        <span type="text" class="mobile:col-span-2">{{
          resourceAssignment.info
        }}</span>
      </div>
      <div
        v-for="resourceAssignment in taskResources.filter((x) => !x.converted)"
        :key="resourceAssignment.id"
        class="gwd-table-row gap-x-2"
        :class="[
          isManager || isAdmin
            ? 'resource-assignment-table'
            : 'resource-assignment-table-worker',
          extraInformationAssignment === resourceAssignment
            ? 'bg-primary/10'
            : '',
        ]"
      >
        <div
          :class="
            isAdmin || isManager ? 'mobile:col-span-2 flex items-center' : ''
          "
        >
          <span v-if="isAdmin || isManager" class="desktop-hidden mr-2">
            Ressurss:
          </span>
          <span>{{ resourceAssignment.resource.name }}</span>
        </div>
        <div class="flex items-center">
          <input
            v-if="(isManager || isAdmin) && !resourceAssignment.converted"
            v-model.number="resourceAssignment.assigned_amount"
            class="mr-3"
            style="width: 50%"
          />
          <span v-else>{{ resourceAssignment.assigned_amount }}</span>
          <span>{{ resourceAssignment.resource.unit }}</span>
        </div>
        <input
          type="number"
          v-model.number="resourceAssignment.returned_amount"
          v-if="
            !resourceAssignment.converted &&
            (isAdmin ||
              isManager ||
              (['R4', 'R5'].includes(userPermissions) &&
                moment(taskTime.end).diff(moment(), 'hours') >= -12))
          "
          @change="
            () => {
              if (resourceAssignment.returned_amount === '')
                resourceAssignment.returned_amount = 0;
            }
          "
        />
        <span v-else>{{ resourceAssignment.returned_amount }} </span>
        <input
          v-if="
            !resourceAssignment.converted &&
            (isAdmin ||
              isManager ||
              (['R4', 'R5'].includes(userPermissions) &&
                moment(taskTime.end).diff(moment(), 'hours') >= -12))
          "
          type="text"
          v-model="resourceAssignment.info"
          :class="isAdmin || isManager ? 'mobile:col-span-2' : ''"
        />
        <span v-else type="text">{{ resourceAssignment.info }}</span>
        <div
          class="flex w-full justify-end gap-x-1 mobile:col-span-2"
          v-if="!resourceAssignment.converted"
        >
          <button
            v-if="isAdmin || isManager"
            @click="toggleResource(resourceAssignment)"
            class="btn-primary"
          >
            <span
              v-if="
                selectedResources
                  .map((x) => x.id)
                  .includes(resourceAssignment.id)
              "
            >
              Eemalda valikust
            </span>
            <span v-else> Muuda kuluks </span>
          </button>
          <button
            v-if="isAdmin || isManager"
            @click.stop.prevent="assignmentToDelete = resourceAssignment"
            class="h-6 w-6 bg-danger hover:bg-danger-dark rounded shadow flex items-center justify-center"
          >
            <img
              src="/bc21/trash.svg"
              class="filter-to-white h-5 w-5"
              alt="Delete assignment"
            />
          </button>
          <button
            v-if="isAdmin || isManager"
            @click="toggleExtraAssignmentInformation(resourceAssignment)"
            class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
          >
            <img src="/bc21/info.svg" class="h-4 w-4 filter-to-white" alt="?" />
          </button>
        </div>
      </div>
      <span v-if="taskResources.length === 0" class="p-2">
        Määratud ressursse pole.
      </span>
      <div
        v-if="extraInformationAssignment"
        class="flex flex-col border-b border-t border-offwhite-dark p-2 bg-offwhite"
      >
        <div class="grid grid-cols-2 font-medium">
          <span>Lisaja</span>
          <span>Töö aeg</span>
        </div>
        <div class="grid grid-cols-2">
          <span>{{
            extraInformationAssignment.added_by
              ? extraInformationAssignment.added_by.worker_name
              : "-"
          }}</span>
          <span>{{
            moment(extraInformationAssignment.occurrence_time).format(
              "DD.MM.YYYY HH:mm"
            )
          }}</span>
        </div>
      </div>
      <div
        class="gwd-table-header mt-2"
        v-if="selectedResources && selectedResources.length > 0 && isManager"
      >
        Valitud ressurssid
      </div>
      <div
        class="gwd-table-header grid-cols-5"
        v-if="selectedResources && selectedResources.length > 0 && isManager"
      >
        <span>Nimi</span>
        <span>Kogus</span>
        <span>Tagastatav kogus</span>
        <span>Kulu</span>
        <span>Hind</span>
      </div>
      <div
        class="gwd-table-row grid-cols-5 w-full"
        v-for="resourceAssignment in selectedResources"
        :key="`res${resourceAssignment.id}`"
      >
        <span>{{ resourceAssignment.resource.name }}</span>
        <span>
          {{
            resourceAssignment.assigned_amount -
            resourceAssignment.returned_amount
          }}
          {{ resourceAssignment.resource.unit }}
        </span>
        <span>{{ resourceAssignment.returned_amount }}</span>
        <span>{{ resourceAssignment.resource.cost }}</span>
        <span>{{ resourceAssignment.resource.price }}</span>
      </div>

      <div
        class="gwd-table-bottom-bar"
        v-if="selectedResources && selectedResources.length > 0 && isManager"
      >
        <button class="btn-primary" @click="createResourceCosts">
          <span class="label">Salvesta kuludeks</span>
        </button>
      </div>
      <div class="gwd-table-header mt-2" v-if="isManager">Ressurssi kulud</div>
      <div
        v-if="taskResourceCosts && isManager"
        class="gwd-table-header resource-cost-table"
      >
        <span>Ressurssi kulu nimi</span>
        <span>Kogus</span>
        <span>Kulu</span>
        <span>Hind</span>
        <span>Käibemaks</span>
        <span class="flex" v-if="isAdmin || isManager">Kulu/Hinna summa</span>
      </div>
      <compact-cost
        class="resource-cost-table"
        v-for="resourceCost in taskResourceCosts"
        :key="resourceCost.id"
        :tax-types="taxTypes"
        :cost="resourceCost"
        :related-separate="false"
        @costsUpdated="loadTaskResourceCosts"
      />
      <div
        v-if="taskResourceCosts && taskResourceCosts.length === 0 && isManager"
        class="p-2"
      >
        Kulud puuduvad
      </div>
    </div>

    <div
      class="gwd-table-bottom-bar gap-x-3"
      v-if="selectedResources.length === 0"
    >
      <button
        v-if="
          !resourcesChanged &&
          (isAdmin ||
            isManager ||
            (['R4', 'R5'].includes(userPermissions) &&
              moment(taskTime.end).diff(moment(), 'hours') >= -12 &&
              !taskConfirmed))
        "
        class="btn-primary"
        @click="
          $store.commit('modals/taskModal/openAddResourceAssignmentModal')
        "
      >
        <span class="label">{{
          isManager ? "Planeeri ressurss" : "Lisa pakkematerjal"
        }}</span>
      </button>
      <button
        class="btn-primary"
        @click="$store.commit('modals/taskModal/openAddResourceCostModal')"
        v-if="!resourcesChanged && (isManager || isAdmin)"
      >
        <span class="label">Lisa kulu</span>
      </button>
      <button
        class="btn-primary"
        v-if="resourcesChanged"
        :class="noResourcesOverAssignedAmount ? 'btn-primary' : 'btn-disabled'"
        @click="
          noResourcesOverAssignedAmount ? updateResourceAssignments() : ''
        "
      >
        <span class="label">Salvesta</span>
      </button>
    </div>
    <delete-confirm-modal
      v-if="assignmentToDelete"
      text="Olete kindel, et soovite planeeritud ressurssi kustutada?"
      @confirmDelete="deleteResourceAssignment"
      @closeModal="assignmentToDelete = null"
    />
    <delete-confirm-modal
      v-if="costToDelete"
      text="Olete kindel et soovite kulu kustutada?"
      @confirmDelete="deleteResourceCost"
      @closeModal="costToDelete = null"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isEqual } from "lodash";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { costWithCoef, priceWithVat, round2 } from "@/assets/utils/commonMath";
import set from "lodash/set";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import moment from "moment";
import CompactCost from "@/components/tasks/costs/CompactCost.vue";
import { costUtil } from "@/assets/utils/commonCostMethods";

export default {
  name: "TaskResourceAssignments",
  components: { CompactCost, DeleteConfirmModal },
  props: {
    taskId: {
      type: String,
      default: null,
    },
    taskConfirmed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taskResources: [],
      taskResourcesCopy: [],
      taskResourcesLoaded: false,
      newResourceAssignment: false,
      selectedResources: [],
      newResourceCost: false,
      assignmentToDelete: null,
      costToDelete: null,
      moment: moment,
      extraInformationAssignment: null,
      taskResourceCosts: [],
    };
  },
  methods: {
    costWithCoef,
    priceWithVat,
    round2,
    toggleExtraAssignmentInformation(cost) {
      if (this.extraInformationAssignment === cost) {
        this.extraInformationAssignment = null;
      } else {
        this.extraInformationAssignment = cost;
      }
    },
    loadTaskResources() {
      this.taskResources = [];
      this.taskResourcesCopy = [];
      this.apiRequest(
        `template/${this.companyId}/resources/task/${this.taskId}/`,
        "get",
        true
      ).then((res) => {
        this.taskResources = res.data;
        this.taskResourcesCopy = JSON.parse(JSON.stringify(res.data));
      });
    },
    editCost(e) {
      costUtil.edit
        .call(this, e)
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskResourceCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          })
        );
    },
    async markAsSelfCost(e) {
      try {
        const { status } = await costUtil.selfCost.call(this, e);
        if (status === 200) this.loadTaskResourceCosts();
      } catch (err) {
        await this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          error: err,
          text: "Midagi läks valesti",
          ttl: 5,
        });
      }
    },
    loadTaskResourceCosts() {
      if (this.isManager) {
        this.apiRequest(
          `costs/${this.companyId}/resource/costs/?task=${this.taskId}`,
          "get",
          true
        ).then((res) => {
          this.taskResourceCosts = res.data;
          for (let resourceCost of this.taskResourceCosts) {
            if (resourceCost.vat)
              set(
                resourceCost,
                "vatType",
                this.taxTypes.find((x) => x.id === resourceCost.vat)
              );
          }
        });
      }
    },
    updateResourceAssignments() {
      this.apiRequest(
        `template/${this.companyId}/task/${this.taskId}/resources/edit/`,
        "patch",
        true,
        this.taskResources
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskResources();
        }
      });
    },
    toggleResource(e) {
      if (
        this.selectedResources.find((item) => item.id === e.id) !== undefined
      ) {
        this.selectedResources.splice(
          this.selectedResources.indexOf(
            this.selectedResources.find((item) => item.id === e.id)
          ),
          1
        );
      } else {
        this.selectedResources.push(e);
      }
    },
    createResourceCosts() {
      let data = {
        task: this.taskId,
        assignments: this.selectedResources.map((x) => {
          return {
            resource: x.resource.id,
            id: x.id,
            info: x.info,
            task: this.taskId,
            returned_amount: x.returned_amount,
          };
        }),
      };
      this.apiRequest(
        `costs/${this.companyId}/resources/costs/create/`,
        "post",
        true,
        data
      ).then((res) => {
        if (res.status === 201) {
          this.loadTaskResources();
          this.loadTaskResourceCosts();
          this.selectedResources = [];
        }
      });
    },
    deleteResourceAssignment() {
      if (!this.assignmentToDelete) return;
      this.apiRequest(
        `template/${this.companyId}/resources/${this.assignmentToDelete.resource.id}/assignments/${this.assignmentToDelete.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) this.loadTaskResources();
      });
    },
    deleteResourceCost(e) {
      let cost;
      if (!this.costToDelete && !e) return;
      if (!this.costToDelete) cost = e;
      else cost = this.costToDelete;
      costUtil.deleteCost
        .call(this, cost)
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskResourceCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Kulu kustutamine ebaõnnestus!",
            ttl: 5,
          })
        );
    },
  },
  async mounted() {
    if (this.isManager && !this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    this.loadTaskResources();
    this.loadTaskResourceCosts();
    EventBus.$on("resourcesAssigned", () => this.loadTaskResources());
    EventBus.$on("costsUpdated", () => this.loadTaskResourceCosts());
  },
  beforeDestroy() {
    EventBus.$off("resourcesAssigned");
    EventBus.$off("costsUpdated");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      taxTypes: "companyVariables/taxTypes",
      taskTime: "modals/taskModal/taskTime",
      userPermissions: "companyData/userPermission",
    }),
    resourcesChanged() {
      return !isEqual(this.taskResources, this.taskResourcesCopy);
    },
    noResourcesOverAssignedAmount() {
      return (
        this.taskResources.filter((x) => x.returned_amount > x.assigned_amount)
          .length === 0
      );
    },
  },
};
</script>
<style scoped lang="scss">
.resource-assignment-table {
  //                     name amount ret   info  confirm
  grid-template-columns: 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr;
  @media screen and (max-width: 601px) {
    @apply flex-wrap border-b border-b-offwhite-dark w-full;
    grid-template-columns: 0.5fr 0.5fr;
    row-gap: 0.5rem;
  }
}

.resource-cost-table {
  //                     name amount cost costSum price tax total ret   info  confirm
  grid-template-columns: 0.5fr 0.2fr 0.2fr 0.3fr 0.2fr 0.3fr 0.1fr;
}
.resource-assignment-table-worker {
  //                     name amount ret   info  confirm
  grid-template-columns: 0.5fr 0.2fr 0.1fr 0.2fr;
  @media screen and (max-width: 601px) {
    @apply flex-wrap m-1 border-b border-b-offwhite-dark w-full;
    grid-template-columns: 0.5fr 0.5fr;
  }
}
input {
  width: 95%;
}
</style>
