<template>
  <div class="flex flex-col mobile:border-b-2 mobile:border-grey-light">
    <div
      class="grid p-2 worker-pay-cost-table mobile:grid-cols-3 mobile:border-b mobile:border-offwhite-dark"
      :class="showEdits ? 'pb-2' : ''"
    >
      <div
        class="flex cursor-pointer mobile:col-span-3"
        @click="navigateToWorker"
      >
        {{ workerCost.worker_name }}
      </div>
      <div class="flex items-center" v-if="startDate">
        <gwd-dateselector
          v-if="editMode"
          v-model="startDate"
          @dateChanged="handleDateChange"
        />
        <span v-else>{{ parseStartDate(startDate) }}</span>
      </div>
      <div class="flex items-center" :class="editMode ? '' : 'justify-start'">
        <span v-if="!editMode">
          {{ parseTime(workerCost.real_times.start_time) }}</span
        >
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter((x) => x.field_name === 'stopper_start')
              .length > 0
          "
          class="typcn typcn-info text-danger cursor-pointer ml-2"
          @click="showEdits = !showEdits"
        />
        <time-selector
          v-if="editMode"
          class="flex flex-row w-full justify-start"
          :value="startTime"
          :full-minutes="true"
          @input="handleStartTimeChange"
          :is-white="false"
          :is-moment="true"
          :class="startTime > endTime ? 'wrong-time' : ''"
        />
      </div>
      <div class="flex items-center" :class="editMode ? '' : 'justify-start'">
        <span v-if="workerCost.real_times.end_time === null">Töötab</span>
        <span v-if="!editMode && workerCost.real_times.end_time !== null">
          {{ parseTime(workerCost.real_times.end_time) }}</span
        >
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter((x) => x.field_name === 'stopper_end')
              .length > 0
          "
          class="typcn typcn-info text-danger ml-2 cursor-pointer"
          @click="showEdits = !showEdits"
        />
        <time-selector
          v-if="editMode && workerCost.real_times.end_time !== null"
          :full-minutes="true"
          class="flex flex-row w-full justify-start"
          :value="endTime"
          @input="handleEndTimeChange"
          :is-white="false"
          :is-moment="true"
          :class="startTime > endTime ? 'wrong-time' : ''"
        />
      </div>
      <div class="flex items-center">
        <span>{{ round2(workerCost.real_times.amount) }} h</span>
      </div>
      <div v-if="isAdmin || isManager" class="flex items-center">
        <decimal-input v-if="editMode" v-model="hourlyCost" />
        <span v-else>{{ workerCost.cost }}€</span>
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter((x) => x.field_name === 'cost').length >
              0
          "
          class="typcn typcn-info text-danger ml-2 cursor-pointer"
          @click="showEdits = !showEdits"
        />
      </div>
      <div class="flex items-center">
        <span>
          {{ round2(workerCost.cost * workerCost.real_times.amount) }} €
        </span>
      </div>
      <div v-if="isAdmin || isManager" class="flex items-center">
        <decimal-input v-if="editMode" v-model="costCoefficient" />
        <span v-else>{{ workerCost.cost_coefficient }}</span>
        <span
          v-if="
            !editMode &&
            workerCost.editions.filter(
              (x) => x.field_name === 'cost_coefficient'
            ).length > 0
          "
          class="typcn typcn-info text-danger ml-2 cursor-pointer"
          @click="showEdits = !showEdits"
        />
      </div>
      <div class="flex items-center">
        <span>{{ workerTimeWithCoef(workerCost) }} €</span>
      </div>
      <div
        class="block items-center truncate mobile:col-span-3 overflow-ellipsis"
      >
        <span :title="workerCost.info">{{ workerCost.info }}</span>
      </div>
      <div
        class="flex items-center justify-end gap-x-1 mobile:col-span-3"
        v-if="['R0', 'R1', 'R2'].includes(userRole)"
      >
        <button
          class="btn-primary"
          v-if="!workerCost.confirmed"
          @click="confirmCost"
        >
          Kinnita
        </button>
        <span v-else class="typcn typcn-tick"> Kinnitatud </span>
        <button
          class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
          v-if="!editMode && !workerCost.confirmed"
          @click="editMode = !editMode"
        >
          <span class="typcn-edit typcn icon text-white" />
        </button>
        <button
          class="btn-primary"
          v-if="editMode && !workerCost.confirmed"
          @click="saveTime"
        >
          <span class="typcn-tick typcn icon" />
        </button>
        <button
          v-if="!workerCost.confirmed"
          @click="isDeleteModalActive = true"
          class="h-6 w-6 bg-danger hover:bg-danger-dark rounded shadow flex items-center justify-center"
        >
          <img
            src="/bc21/trash.svg"
            class="h-4 w-4 filter-to-white"
            alt="Delete icon"
          />
        </button>
        <button
          @click="showExtraInformation = !showExtraInformation"
          class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
        >
          <img
            src="/bc21/info.svg"
            class="h-4 w-4 filter-to-white"
            alt="Info"
          />
        </button>
      </div>
    </div>
    <div
      v-if="showExtraInformation"
      class="flex flex-col border-t border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-5 mobile:grid-cols-2 font-medium">
        <span>Lisaja</span>
        <span>Töö aeg</span>
        <span>Kinnitaja</span>
        <span>Kinnitamise aeg</span>
      </div>
      <div class="grid grid-cols-5 mobile:grid-cols-2">
        <span>{{
          workerCost.added_by ? workerCost.added_by.worker_name : "-"
        }}</span>
        <span>{{
          moment(workerCost.occurrence_time).format("DD.MM.YYYY HH:mm")
        }}</span>
        <span>{{
          workerCost.confirmed
            ? workerCost.confirmed_by.worker_name
            : "Kinnitamata"
        }}</span>
        <span>{{
          workerCost.confirmed_time
            ? moment(workerCost.confirmed_time).format("DD.MM.YYYY HH:mm")
            : "-"
        }}</span>
        <div class="flex items-center gap-x-2 justify-end">
          <div v-html="parseCostStatus(workerCost.status)" />

          <button
            v-if="[0, 1].includes(workerCost.status)"
            class="icon-button grey"
            title="Muuda omakuluks"
            @click="selfCostActive = true"
          >
            <span class="typcn typcn-home icon text-white" />
          </button>
          <div
            v-if="isAdmin && workerCost.confirmed"
            class="flex justify-center mobile:justify-end mobile:col-span-2"
          >
            <button class="btn-dangerdark ml-auto" @click="unconfirm">
              <span class="label">Võta kinnitus maha</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        showEdits || (showExtraInformation && workerCost.editions.length > 0)
      "
      class="flex flex-col border-t border-b border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-6 font-medium mobile:grid-cols-2">
        <span>Muutja</span>
        <span>Väli</span>
        <span class="col-span-2 mobile:col-span-1">Vana väärtus</span>
        <span class="col-span-2 mobile:col-span-1">Uus väärtus</span>
      </div>
      <div
        v-for="item in workerCost.editions"
        :key="item.id"
        class="grid grid-cols-6 mobile:grid-cols-2"
      >
        <div>{{ item.editor }}</div>
        <div>
          {{ translateFieldName(item.field_name) }}
        </div>
        <div class="col-span-2 mobile:col-span-1">
          {{ transformFieldValue(item.field_name, item.initial_value) }}
        </div>
        <div class="col-span-2 mobile:col-span-1">
          {{ transformFieldValue(item.field_name, item.new_value) }}
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="selfCostActive"
      :header-text="'Kulu muutmine omakuluks'"
      :text="'Kas olete kindel et soovite omakuluks muuta?'"
      @confirm="markAsSelfCost"
      @closeModal="selfCostActive = false"
    />
    <delete-confirm-modal
      v-if="isDeleteModalActive"
      @confirmDelete="deleteTime()"
      @closeModal="isDeleteModalActive = false"
    />
  </div>
</template>
<script>
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";

import moment from "moment";
import { ee } from "vuejs-datepicker/dist/locale";
import TimeSelector from "@/components/reusable/TimeSelector.vue";
import {
  costWithVatWorkerTime,
  priceWithVatWorkerTime,
  round2,
  workerTimeWithCoef,
} from "@/assets/utils/commonMath";
import { mapGetters } from "vuex";
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import {
  transformFieldValue,
  translateFieldName,
} from "@/assets/utils/commonTransforms";
import StatusParser from "@/assets/mixins/StatusParser";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
import { costUtil } from "@/assets/utils/commonCostMethods";
export default {
  name: "WorkerCost",
  components: { ConfirmModal, DecimalInput, DeleteConfirmModal, TimeSelector },
  props: {
    workerCost: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      startTime: null,
      endTime: null,
      editMode: false,
      moment: moment,
      ee: ee,
      showEdits: false,
      showExtraInformation: false,
      isDeleteModalActive: false,
      hourlyCost: null,
      hourlyPay: null,
      costCoefficient: null,
      vatType: null,
      selfCostActive: false,
    };
  },
  mixins: [StatusParser],
  mounted() {
    this.startTime = moment(this.workerCost.real_times.start_time);
    this.endTime = moment(this.workerCost.real_times.end_time);
    this.startDate = moment(this.workerCost.real_times.start_time);
    this.hourlyCost = this.workerCost.cost;
    this.hourlyPay = this.workerCost.price;
    this.costCoefficient = this.workerCost.cost_coefficient;
    this.vatType = this.workerCost.vatType;
  },
  methods: {
    workerTimeWithCoef,
    transformFieldValue,
    translateFieldName,
    costWithVatWorkerTime,
    priceWithVatWorkerTime,
    round2,
    handleStartTimeChange(e) {
      this.startTime.hour(e.hour).minute(e.minute);
    },
    handleEndTimeChange(e) {
      this.endTime.hour(e.hour).minute(e.minute);
    },
    handleDateChange(e) {
      this.startDate = moment(e);
    },
    parseStartDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    parseTime(date) {
      return moment(date).format("HH:mm");
    },
    parseDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formatTime(time) {
      return this.startDate
        .hour(time.hour())
        .minute(time.minute())
        .format("YYYY-MM-DD[T]HH:mm:ssZ");
    },
    confirmCost() {
      this.$emit("confirm", this.workerCost);
    },
    unconfirm() {
      this.$emit("unconfirm", this.workerCost);
    },
    navigateToWorker() {
      this.$router.push(
        `/workers/${this.workerCost.worker_id}?task_id=${this.workerCost.task.id}`
      );
      this.$store.dispatch("modals/taskModal/close", this.workerCost.task.id);
    },
    async markAsSelfCost() {
      try {
        const { status } = await costUtil.selfCost.call(this, this.workerCost);
        if (status === 200) this.$emit("loadTaskWorkerCosts");
        this.selfCostActive = false;
      } catch (err) {
        this.selfCostActive = false;
        await this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          error: err,
          text: "Midagi läks valesti",
          ttl: 5,
        });
      }
    },
    saveTime() {
      if (this.startTime > this.endTime || this.hourlyPay < 0) {
        return false;
      }
      const changedFields = {
        id: this.workerCost.time_tracking_id,
        workerId: this.workerCost.worker_id,
      };

      if (
        this.parseTime(this.startTime) !==
          this.parseTime(this.workerCost.real_times.start_time) ||
        this.parseDate(this.startDate) !==
          this.parseDate(this.workerCost.real_times.start_time) ||
        this.parseTime(this.endTime) !==
          this.parseTime(this.workerCost.real_times.end_time)
      ) {
        changedFields.startTime = this.formatTime(this.startTime);
        changedFields.endTime = this.formatTime(this.endTime);
      }

      if (this.workerCost.price !== this.hourlyPay) {
        changedFields.hourlyPay = this.hourlyPay;
      }

      if (this.workerCost.cost !== this.hourlyCost) {
        changedFields.hourlyCost = this.hourlyCost;
      }

      if (this.workerCost.cost_coefficient !== this.costCoefficient) {
        changedFields.costCoefficient = this.costCoefficient;
      }

      if (this.workerCost.vatType !== this.vatType) {
        changedFields.vatType = this.vatType;
      }

      // Check if there are any changes (beyond the required ID fields)
      if (Object.keys(changedFields).length > 2) {
        changedFields.startTime = this.formatTime(this.startTime);
        changedFields.endTime = this.formatTime(this.endTime);
        this.$emit("update", changedFields);
        this.editMode = false;
      } else {
        this.editMode = false;
      }
    },

    deleteTime() {
      this.$emit(
        "delete",
        this.workerCost.worker_id,
        this.workerCost.time_tracking_id
      );
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      userRole: "companyData/userPermission",
    }),
  },
};
</script>
