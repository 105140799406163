<template>
  <div class="flex flex-col w-full h-full">
    <div class="flex flex-col h-full bg-white">
      <div class="gwd-table h-full">
        <div class="gwd-table-header tasks-costs-table gap-x-2">
          <span></span>
          <span>Nimetus</span>
          <span>Kogus</span>
          <span>Ühikukulu</span>
          <span>Kulu*kogus</span>
          <span>Koefitsent</span>
          <span>Kogukulu</span>
          <span>Info</span>
          <span>Staatus</span>
          <span></span>
        </div>
        <div v-show="taskCosts.length > 0" class="gwd-table-row-wrapper mt-1">
          <task-cost
            class="mb-1"
            v-for="cost in taskCosts"
            :key="`${cost.type}${cost.id}`"
            :cost="cost"
            @editCost="editCost"
            @deleteCost="setCostToDelete"
            @confirm="(e) => (costToConfirm = e)"
          />
        </div>
        <div v-if="taskCosts.length === 0" class="inline-block p-3">
          <span class="p-2">Kulud puuduvad</span>
        </div>
        <div class="gwd-table-bottom-bar gap-x-3">
          <button
            v-if="$store.state.modals.taskModal.taskData.related_object"
            class="btn-primary"
            @click="routeToOutgoingInvoiceCreation"
          >
            <span class="label">Arve koostamisse</span>
          </button>
          <button
            v-if="$store.state.modals.taskModal.taskData.related_object"
            class="btn-primary"
            @click="routeToOutgoingInvoiceGeneratorView"
          >
            <span class="label">Arve kulude põhjal</span>
          </button>
          <div class="flex items-center gap-x-2">
            <gwd-formlabel title="Kuva kustutatuid" />
            <gwd-checkbox v-model="showDeleted" size="6" />
          </div>
          <div
            v-if="uniqueInvoices.length > 0"
            class="flex items-center gap-x-1 ml-auto"
          >
            <span
              class="p-1 cursor-pointer inline-block rounded font-medium"
              :class="
                invoice.manually_checked ? 'bg-green' : 'bg-pending text-white'
              "
              v-for="invoice in uniqueInvoices"
              :key="invoice.id"
              @click="routeToOutgoingInvoice(invoice)"
            >
              Arve
              {{
                invoice.manually_checked
                  ? `nr: ${invoice.invoice_nr}`
                  : `id: ${invoice.id}`
              }}
            </span>
          </div>
          <div
            class="flex items-center gap-x-3"
            :class="{ 'ml-auto': uniqueInvoices.length === 0 }"
          >
            <h4>Kogukulu</h4>
            <span>
              {{ round2(profits.total_cost) }}
              {{ $store.state.userData.user.currency }}
            </span>
            <h4>Kogutulu</h4>
            <span>
              {{ round2(profits.total_revenue) }}
              {{ $store.state.userData.user.currency }}
            </span>
            <h4>Kasum (Marginaal %)</h4>
            <span v-html="calculatedMarginals" />
          </div>
        </div>
      </div>
    </div>

    <confirm-modal
      v-if="costToConfirm"
      :text="'Olete kindel, et soovite töötaja kulu kinnitada?'"
      @confirm="confirmCost"
      @closeModal="costToConfirm = null"
    />
    <delete-confirm-modal
      v-if="costToDelete"
      text="Olete kindel et soovite kulu kustutada?"
      @confirmDelete="deleteCost"
      @closeModal="costToDelete = null"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TaskCost from "@/components/project/costs/table/TaskCost.vue";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import { round2 } from "@/assets/utils/commonMath";

export default {
  name: "TaskTotalCosts",
  components: { DeleteConfirmModal, ConfirmModal, TaskCost },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      taskCosts: [],
      costToConfirm: null,
      costToDelete: null,
      profits: {
        total_cost: null,
        total_revenue: null,
      },
      uniqueInvoices: [],
      showDeleted: false,
    };
  },
  async mounted() {
    this.listTaskTotalCosts();
    this.retrieveTaskProfits();
  },
  methods: {
    round2,
    async routeToOutgoingInvoiceCreation() {
      await this.$store.dispatch("invoice/setData", {
        projectId:
          this.$store.state.modals.taskModal.taskData.related_object
            .object_uuid,
        client:
          this.$store.state.modals.taskModal.taskData.related_object.client_id,
      });
      sessionStorage.removeItem("taskId");
      await this.$router.push("/invoices/outgoing/add");
      await this.$store.dispatch("modals/taskModal/close");
    },
    async routeToOutgoingInvoiceGeneratorView() {
      sessionStorage.removeItem("taskId");
      await this.$router.push(
        `/invoices/generator/${this.$store.state.modals.taskModal.taskData.related_object.object_uuid}?view=2`
      );
      await this.$store.dispatch("modals/taskModal/close");
    },
    retrieveTaskProfits() {
      this.apiRequest(
        `tasks/${this.companyId}/${this.taskId}/profits/`,
        "get",
        true
      ).then((res) => {
        this.profits = res.data;
      });
    },
    listTaskTotalCosts() {
      this.apiRequest(
        `costs/${this.companyId}/task/${this.taskId}/list/total/?deleted=${this.showDeleted}`,
        "get",
        true
      ).then((res) => {
        this.taskCosts = res.data;
        this.uniqueInvoices = this.taskCosts
          .map((x) => x.outgoing_invoices)
          .flat()
          .reduce(
            (unique, item) =>
              unique.map((x) => x.id).includes(item.id)
                ? unique
                : [...unique, item],
            []
          );
      });
    },
    confirmCost() {
      if (!this.costToConfirm) return;
      let url;
      if (this.costToConfirm.type === "workerCost") {
        url = `costs/${this.companyId}/worker/${this.costToConfirm.object_id}/${this.costToConfirm.id}/confirm/`;
      } else if (this.costToConfirm.type === "extraCost") {
        url = `costs/${this.companyId}/worker/${this.costToConfirm.object_id}/extra/${this.costToConfirm.id}/confirm/`;
      }
      this.apiRequest(url, "post", true).then(() => this.listTaskTotalCosts());
    },
    editCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/${e.type}/${e.id}/edit/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) {
            this.listTaskTotalCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          })
        );
    },
    setCostToDelete(e) {
      this.costToDelete = e;
    },
    deleteCost() {
      if (!this.costToDelete) return;
      this.apiRequest(
        `costs/${this.companyId}/costs/${this.costToDelete.type}/${this.costToDelete.id}/delete/`,
        "delete",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.listTaskTotalCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Kulu kustutamine ebaõnnestus!",
            ttl: 5,
          })
        );
    },
    routeToOutgoingInvoice(invoice) {
      const route = this.$router.resolve({
        path: invoice.manually_checked
          ? `/invoices/outgoing/confirmed/${invoice.id}`
          : `/invoices/outgoing/${invoice.id}`,
        query: {
          view: 6,
        },
      });
      window.open(route.href, "_blank");
    },
  },
  watch: {
    showDeleted() {
      this.listTaskTotalCosts();
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
    }),
    calculatedMarginals() {
      const revenue = parseFloat(this.profits.total_revenue ?? "0");
      const cost = parseFloat(this.profits.total_cost ?? "1");

      const profit = revenue - cost;
      const marginalPercent = (profit / cost) * 100;
      let textColor;
      if (isNaN(marginalPercent)) {
        textColor = "black";
      } else if (marginalPercent < 0) {
        textColor = "danger"; // red for negative
      } else if (marginalPercent < 15) {
        textColor = "attention"; // yellow for 0-14.99%
      } else if (marginalPercent < 30) {
        textColor = "black"; // black for 15-29.99%
      } else {
        textColor = "green"; // green for 30% and above
      }

      return `<span class="text-sm text-${textColor}">${round2(
        profit
      )} € (${round2(marginalPercent)}%)</span>`;
    },
  },
};
</script>

<style lang="scss">
.tasks-costs-table {
  //                     type       amount      total       total       status
  //                          name        cost        cost_co     info         edit
  grid-template-columns: 3rem 0.5fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.4fr 0.15fr 0.2fr;
}
</style>
