<template>
  <button
    :class="
      disabledCondition
        ? 'btn-disabled'
        : dangerCondition
        ? 'btn-danger dark'
        : warningCondition
        ? 'btn-attention'
        : 'btn-primary'
    "
    :disabled="disabledCondition"
    :title="
      disabledCondition ? disabledText : dangerCondition ? dangerText : null
    "
    @click="disabledCondition || dangerCondition ? null : buttonClicked()"
  >
    <span class="label">{{ buttonDescription }}</span>
  </button>
</template>
<script>
export default {
  name: "CustomButton",
  props: {
    warningCondition: {
      type: Boolean,
      default: false,
    },
    dangerCondition: {
      type: Boolean,
      default: false,
    },
    disabledCondition: {
      type: Boolean,
      default: false,
    },
    disabledText: {
      type: String,
      default: "",
    },
    dangerText: {
      type: String,
      default: "",
    },
    buttonDescription: {
      type: String,
      default: "Vali",
    },
  },
  methods: {
    buttonClicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped lang="scss"></style>
