<template>
  <div class="gwd-table nested h-full">
    <div class="gwd-table-header">Töötajate töö tasud</div>
    <div class="gwd-table-header worker-pay-cost-table mobile:text-xs">
      <span class="mobile:col-span-3">Töötaja</span>
      <span>Kuupäev</span>
      <span>Algus</span>
      <span>Lõpp</span>
      <span>Kogus</span>
      <span>Ühikukulu</span>
      <span>Töötasu</span>
      <span>Koefitsent</span>
      <span v-if="isAdmin || isManager">Kogukulu</span>
      <span class="mobile:col-span-3">Info</span>
      <span class="mobile:col-span-3">Kinnitatud</span>
    </div>
    <div class="gwd-table-row-wrapper h-full" v-if="workerCosts.length > 0">
      <div
        class="gwd-table-row nopad w-full"
        v-for="workerCost in workerCosts"
        :key="workerCost.id"
        :class="
          workerCost.added_by.id !== Number(workerCost.worker_id)
            ? 'bg-danger-dark/25'
            : workerCost.editions && workerCost.editions.length > 0
            ? 'bg-attention/25'
            : ''
        "
      >
        <worker-cost
          :worker-cost="workerCost"
          @update="updateItem"
          @delete="deleteItem"
          @confirm="(e) => (costToConfirm = e)"
          @unconfirm="(e) => (payToMarkAsNotConfirmed = e)"
          @loadTaskWorkerCosts="loadTaskWorkerCosts"
        />
      </div>
    </div>
    <div v-if="workerCosts.length === 0" class="p-2">Kulud puuduvad</div>
    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-2"
        @click="
          $store.commit('modals/taskModal/setShowNewWorkerTimeModal', true)
        "
      >
        <span class="label">Lisa töötunde</span>
      </button>
    </div>
    <confirm-modal
      v-if="costToConfirm"
      :text="'Olete kindel, et soovite töötaja tööaja kinnitada?'"
      @confirm="confirmWorkerTime(costToConfirm.worker_id, costToConfirm.id)"
      @closeModal="costToConfirm = null"
    />
    <delete-confirm-modal
      v-if="payToMarkAsNotConfirmed"
      :text="'Olete kindel, et soovite kulu kinnituse maha võtta? Ei peaks aga njah, ei saa tekistada teid. Ma olen silt, mitte politsei'"
      @confirmDelete="unconfirmWorkerPayCost"
      @closeModal="payToMarkAsNotConfirmed = null"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { round2 } from "@/assets/utils/commonMath";
import WorkerCost from "@/components/tasks/costs/WorkerCost.vue";
import EventBus from "@/assets/mixins/EventBus";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";

export default {
  name: "TaskWorkerAssignments",
  components: { ConfirmModal, DeleteConfirmModal, WorkerCost },
  props: {
    taskId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      workerCosts: [],
      payToMarkAsNotConfirmed: false,
      costToConfirm: null,
    };
  },
  async mounted() {
    this.loadTaskWorkerCosts();
    EventBus.$on("stopperSaved", this.loadTaskWorkerCosts);
  },
  beforeDestroy() {
    EventBus.$off("stopperSaved");
  },
  methods: {
    round2,
    loadTaskWorkerCosts() {
      this.apiRequest(
        `costs/${this.companyId}/task/${this.taskId}/workers/paycosts/`,
        "get",
        true
      ).then((res) => {
        this.workerCosts = res.data;
      });
    },
    updateItem(data) {
      this.apiRequest(
        `tasks/${this.companyId}/${data.workerId}/${data.id}/edit/`,
        "patch",
        true,
        {
          start_time: data.startTime,
          end_time: data.endTime,
          cost: data.hourlyCost,
          price: data.hourlyPay,
          cost_coefficient: data.costCoefficient,
          vat: data.vatType,
        }
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskWorkerCosts();
        }
      });
    },
    deleteItem(workerId, timeTrackingId) {
      this.apiRequest(
        `tasks/${this.companyId}/${workerId}/${timeTrackingId}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskWorkerCosts();
        }
      });
    },
    unconfirmWorkerPayCost() {
      if (!this.payToMarkAsNotConfirmed) return;
      this.apiRequest(
        `costs/${this.companyId}/worker/${this.payToMarkAsNotConfirmed.worker_id}/${this.payToMarkAsNotConfirmed.id}/unconfirm/`,
        "post",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskWorkerCosts();
        }
      });
    },
    confirmWorkerTime(workerId, costId) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${workerId}/${costId}/confirm/`,
        "post",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskWorkerCosts();
          }
        })
        .catch((err) => {
          if (
            err.response.status === 409 ||
            { err }.err.response.status === 409
          )
            this.$store.dispatch("messageHandler/throwMessage", {
              text: "Kinnitamine ebaõnnestus, lõppajata aega ei saa kinnitada!",
              type: "error",
              ttl: 15,
            });
        });
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      userRole: "companyData/userPermission",
      isManager: "companyData/isManager",
      isAdmin: "companyData/isAdmin",
    }),
  },
};
</script>

<style lang="scss">
.worker-pay-cost-table {
  @apply items-center;
  //                     worker      start       amount        cost*amount   sum*cost_co  buttons
  //                           date        end          cost          cost_coef     info
  grid-template-columns: 0.3fr 0.15fr 0.1fr 0.1fr 0.1fr 0.15fr 0.15fr 0.15fr 0.15fr 0.4fr 0.2fr;
  @media screen and (max-width: 601px) {
    @apply grid grid-cols-3;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
