<template>
  <div class="flex flex-col">
    <div
      class="gwd-table-row narrow-template-list border-b border-t"
      :style="`background-color: ${typeColor}`"
      v-if="
        template !== undefined &&
        filteredCosts.filter((y) => !addedCostIds.includes(`${y.type}${y.id}`))
          .length > 0
      "
    >
      <span>{{ namePrefix }}</span>
      <span>{{ template.description }}</span>
      <span>{{ template.price }} € / {{ template.unit }}</span>
      <div class="flex justify-end" v-if="!combineCostsActive && !markAsPaid">
        <button class="btn-primary" @click="selectTemplate">
          <span class="typcn typcn-plus icon"></span>
          <span class="label">lisa</span>
        </button>
      </div>
    </div>
    <div
      v-for="cost in showAdded
        ? filteredCosts
        : filteredCosts.filter(
            (y) => !addedCostIds.includes(`${y.type}${y.id}`)
          )"
      :key="cost.id"
      class="gwd-table-row nopad narrow-cost-list items-center"
    >
      <div
        v-html="parseCostColorAndName(cost.type)"
        class="flex h-full items-center gap-x-1"
        style="min-width: 7px"
      />
      <span class="truncate" :title="cost.description">{{
        cost.description
      }}</span>
      <span>{{ moment(cost.occurrence_time).format("DD.MM.YYYY") }}</span>
      <div class="flex py-2">
        <span class="mr-1"> {{ cost.amount }}</span>
        <span> {{ cost.unit }}</span>
      </div>
      <div class="flex py-2">
        <span class="w-1/2"> {{ costWithCoef(cost) }} €</span>
        <span class="w-1/2"> {{ priceWithVat(cost) }} €</span>
      </div>
      <div class="flex justify-end py-2 pr-2">
        <gwd-checkbox
          v-if="markAsPaid || combineCostsActive"
          :value="
            combineCostsActive
              ? combinedCosts.includes(cost)
              : markedAsPaid.includes(cost)
          "
          @input="
            combineCostsActive
              ? addToCombinedCosts(cost)
              : addToMarkedAsPaid(cost)
          "
        />
        <button
          @click="addItem(cost)"
          class="btn-primary"
          v-if="
            !(combineCostsActive || markAsPaid) &&
            (!showAdded ||
              (showAdded && !addedCostIds.includes(`${cost.type}${cost.id}`)))
          "
        >
          <span class="label">Lisa </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { costWithCoef, priceWithVat } from "@/assets/utils/commonMath";
import moment from "moment";

export default {
  name: "TemplateCost",
  props: {
    template: {
      type: Object,
      default: undefined,
    },
    costs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    namePrefix: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: null,
    },
    addedCostIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    task: {
      type: Object,
      default: null,
    },
    showAdded: {
      type: Boolean,
      default: false,
    },
    combineCostsActive: {
      type: Boolean,
      default: false,
    },
    combinedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    markAsPaid: {
      type: Boolean,
      default: false,
    },
    markedAsPaid: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Number,
      default: null,
    },
  },
  mixins: [CostTypeParser],
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    priceWithVat,
    costWithCoef,
    addItem(e) {
      this.$emit("addItem", e);
    },
    selectTemplate() {
      this.$emit("selectTemplate", this.template, this.task);
    },
    addToCombinedCosts(e) {
      this.$emit("addToCombinedCosts", e);
    },
    addToMarkedAsPaid(e) {
      this.$emit("addToMarkedAsPaid", e);
    },
  },
  computed: {
    ...mapGetters({
      units: "companyVariables/units",
    }),
    filteredCosts() {
      if (this.status || this.status === 0)
        return this.costs.filter((x) => x.status === this.status);
      return this.costs;
    },
    typeColor() {
      switch (this.type) {
        case 1:
          return "rgba(216,205,2,0.5)";
        case 2:
          return "rgba(61,52,139,0.5)";
        case 3:
          return "rgba(111,143,114,0.5)";
        case 4:
          return "rgba(215,207,7,0.5)";
        case 5:
          return "rgba(217,131,36,0.5)";
        case 6:
          return "rgba(164,22,35,0.5)";
        default:
          return "rgba(18,33,18,0.5)";
      }
    },
  },
};
</script>
