export const formatTimeUnit = (item) => {
  if (item.toString().length > 1) return item;
  else return `0${item}`;
};

export const generateParamString = (params) => {
  if (!params) return "";
  let paramString = "?";
  if (Array.isArray(params)) {
    if (params.length === 0) return "";
    for (let i = 0; i < params.length; i++) {
      paramString += `${params[i].toString()}${
        i !== params.length - 1 ? "&" : ""
      }`;
    }
  } else if (typeof params === "object") {
    if (Object.keys(params).length === 0) return "";
    for (const key in params) {
      paramString += `${key}=${params[key]}&`;
    }
    paramString = paramString.slice(0, -1);
  } else {
    console.error("Invalid input!");
    return "";
  }
  return paramString;
};

export const generateCoordinateLocationObject = (address, lat, long) => {
  return {
    ads_oid: "",
    long_address: address,
    latitude: lat,
    longitude: long,
    street: null,
    locality: null,
    city: null,
    country: null,
    full_object: null,
  };
};

export const normalizeLocationObject = (locationObj) => {
  if (locationObj.long_address) return locationObj;
  return {
    ads_oid: locationObj.ads_oid ?? null,
    long_address: locationObj.ipikkaadress ?? null,
    latitude: locationObj.viitepunkt_b ?? null,
    longitude: locationObj.viitepunkt_l ?? null,
    street: locationObj.aadresstekst ?? null,
    locality: locationObj.asustusyksus ?? null,
    city: locationObj.omavalitsus ?? null,
    country: "Eesti" ?? null,
    full_object: locationObj ?? null,
  };
};

export const normalizeAbbreviatedLocationObject = (location) => {
  return {
    ads_oid: null,
    long_address: location,
    latitude: null,
    longitude: null,
    street: null,
    locality: null,
    city: null,
    country: "Eesti",
    full_object: null,
  };
};

import moment from "moment";
export const hmObjectToString = (obj) => {
  return `${formatTimeUnit(obj.hour)}:${formatTimeUnit(obj.minute)}`;
};
export const timeSelectorObjectToString = (timeObj, date, format) => {
  return moment(date).hour(timeObj.hour).minute(timeObj.minute).format(format);
};

export const parseTimerData = (timerData, workerUUID) => {
  return moment
    .utc(
      timerData
        .filter((x) => x.worker === workerUUID)
        .map((x) =>
          moment
            .duration(
              moment(x.stopper_end ? moment(x.stopper_end) : moment()).diff(
                moment(x.stopper_start)
              )
            )
            .as("milliseconds")
        )
        .reduce((len, sum) => len + sum, 0)
    )
    .format("HH:mm");
};

const fieldNames = [
  {
    field: "stopper_start",
    translation: "Algus",
  },
  {
    field: "stopper_end",
    translation: "Lõpp",
  },
  {
    field: "price",
    translation: "Palk/Hind",
  },
  {
    field: "cost",
    translation: "Kulu",
  },
  {
    field: "vat",
    translation: "Käibemaks",
  },
  {
    field: "cost_coefficient",
    translation: "Koefitsent",
  },
  {
    field: "added_by_manager",
    translation: "Käsitsi juhataja lisatud",
  },
  {
    field: "name",
    translation: "Kirjeldus",
  },
  {
    field: "amount",
    translation: "Kogus",
  },
  {
    field: "confirmed",
    translation: "Kinnitus",
  },
];
export const translateFieldName = (field) => {
  return fieldNames.find((x) => x.field === field).translation ?? field;
};

export const transformFieldValue = (field, value) => {
  if (!value) return "-";
  if (["stopper_start", "stopper_end"].includes(field)) {
    return moment(value).format("DD.MM HH:mm:ss");
  } else if (field === "added_by_manager") {
    if (value.includes(";"))
      return value
        .split(";")
        .map((x) => moment(x).format("DD.MM HH:mm:ss"))
        .join("-");
    return value;
  }
  return value;
};

export const transformLocationName = (location, savedLocations) => {
  if (!location) return null;
  if (!savedLocations || savedLocations.length === 0) return null;
  if (typeof location === "string") {
    const loc = savedLocations.find(
      (x) => x.location.long_address === location
    );
    return loc ? loc.name : null;
  } else {
    const loc = savedLocations.find(
      (x) =>
        x.location.ads_oid === location.ads_oid ||
        x.location.long_address === location
    );
    return loc ? loc.name : null;
  }
};

export const parseLocation = (location, savedLocations) => {
  if (!location) return "-";
  let address = typeof location === "string" ? location : location.long_address;
  const savedLocation = transformLocationName(location, savedLocations);
  if (savedLocation) {
    return `${savedLocation} / ${address}`;
  }
  if (location.ads_oid) return `${address}`;
  if (location.latitude && location.longitude)
    return `${address} / (${location.latitude}, ${location.longitude})`;
  return `${address}`;
};

export const decimalToHour = (decimalHours) => {
  let hours = Math.floor(decimalHours);
  let minutes = Math.round((decimalHours - hours) * 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const backgroundColours = [
  {
    status: 1,
    color: "bg-grey-light",
  },
  {
    status: 2,
    color: "bg-pending",
  },
  {
    status: 3,
    color: "bg-attention",
  },
  {
    status: 4,
    color: "bg-danger",
  },
  {
    status: 5,
    color: "bg-turquoise",
    backgroundColor: "bg-turquoise",
  },
  {
    status: 6,
    color: "bg-primary-dark",
    backgroundColor: "bg-grey-dark",
  },
  {
    status: 7,
    color: "bg-danger-dark",
    backgroundColor: "bg-danger-dark",
  },
];

export const borderColor = (status) => {
  return (
    backgroundColours.find((x) => x.status === status).color ?? "bg-primary"
  );
};
