<template>
  <div class="data-container-content data-container-table">
    <div class="gwd-table">
      <div class="gwd-table-header worker-table header pad-sm">
        <div class="flex">
          <h4>Nimi</h4>
        </div>
        <div class="flex">
          <h4>Plan. algus</h4>
        </div>
        <div class="flex">
          <h4>Plan. lõpp</h4>
        </div>
        <div class="flex">
          <h4>Algus</h4>
        </div>
        <div class="flex">
          <h4>Lõpp</h4>
        </div>
        <div class="flex">
          <h4>Tööaeg</h4>
        </div>
        <div class="flex">
          <h4>Töötasu</h4>
        </div>
        <div class="flex">
          <h4>Kinnitatud</h4>
        </div>
        <div class="flex">
          <h4>Lähtekoht</h4>
        </div>
        <div class="flex">
          <h4>Sihtkoht</h4>
        </div>
        <div class="flex">
          <h4>Kirjeldus</h4>
        </div>
        <div class="flex"></div>
      </div>
      <div class="gwd-table-row-wrapper">
        <div v-if="assignmentsWithTimers" class="gap-y-2">
          <worker-assignment
            v-for="assignmentWithTimers in assignmentsWithTimers"
            :key="assignmentWithTimers.assignment.id"
            :assignment-id="assignmentWithTimers.assignment"
            :timers="assignmentWithTimers.timers"
            @reloadAssignments="
              () => {
                assignmentsWithTimers = [];
                initTimers();
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { formatTimeUnit } from "@/assets/utils/commonTransforms";
import EventBus from "@/assets/mixins/EventBus";
import set from "lodash/set";
import { round2 } from "@/assets/utils/commonMath";
import WorkerAssignment from "@/components/tasks/taskmodal/workers/WorkerAssignment.vue";
export default {
  components: {
    WorkerAssignment,
  },
  data() {
    return {
      moment: moment,
      assignmentToDelete: null,
      assignmentsWithTimers: [],
      taskExtraCosts: [],
    };
  },
  mounted() {
    EventBus.$on("subTaskLocationUpdated", this.updateSubTaskLocation);
    if (this.timerData) this.initTimers();
  },
  beforeDestroy() {
    EventBus.$off("subTaskLocationUpdated");
  },
  methods: {
    round2,
    formatTimeUnit,
    addWorkerTime(assignment) {
      this.apiRequest(`tasks/${this.companyId}/stopper/create/`, "post", true, {
        worker: assignment.worker.id,
        stopper_start: moment(
          `${moment(this.taskData.start_time).format("YYYY-MM-DD")} ${
            assignment.newStartTime.hour
          }:${String(assignment.newStartTime.minute).padStart(2, "0")}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        stopper_end: moment(
          `${moment(this.taskData.start_time).format("YYYY-MM-DD")} ${
            assignment.newEndTime.hour
          }:${String(assignment.newEndTime.minute).padStart(2, "0")}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        task: this.taskData.id,
        project: this.taskData.related_object?.object_uuid ?? null,
      })
        .then((res) => {
          if (res.status === 201) {
            this.$store.dispatch(
              "modals/taskModal/retrieveTimerData",
              this.taskData.id
            );
            this.saving = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 10,
          });
          this.saving = false;
        });
    },
    updateSubTaskLocation(e) {
      let subTask = this.taskData.sub_tasks.find(
        (x) => x.id === e.assignmentId
      );
      if (subTask) {
        set(subTask, e.field, e.selectedLocation);
      }
    },
    initTimers() {
      for (let assignment of this.taskData.sub_tasks) {
        this.assignmentsWithTimers.push({
          assignment: assignment.id,
          worker: assignment.worker?.id ?? null,
          timers: this.timerData
            .filter((x) => x.worker_id === assignment.worker.id)
            .map((x) => {
              let data = JSON.parse(JSON.stringify(x));
              if (x.stopper_start) {
                data.start_time = {
                  hour: moment(x.stopper_start).hour(),
                  minute: moment(x.stopper_start).minute(),
                };
              }
              if (x.stopper_end) {
                data.end_time = {
                  hour: moment(x.stopper_end).hour(),
                  minute: moment(x.stopper_end).minute(),
                };
              }
              return data;
            }),
        });
      }
    },
  },
  watch: {
    timerData(val) {
      if (val) {
        this.assignmentsWithTimers = [];
        this.initTimers();
      }
    },
  },
  computed: {
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
    }),
  },
};
</script>
<style lang="scss">
.worker-table {
  @apply grid gap-x-2;
  grid-template-columns: 0.4fr 0.15fr 0.15fr 0.1fr 0.1fr 0.1fr 0.1fr 0.15fr 0.4fr 0.4fr 0.4fr 0.1fr;

  @media screen and (max-width: 601px) {
    @apply grid grid-cols-5;
    &.header {
      display: none !important;
      div {
        @apply border;
      }
    }
  }
}
</style>
