var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gwd-form"},[_c('div',{staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Kirjeldus","required":true,"valid":!_vm.$v.resource.name.$invalid,"error":_vm.$v.resource.name.$error,"error-text":_vm.$v.$error && !_vm.$v.resource.name.maxLength
          ? 'Kirjeldus on liiga pikk'
          : 'Kohustuslik'}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resource.name),expression:"resource.name"}],class:[
        _vm.$v.$error && !_vm.$v.resource.name.maxLength
          ? 'shadow-dangeroutline'
          : '' ],domProps:{"value":(_vm.resource.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.resource, "name", $event.target.value)}}})],1),_c('div',{staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Kogus","required":true,"valid":!_vm.$v.resource.amount.$invalid,"error":_vm.$v.resource.amount.$error}}),_c('decimal-input',{class:[
        _vm.$v.$error && !_vm.$v.resource.amount.required
          ? 'shadow-dangeroutline'
          : '' ],model:{value:(_vm.resource.amount),callback:function ($$v) {_vm.$set(_vm.resource, "amount", $$v)},expression:"resource.amount"}})],1),_c('div',{staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Ühik","required":true,"valid":!_vm.$v.resource.unit.$invalid,"error":_vm.$v.resource.unit.$error}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.resource.unit),expression:"resource.unit"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.resource, "unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.units),function(unit){return _c('option',{key:unit,domProps:{"value":unit}},[_vm._v(" "+_vm._s(unit)+" ")])}),0)],1),_c('div',{staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Ostuhind","required":true,"valid":!_vm.$v.resource.cost.$invalid,"error":_vm.$v.resource.cost.$error}}),_c('decimal-input',{class:[
        _vm.$v.$error && _vm.$v.resource.cost.$invalid ? 'shadow-dangeroutline' : '' ],model:{value:(_vm.resource.cost),callback:function ($$v) {_vm.$set(_vm.resource, "cost", $$v)},expression:"resource.cost"}})],1),_c('div',{staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Müügihind","required":true,"valid":!_vm.$v.resource.price.$invalid,"error":_vm.$v.resource.price.$error}}),_c('decimal-input',{class:[
        _vm.$v.$error && _vm.$v.resource.price.$invalid ? 'shadow-dangeroutline' : '' ],model:{value:(_vm.resource.price),callback:function ($$v) {_vm.$set(_vm.resource, "price", $$v)},expression:"resource.price"}})],1),_c('div',{staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Ressursi kood"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resource.code),expression:"resource.code"}],attrs:{"type":"text"},domProps:{"value":(_vm.resource.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.resource, "code", $event.target.value)}}})],1),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(function () { return (_vm.showGroups = false); }),expression:"() => (showGroups = false)"}],staticClass:"gwd-form-group"},[_c('gwd-formlabel',{attrs:{"title":"Kategooria"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resource.group),expression:"resource.group"}],attrs:{"type":"text"},domProps:{"value":(_vm.resource.group)},on:{"focus":function($event){_vm.showGroups = true},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.resource, "group", $event.target.value)}}}),(_vm.showGroups)?_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.resourceGroups),function(group){return _c('span',{key:group,staticClass:"p-1 cursor-pointer border border-1 bg-offwhite",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {
            _vm.resource.group = group;
            _vm.showGroups = false;
          }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(group)+" ")])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }