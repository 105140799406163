var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gap-y-2 text-sm"},[_c('div',{staticClass:"flex gap-x-2"},[_vm._m(0),_c('div',{staticClass:"flex flex-col w-full"},[_c('planning-location-selector',{ref:"startLocation",staticClass:"text-sm",attrs:{"emit-standardized-object":true},model:{value:(_vm.fields.location),callback:function ($$v) {_vm.$set(_vm.fields, "location", $$v)},expression:"fields.location"}},[_vm._v(" Lähtekoht ")]),(
          _vm.selectedProject &&
          Object.keys(_vm.selectedProject).length > 0 &&
          !_vm.fields.location
        )?_c('div',{staticClass:"flex w-full text-sm mt-1"},[_c('button',{staticClass:"mr-2",class:_vm.selectedProject.location ? 'btn-primary' : 'btn-disabled',attrs:{"disabled":!_vm.selectedProject.location},on:{"click":function () { return _vm.$refs.startLocation.selectLocation(
                _vm.processLocationForEmit(_vm.selectedProject.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimusega sama")])]),_c('button',{staticClass:"mr-2",class:(_vm.selectedProject &&
              _vm.selectedProject.client &&
              _vm.selectedProject.client.location) ||
            (_vm.selectedProject && _vm.selectedProject.client_location)
              ? 'btn-primary'
              : 'btn-disabled',on:{"click":function () { return _vm.$refs.startLocation.selectLocation(
                _vm.processLocationForEmit(
                  _vm.selectedProject.client && _vm.selectedProject.client.location
                    ? _vm.selectedProject.client.location
                    : _vm.selectedProject.client_location
                )
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimuse kliendiga sama")])]),_c('button',{staticClass:"mr-2",class:_vm.fields.endLocation ? 'btn-primary' : 'btn-disabled',attrs:{"disabled":!_vm.fields.endLocation},on:{"click":function () { return _vm.$refs.startLocation.selectLocation(
                _vm.processLocationForEmit(_vm.fields.endLocation)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Sama mis lõpp")])])]):_vm._e()],1)]),(_vm.extraLocations.length > 0)?_c('div',{staticClass:"flex flex-col gap-y-1"},_vm._l((_vm.extraLocations),function(location,index){return _c('div',{key:("l-" + (location.key)),staticClass:"flex w-full gap-x-2"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('planning-location-selector',{ref:"extraLocations",refInFor:true,attrs:{"emit-standardized-object":true,"extra-location":true},on:{"delete":function($event){return _vm.$store.dispatch('modals/addTaskModal/deleteExtraLocation', index)}},model:{value:(location.location),callback:function ($$v) {_vm.$set(location, "location", $$v)},expression:"location.location"}},[_vm._v(" Vahesihtkoht ")])],1)])}),0):_vm._e(),(_vm.fields.location && _vm.isManager)?_c('div',{staticClass:"flex gap-x-2 cursor-pointer rounded-l-full rounded-r hover:bg-primary hover:bg-opacity-10 duration-100",on:{"click":function($event){return _vm.$store.dispatch('modals/addTaskModal/addExtraLocation')}}},[_vm._m(1),_vm._m(2)]):_vm._e(),_c('div',{staticClass:"flex gap-x-2"},[_vm._m(3),_c('div',{staticClass:"flex flex-col flex-1"},[_c('planning-location-selector',{ref:"endLocation",attrs:{"emit-standardized-object":true},model:{value:(_vm.fields.endLocation),callback:function ($$v) {_vm.$set(_vm.fields, "endLocation", $$v)},expression:"fields.endLocation"}},[_vm._v(" Sihtkoht ")]),(!_vm.fields.endLocation)?_c('div',{staticClass:"flex w-full text-sm mt-1"},[(_vm.selectedProject && Object.keys(_vm.selectedProject).length > 0)?_c('button',{staticClass:"mr-2",class:_vm.selectedProject.location ? 'btn-primary' : 'btn-disabled',on:{"click":function () { return _vm.$refs.endLocation.selectLocation(
                _vm.processLocationForEmit(_vm.selectedProject.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimusega sama")])]):_vm._e(),(_vm.selectedProject && Object.keys(_vm.selectedProject).length > 0)?_c('button',{staticClass:"mr-2",class:(_vm.selectedProject &&
              _vm.selectedProject.client &&
              _vm.selectedProject.client.location) ||
            (_vm.selectedProject && _vm.selectedProject.client_location)
              ? 'btn-primary'
              : 'btn-disabled',on:{"click":function () { return _vm.$refs.endLocation.selectLocation(
                _vm.processLocationForEmit(
                  _vm.selectedProject.client && _vm.selectedProject.client.location
                    ? _vm.selectedProject.client.location
                    : _vm.selectedProject.client_location
                )
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Tellimuse kliendiga sama")])]):_vm._e(),(_vm.fields.location)?_c('button',{staticClass:"btn-primary mr-2",on:{"click":function () { return _vm.$refs.endLocation.selectLocation(
                _vm.processLocationForEmit(_vm.fields.location)
              ); }}},[_c('span',{staticClass:"label"},[_vm._v("Sama mis algus")])]):_vm._e()]):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-4 self-center mobile:hidden mobile-hidden"},[_c('span',{staticClass:"w-4 h-4 bg-primary rounded-full"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-4 self-center"},[_c('button',{staticClass:"bg-primary h-4 w-4 rounded-full leading-none flex justify-center items-center text-white"},[_c('span',{staticClass:"typcn typcn-plus leading-none"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex font-medium"},[_c('span',[_vm._v("Lisa vahesihtkoht")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex w-4 relative self-center mobile:hidden mobile-hidden"},[_c('span',{staticClass:"w-4 h-4 bg-primary rounded-full"})])}]

export { render, staticRenderFns }