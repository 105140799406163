<template>
  <div
    class=""
    :class="{
      'grid-coordinates': enableCoordinates,
      'grid-default': !enableCoordinates,
    }"
  >
    <input type="text" v-model="location.long_address" placeholder="Aadress" />
    <input
      v-if="enableCoordinates"
      type="text"
      v-model="location.latitude"
      placeholder="Laius"
    />
    <input
      v-if="enableCoordinates"
      type="text"
      v-model="location.longitude"
      placeholder="Pikkus"
    />
    <div
      v-if="!enableCoordinates"
      class="btn-primary cursor-pointer"
      @click="enableCoordinates = true"
    >
      <span class="typcn typcn-map icon" />
    </div>
    <div class="btn-primary cursor-pointer" @click="showMap = true">
      <span class="typcn typcn-location icon" />
    </div>
    <div
      class="btn-danger cursor-pointer"
      @click="
        () => {
          location.long_address = '';
          location.latitude = '';
          location.longitude = '';
          location.info = '';
          this.enableCoordinates = false;
        }
      "
    >
      <span class="typcn typcn-trash icon" />
    </div>
    <map-location-selector
      v-if="showMap"
      @locationSelected="
        (e) => {
          location.latitude = e.lat;
          location.longitude = e.lng;
          this.showMap = false;
          this.enableCoordinates = true;
        }
      "
      @close="() => (this.showMap = false)"
    />
  </div>
</template>
<script>
import { generateCoordinateLocationObject } from "@/assets/utils/commonTransforms";
import MapLocationSelector from "./MapLocationSelector.vue";
export default {
  props: {
    existingObject: {
      type: Object,
      default: null,
    },
    showMapOnMount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      location: {
        long_address: "",
        latitude: "",
        longitude: "",
      },
      showMap: false,
      enableCoordinates: false,
    };
  },
  mounted() {
    if (this.existingObject) {
      this.location.long_address = this.existingObject.long_address;
      this.location.latitude = this.existingObject.latitude;
      this.location.longitude = this.existingObject.longitude;
      if (this.existingObject.latitude || this.existingObject.longitude)
        this.enableCoordinates = true;
    } else if (this.showMapOnMount) this.$nextTick(() => (this.showMap = true));
  },
  components: {
    MapLocationSelector,
  },
  methods: {
    setAddressString(string) {
      this.location.long_address = string;
    },
  },
  computed: {
    locationObject() {
      return generateCoordinateLocationObject(
        this.location.long_address,
        this.location.latitude,
        this.location.longitude
      );
    },
  },
  watch: {
    locationObject: {
      deep: true,
      handler() {
        if (
          !this.existingObject ||
          this.existingObject.long_address !== this.location.long_address
        )
          this.$emit("locationChanged", this.locationObject);
      },
    },
  },
};
</script>
<style lang="scss">
.grid-default {
  @apply grid grid-cols-4 gap-x-2;
  grid-template-columns: 1.5fr 0.08fr 0.08fr 0.08fr;
}
.grid-coordinates {
  @apply grid grid-cols-5 gap-x-2;
  grid-template-columns: 1.1fr 0.4fr 0.4fr 0.08fr 0.08fr;
}
</style>
