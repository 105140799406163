<template>
  <div class="flex flex-col gap-y-1">
    <div class="flex justify-between items-center">
      <div class="flex gap-x-1 items-center">
        <h4 class="cursor-pointer" @click="hover = !hover"><slot /></h4>
        <div v-if="extraLocation" class="pls-mode" @click="$emit('delete')">
          <div
            class="h-6 w-6 rounded-full bg-danger flex justify-center items-center"
          >
            <img
              src="/bc21/trash.svg"
              class="h-3 w-3 filter-to-white"
              alt="Search icon"
            />
          </div>
        </div>
      </div>

      <div class="flex gap-x-1">
        <div
          @click="
            () => {
              addressSearch = true;
              coordinateSearch = false;
              manualEntry = false;
            }
          "
          class="pls-mode pl-1"
          :class="addressSearch ? 'bg-primary text-white rounded' : null"
        >
          <span class="pls-title">Otsing</span>
          <div class="pls-icon">
            <img
              src="/bc21/search.svg"
              class="h-3 w-3 filter-to-white"
              alt="Search icon"
            />
          </div>
        </div>
        <div
          class="pls-mode pl-1"
          :class="manualEntry ? 'bg-primary text-white rounded' : null"
          @click="
            () => {
              addressSearch = false;
              coordinateSearch = false;
              manualEntry = true;
            }
          "
        >
          <span class="pls-title">Manuaalne</span>
          <div class="pls-icon">
            <img
              src="/bc21/search.svg"
              class="h-3 w-3 filter-to-white"
              alt="Search icon"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex"
      v-if="!addressSearch && !coordinateSearch && !location"
    ></div>
    <location-selector
      v-if="addressSearch"
      :emit-standardized-object="true"
      :existing-object="value"
      @locationChanged="(e) => (location = e)"
      @presetInfo="(e) => (info = e)"
      @addManual="
        (e) => {
          addressSearch = false;
          manualEntry = true;
          $nextTick(() => $refs.coordinate.setAddressString(e));
        }
      "
      @setLocationAsManual="setLocationAsManual"
      class="w-full"
      ref="manualSelector"
    />
    <location-coordinate-selector
      key="map"
      v-if="coordinateSearch"
      @locationChanged="(e) => (location = e)"
      :existing-object="value"
      ref="coordinate"
      :show-map-on-mount="true"
    />
    <location-coordinate-selector
      key="man"
      v-if="manualEntry"
      @locationChanged="(e) => (location = e)"
      :existing-object="value"
      ref="coordinate"
    />
    <input
      v-if="info || hover || !location"
      type="text"
      v-model="info"
      placeholder="Info"
      class="text-sm"
    />
  </div>
</template>
<script>
import LocationSelector from "../../reusable/LocationSelector.vue";
import LocationCoordinateSelector from "@/components/reusable/LocationCoordinateSelector";
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    extraLocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      location: null,
      addressSearch: false,
      coordinateSearch: false,
      manualEntry: false,
      info: null,
      showMap: false,
      hover: true,
    };
  },
  mounted() {
    if (this.value) {
      this.location = this.value;
      this.info = this.value.info ? this.value.info : null;
      if (!this.info) this.hover = false;
      if (this.value.ads_oid) this.addressSearch = true;
      else this.manualEntry = true;
    } else this.addressSearch = true;
  },
  components: {
    LocationSelector,
    LocationCoordinateSelector,
  },
  methods: {
    selectLocation(e) {
      if (e)
        this.$nextTick(() => {
          this.$refs.manualSelector.selectLocation(e);
        });
    },
    setSearchString(e) {
      this.$refs.manualSelector.selectLocation(e);
      this.addressSearch = true;
      this.$refs.manualSelector.searchString = e?.long_address;
      this.info = e.info;
    },
    setLocationAsManual(e) {
      this.addressSearch = false;
      this.manualEntry = true;
      this.$nextTick(() => this.$refs.coordinate.setAddressString(e));
    },
  },
  computed: {
    hasInAadressScript() {
      return !!document.head.querySelector(
        "script[src='https://inaadress.maaamet.ee/inaadress/js/inaadress.min.js']"
      );
    },
    locationComputed() {
      if (this.location || this.info)
        return { ...this.location, info: this.info };
      return null;
    },
  },
  watch: {
    locationComputed: {
      deep: true,
      handler() {
        this.$emit("input", this.locationComputed);
      },
    },
  },
};
</script>

<style lang="scss">
.pls-mode {
  @apply flex gap-x-1 cursor-pointer items-center duration-100 text-sm;
  .pls-icon {
    @apply h-6 w-6 rounded-full bg-primary flex justify-center items-center;
  }
}
</style>
