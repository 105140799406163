<template>
  <div
    class="gwd-table-row"
    :class="
      isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
    "
  >
    <span class="mobile:hidden">{{
      extraCost.worker
        ? extraCost.worker.worker_name
        : !isManager
        ? "(Mina)"
        : "-"
    }}</span>
    <span>{{ extraCost.name }}</span>
    <div v-if="isAdmin || isManager">
      <div class="flex justify-end items-center gap-x-1" v-if="edit">
        <decimal-input v-model="editables.amount" class="ml-auto" />
        <select class="add-offer-input" v-model="editables.unit">
          <option v-for="unit in units" :value="unit" :key="unit">
            {{ unit }}
          </option>
        </select>
      </div>
      <span class="mobile:hidden" v-else>
        {{ extraCost.amount }} {{ extraCost.unit }}
      </span>
    </div>
    <span v-else> {{ extraCost.amount }} {{ extraCost.unit }} </span>
    <div v-if="isAdmin || isManager" class="flex">
      <span v-if="extraCost.confirmed || !edit">{{ extraCost.cost }} €</span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.cost"
      />
    </div>
    <span v-else>{{ extraCost.cost }} €</span>
    <span v-if="isAdmin || isManager">
      <span v-if="extraCost.confirmed || !edit">
        {{ extraCost.cost_coefficient }}
      </span>
      <decimal-input
        v-if="!extraCost.confirmed && edit"
        v-model="editables.cost_coefficient"
      />
    </span>
    <span>{{ round2(extraCost.cost * extraCost.amount) }} €</span>
    <span v-if="isAdmin || isManager">
      {{
        round2(extraCost.amount * extraCost.cost * extraCost.cost_coefficient)
      }}
      €
    </span>
    <span>{{ extraCost.info }}</span>
    <div class="flex">
      <div
        v-if="isManager || isAdmin"
        class="flex items-center gap-x-1 justify-end w-full"
      >
        <div v-if="!extraCost.confirmed && !edit">
          <button
            class="btn-primary"
            @click="$emit('confirmExtraCost', extraCost)"
          >
            Kinnita
          </button>
        </div>
        <button
          v-if="!edit && !extraCost.confirmed"
          class="btn-primary"
          @click="edit = true"
        >
          <span class="typcn typcn-edit" />
        </button>
        <button v-if="edit" class="btn-primary" @click="editCost">
          <span class="typcn typcn-tick" />
        </button>
        <button v-if="edit" class="btn-danger" @click="resetCost">
          <span class="typcn typcn-times" />
        </button>
        <button v-if="edit" class="btn-danger" @click="deleteCostActive = true">
          <span class="typcn typcn-trash" />
        </button>
        <span v-if="extraCost.confirmed && !edit" class="typcn typcn-tick">
          Kinnitatud
        </span>
        <button
          v-if="!edit"
          @click="showExtraInformation = !showExtraInformation"
          class="icon-button primary"
        >
          <img src="/bc21/info.svg" class="h-4 w-4 filter-to-white" alt="?" />
        </button>
      </div>
      <div v-else>
        <span
          class="typcn"
          :class="extraCost.confirmed ? 'typcn-tick' : 'typcn-times'"
        />
      </div>
    </div>
    <div
      v-if="showExtraInformation"
      class="col-span-11 flex flex-col border-b border-t border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-5 font-medium">
        <span>Lisaja</span>
        <span>Töö aeg</span>
        <span>Kinnitaja</span>
        <span>Kinnitamise aeg</span>
      </div>
      <div class="grid grid-cols-5">
        <span>{{
          extraCost.added_by ? extraCost.added_by.worker_name : "-"
        }}</span>
        <span>{{
          moment(extraCost.occurrence_time).format("DD.MM.YYYY HH:mm")
        }}</span>
        <span>{{
          extraCost.confirmed
            ? extraCost.confirmed_by
              ? extraCost.confirmed_by.worker_name
              : "Kinnitaja puudub"
            : "Kinnitamata"
        }}</span>
        <span>{{
          extraCost.confirmed_time
            ? moment(extraCost.confirmed_time).format("DD.MM.YYYY HH:mm")
            : "-"
        }}</span>
        <div class="flex items-center gap-x-2 justify-end">
          <div v-html="parseCostStatus(extraCost.status)" />

          <button
            v-if="[0, 1].includes(extraCost.status)"
            class="icon-button grey"
            title="Muuda omakuluks"
            @click="selfCostActive = true"
          >
            <span class="typcn typcn-home icon text-white" />
          </button>
          <div
            v-if="isAdmin && extraCost.confirmed"
            class="flex justify-center"
          >
            <button class="btn-dangerdark ml-auto" @click="unconfirm">
              <span class="label"> Võta kinnitus maha </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showExtraInformation && extraCost.editions.length > 0"
      class="col-span-11 flex flex-col border-t border-b border-offwhite-dark p-2 bg-offwhite"
    >
      <div class="grid grid-cols-6 font-medium">
        <span>Muutja</span>
        <span>Väli</span>
        <span class="col-span-2">Vana väärtus</span>
        <span class="col-span-2">Uus väärtus</span>
      </div>
      <div
        v-for="item in extraCost.editions"
        :key="item.id"
        class="grid grid-cols-6"
      >
        <div>{{ item.editor }}</div>
        <div>
          {{ translateFieldName(item.field_name) }}
        </div>
        <div class="col-span-2">
          {{ transformFieldValue(item.field_name, item.initial_value) }}
        </div>
        <div class="col-span-2">
          {{ transformFieldValue(item.field_name, item.new_value) }}
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="selfCostActive"
      :header-text="'Kulu muutmine omakuluks'"
      :text="'Kas olete kindel et soovite omakuluks muuta?'"
      @confirm="markAsSelfCost"
      @closeModal="selfCostActive = false"
    />
    <delete-confirm-modal
      v-if="deleteCostActive"
      @confirmDelete="deleteCost"
      @closeModal="deleteCostActive = false"
    />
  </div>
</template>
<script>
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { round2 } from "@/assets/utils/commonMath";
import { mapGetters } from "vuex";
import { isEqual } from "lodash";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import moment from "moment";
import {
  transformFieldValue,
  translateFieldName,
} from "@/assets/utils/commonTransforms";
import { costUtil } from "@/assets/utils/commonCostMethods";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
import StatusParser from "@/assets/mixins/StatusParser";

export default {
  name: "ExtraCost",
  components: { ConfirmModal, DeleteConfirmModal, DecimalInput },
  props: {
    extraCost: {
      type: Object,
      default: null,
    },
    isAdmin: { type: Boolean, default: false },
    isManager: { type: Boolean, default: false },
    taxTypes: { type: Array, default: () => [] },
  },
  data() {
    return {
      edit: false,
      editables: null,
      deleteCostActive: false,
      showExtraInformation: false,
      moment: moment,
      selfCostActive: false,
    };
  },
  mixins: [StatusParser],

  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.extraCost));
  },
  methods: {
    transformFieldValue,
    translateFieldName,
    round2,
    editCost() {
      if (!isEqual(this.editables, this.extraCost)) {
        this.editables.vat = this.editables.vatType
          ? this.editables.vatType.id
          : null;
        this.$emit("editCost", this.editables);
      }
      this.edit = false;
    },
    deleteCost() {
      this.$emit("deleteCost", this.editables);
      this.edit = false;
    },
    unconfirm() {
      this.$emit("unconfirm", this.extraCost);
    },
    resetCost() {
      this.editables = JSON.parse(JSON.stringify(this.extraCost));
      this.edit = false;
    },
    async markAsSelfCost() {
      try {
        const { status } = await costUtil.selfCost.call(this, this.editables);
        if (status === 200) this.$emit("loadTaskExtraCosts");
        this.selfCostActive = false;
      } catch (err) {
        this.selfCostActive = false;
        await this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          error: err,
          text: "Midagi läks valesti",
          ttl: 5,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      units: "companyVariables/units",
    }),
  },
};
</script>
<style scoped lang="scss">
input {
  width: 95%;
}
select {
  width: 75%;
  option {
    width: 75%;
  }
}
</style>
