<template>
  <div class="flex flex-col rounded relative text-sm">
    <div class="flex items-start w-full">
      <div
        v-if="!location"
        class="flex w-full mobile:p-1 bg-offwhite shadow items-center duration-100 rounded-b mobile:flex-col ring-primary"
        :class="[
          isFocused
            ? 'ring-2 ring-opacity-25 mobile:shadow-none'
            : 'hover:ring-2 hover:ring-opacity-10',
          showAddresses ? 'rounded-t' : 'rounded',
          showError ? 'shadow-dangeroutline' : '',
        ]"
      >
        <span
          class="mobile:flex mobile:self-start mobile:items-center mobile:mb-2"
        >
          <img
            src="/bc21/search.svg"
            class="h-4 w-4 mobile:h-3 mobile:w-3 ml-2 mobile:ml-1 mobile:mr-3"
            alt="Search icon"
          />
          <h4 class="hidden mobile:flex">Otsi asukohta</h4>
        </span>
        <input
          v-model="searchString"
          ref="searchInput"
          type="text"
          class="add-project-input embedded w-auto flex-grow truncate mobile:w-full mobile:bg-white hover:ring-0 text-sm"
          :class="[isWhite ? 'bg-white' : '']"
          :placeholder="text"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
        <div class="w-auto py-1 px-1 flex items-center justify-center">
          <ClipLoader size="16px" :loading="retrieving" class="h-4 w-4" />
          <button
            class="btn-danger"
            v-if="searchString.length > 0"
            @click="searchString = ''"
          >
            <span class="icon">
              <img
                src="/bc21/trash.svg"
                class="h-5 filter-to-white"
                alt="Delete icon"
              />
            </span>
          </button>
        </div>
        <div
          v-if="locationBackup"
          class="w-auto py-1 px-1 flex items-center justify-center"
        >
          <button class="btn-danger" @click="cancelEditLocation">
            <span class="icon">
              <img
                src="/bc21/close.svg"
                class="h-5 filter-to-white"
                alt="Cancel icon"
              />
            </span>
          </button>
        </div>
      </div>
      <div v-else class="flex justify-between w-full">
        <span>{{ location.long_address }}</span>
        <div>
          <button class="btn-primary" @click="editLocation">Muuda</button>
        </div>
      </div>
    </div>
    <div
      class="saved-location-menu"
      v-if="isFocused && foundAddresses.length > 0"
    >
      <div
        class="flex cursor-pointer px-2 py-3 justify-between items-center hover:bg-offwhite hover:bg-opacity-50"
        v-for="item in addressArray"
        :key="item.ads_oid"
        @mousedown="selectLocation(item)"
      >
        <span
          v-if="item.favorite"
          class="typcn typcn-star text-attention mr-2"
        ></span>
        <span
          v-if="!item.favorite && item.name"
          class="typcn typcn-star-outline mr-2"
        ></span>
        <span class="flex w-full">
          {{ item.name ? item.name : item.ipikkaadress }}</span
        >
      </div>
      <div
        class="flex cursor-pointer px-2 py-3 items-center gap-x-2 hover:bg-offwhite hover:bg-opacity-50"
        v-if="searchString.length > 0"
        @mousedown="addAddressManually"
      >
        <span class="typcn typcn-plus" />
        <span>Lisa manuaalselt</span>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import Axios from "axios";
import { normalizeLocationObject } from "@/assets/utils/commonTransforms";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "LocationSelector",
  props: {
    text: {
      type: String,
      default: "Sisestage asukoha aadress",
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    showDeleteText: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    existingObject: {
      type: Object,
      default: null,
    },
  },
  components: { ClipLoader },
  data() {
    return {
      location: null,
      locationBackup: null,
      searchString: "",
      foundAddresses: [],
      showAddresses: false,
      selectedLocation: null,
      isFocused: false,
      presetLocationExists: false,
      retrieving: false,
    };
  },
  async mounted() {
    if (this.existingObject) {
      if (this.existingObject.ads_oid) {
        this.location = this.existingObject;
        this.locationBackup = JSON.parse(JSON.stringify(this.existingObject));
      } else {
        if (this.existingObject.long_address)
          this.searchString = this.existingObject.long_address;
        else this.searchString = "";
      }
    }
    if (!this.companyLocations) {
      await this.$store.dispatch("companyVariables/retrieveSavedLocations");
    }
  },
  methods: {
    clear() {
      this.$emit("locationChanged", null);
    },
    addAddressManually() {
      this.$emit("addManual", this.searchString);
    },
    editLocation() {
      this.searchString = this.location?.long_address;
      this.location = null;
    },
    cancelEditLocation() {
      this.location = JSON.parse(JSON.stringify(this.locationBackup));
    },
    filterResults() {
      //  Find if address/name matches existing locations
      // After that, use address search
      this.foundAddresses = [];
      this.showAddresses = false;
      if (this.companyLocations)
        this.foundAddresses = this.companyLocations.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
            x.location.long_address
              .toLowerCase()
              .includes(this.searchString.toLowerCase())
        );

      this.loadAddresses();
    },
    loadAddresses() {
      if (this.searchString.length > 2) {
        this.retrieving = true;
        let axiosInstance = Axios.create({
          baseURL: process.env.VUE_APP_API_URL,
        });
        let options = {
          method: "get",
          url:
            "https://inaadress.maaamet.ee/inaadress/gazetteer?address=" +
            this.searchString.replace(" ", "+"),
        };
        axiosInstance(options)
          .then((res) => {
            if (Array.isArray(res.data.addresses)) {
              let data = this.foundAddresses;
              data = data.concat(res.data.addresses);

              this.foundAddresses = data;
            }
            this.showAddresses = true;
            this.retrieving = false;
          })
          .catch((err) => {
            console.error(err);
            this.$store.dispatch("messageHandler/throwMessage", {
              type: "error",
              text: "Asukoha päring ebaõnnestus",
              ttl: 15,
            });
            this.retrieving = false;
          });
      }
    },
    debounceSearchQuery: debounce(function () {
      if (!this.selectedLocation) this.$emit("locationChanged");
      this.filterResults();
    }, 500),
    selectLocation(e) {
      if (this.companyLocations?.find((x) => x.id === e.id))
        this.location = e.location;
      else this.location = normalizeLocationObject(e);
      if (e.info && e.info.length > 0) this.$emit("presetInfo", e.info);
      if (e.location && e.location.info && e.location.info.length > 0)
        this.$emit("presetInfo", e.location.info);
      this.foundAddresses = [];
      this.showAddresses = false;
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      companyLocations: "companyVariables/savedLocations",
    }),
    addressArray() {
      let addressArr = [];
      if (this.searchString === "")
        return this.companyLocations.filter((x) => x.favorite);
      if (this.foundAddresses?.length > 0)
        addressArr = [...this.foundAddresses];
      return addressArr;
    },
  },
  watch: {
    location: {
      deep: true,
      handler() {
        this.$emit("locationChanged", this.location);
      },
    },
    searchString() {
      this.debounceSearchQuery();
    },
    isFocused(newVal) {
      if (!newVal && !this.location && this.searchString.length > 0)
        this.$emit("setLocationAsManual", this.searchString);
    },
  },
};
</script>

<style scoped>
.saved-location-menu {
  @apply shadow rounded-b w-full bg-white overflow-y-scroll;
  position: absolute;
  top: 100%;
  z-index: 9999;
  max-height: 16rem;
}
</style>
