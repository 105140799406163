import { set } from "lodash";

export const setDefaultServiceObjectFields = (
  item,
  serviceObjects,
  taxTypes,
  defaultTaxType,
  templateField = false
) => {
  if (item.serviceObj) {
    // If has default value, override always, if description in values_list override, else desc
    let description;
    if (
      item.description &&
      serviceObjects.filter(
        (x) =>
          x.service_name === item.description || x.info === item.description
      ).length
    )
      description = item.serviceObj.info
        ? item.serviceObj.info
        : item.serviceObj.service_name;
    else description = item.description;
    set(item, "description", description);
    let vat;
    if (!templateField) {
      if (item.serviceObj.default_vat) {
        vat = taxTypes.find((x) => x.id === item.serviceObj.default_vat);
      } else {
        vat = defaultTaxType;
      }
      set(item, "taxType", vat);
      set(item, "unit", item.serviceObj.default_unit ?? null);
    }
  }
};

export const defaultTaxType = (client = null, payer = null, taxTypes) => {
  if (!taxTypes) return null;
  if (payer) {
    if (payer.default_vat_code)
      return taxTypes.find((x) => x.id === payer.default_vat_code);
    return taxTypes.filter((x) => x.outgoing_tax_code).find((x) => x.default);
  }
  if (client) {
    if (client.default_vat_code)
      return taxTypes.find((x) => x.id === client.default_vat_code);
    return taxTypes.filter((x) => x.outgoing_tax_code).find((x) => x.default);
  } else {
    return taxTypes.filter((x) => x.outgoing_tax_code).find((x) => x.default);
  }
};

export const defaultServiceCode = (serviceObjects, defaultService = null) => {
  if (!serviceObjects) return null;

  if (defaultService)
    return serviceObjects.find((x) => x.id === defaultService);
  return null;
};
