var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table nested h-full"},[_c('div',{staticClass:"gwd-table-header",class:_vm.isAdmin || _vm.isManager ? 'supplier-cost' : 'supplier-cost-worker'},[_c('span',[_vm._v("Hankija")]),_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Kirjeldus")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v("Kulu")]),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Hind")]):_c('span',[_vm._v("Kokku")]),(_vm.isAdmin || _vm.isManager)?_c('span',[_vm._v("Käibemaks")]):_vm._e(),(_vm.isAdmin || _vm.isManager)?_c('span',{staticClass:"flex"},[_vm._v("Kulu/Hinna summa")]):_vm._e(),_c('span')]),_c('div',{staticClass:"gwd-table-row-wrapper"},[_vm._l((_vm.supplierCostPlannings),function(planningCost){return _c('supplier-cost-planning',{key:planningCost.id,attrs:{"planning-cost":planningCost},on:{"deletePlanningCost":function (planning) { return (_vm.planningToDelete = planning); },"updatePlanningCost":_vm.updatePlanningCost,"convertToCost":_vm.convertToCost}})}),(_vm.supplierCostPlannings.length > 0)?_c('div',{staticClass:"gwd-table-header nopad"}):_vm._e(),_vm._l((_vm.taskSupplierCosts),function(supplierCost){return _c('compact-cost',{key:supplierCost.id,class:_vm.isAdmin || _vm.isManager ? 'supplier-cost' : 'supplier-cost-worker',attrs:{"cost":supplierCost,"tax-types":_vm.taxTypes},on:{"costsUpdated":_vm.loadTaskSupplierCosts}},[_c('span',{class:{ 'text-danger': !supplierCost.supplier }},[_vm._v(" "+_vm._s(supplierCost.supplier ? supplierCost.supplier.name : "HANKIJA PUUDUB")+" ")])])}),(
        _vm.taskSupplierCosts.length === 0 && _vm.supplierCostPlannings.length === 0
      )?_c('span',{staticClass:"p-2"},[_vm._v(" Hankijakulusid pole. ")]):_vm._e()],2),_c('div',{staticClass:"gwd-table-bottom-bar"},[(_vm.isAdmin || _vm.isManager)?_c('button',{staticClass:"btn-primary mr-3",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddSupplierCostModal', {
          isPlanning: true,
        })}}},[_vm._v(" Planeeri hankija kulu ")]):_vm._e(),(
        _vm.isAdmin ||
        _vm.isManager ||
        (['R4', 'R5'].includes(_vm.userPermissions) &&
          _vm.moment(_vm.taskTime.end).diff(_vm.moment(), 'hours') >= -12 &&
          !_vm.taskConfirmed)
      )?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/openAddSupplierCostModal', {
          isPlanning: false,
        })}}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.isAdmin || _vm.isManager ? "Lisa kulu" : "Lisa prügi/vms kulu"))])]):_vm._e()]),(_vm.planningToDelete)?_c('delete-confirm-modal',{on:{"confirmDelete":_vm.deletePlannedCost,"closeModal":function($event){_vm.planningToDelete = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }