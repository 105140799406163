<template>
  <div class="gwd-table nested h-full">
    <div class="gwd-table-header">Lisatööd</div>
    <div
      class="gwd-table-header"
      :class="
        isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
      "
    >
      <span class="mobile:hidden">Töötaja</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>{{ isManager ? "Kulu" : "Ühiku hind" }}</span>
      <span v-if="isAdmin || isManager">Koefitsent</span>
      <span>Töötasu</span>
      <span v-if="isAdmin || isManager">Kogukulu</span>
      <span>Lisainfo</span>
      <span>Kinnitatud</span>
    </div>
    <div class="gwd-table-row-wrapper mobile:overflow-x-hidden">
      <extra-cost-planning
        v-for="extraCostPlanning in extraWorkPlannings"
        :key="extraCostPlanning.id"
        :extra-cost-planning="extraCostPlanning"
        @deletePlanningCost="(planning) => (planningToDelete = planning)"
        @updatePlanningCost="updatePlanningCost"
      />
      <div
        class="gwd-table-header nopad"
        v-if="extraWorkPlannings.length > 0"
      />
      <extra-cost
        v-for="extraCost in taskExtraCosts"
        :key="extraCost.id"
        :extra-cost="extraCost"
        @confirmExtraCost="(e) => (costToConfirm = e)"
        @unconfirm="(e) => (costToUnConfirm = e)"
        @editCost="editCost"
        @deleteCost="deleteCost"
        :is-admin="isAdmin"
        :is-manager="isManager"
        :class="
          extraCost.added_by.id !==
          (extraCost.worker ? extraCost.worker.id : workerId)
            ? 'bg-danger-dark/25'
            : extraCost.editions && extraCost.editions.length > 0
            ? 'bg-attention/25'
            : ''
        "
        @loadTaskExtraCosts="loadTaskExtraCosts"
      />
      <span
        v-if="taskExtraCosts.length === 0 && extraWorkPlannings.length === 0"
        class="p-2"
      >
        Määratud lisatöid pole.
      </span>
    </div>

    <div class="gwd-table-bottom-bar gap-x-3">
      <button
        class="btn-primary"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="$store.commit('modals/taskModal/openAddExtraCostModal', true)"
      >
        Planeeri lisatöö
      </button>

      <button
        v-if="
          isAdmin ||
          isManager ||
          (['R4', 'R5'].includes(userPermissions) &&
            moment(taskTime.end).diff(moment(), 'hours') >= -12 &&
            !taskConfirmed)
        "
        class="btn-primary"
        @click="$store.commit('modals/taskModal/openAddExtraCostModal', false)"
      >
        <span class="label">{{
          isAdmin || isManager ? "Lisa kulu" : "Lisa tõste/lisatasu"
        }}</span>
      </button>
      <button
        v-if="isAdmin || isManager"
        class="btn-primary"
        @click="$store.commit('modals/taskModal/openAddMultipleExtraCostModal')"
      >
        <span class="label">Lisa mitmele korraga</span>
      </button>
    </div>
    <delete-confirm-modal
      v-if="planningToDelete"
      @confirmDelete="deletePlannedCost"
      @closeModal="planningToDelete = null"
    />
    <confirm-modal
      v-if="costToConfirm"
      :text="'Olete kindel, et soovite kulu kinnitada?'"
      @confirm="confirmWorkerExtraCost(costToConfirm)"
      @closeModal="costToConfirm = null"
    />
    <delete-confirm-modal
      v-if="costToUnConfirm"
      :text="'Olete kindel, et soovite kulu kinnituse maha võtta? Ei peaks aga njah, ei saa tekistada teid. Ma olen silt, mitte politsei'"
      @confirmDelete="unConfirmWorkerExtraCost(costToUnConfirm)"
      @closeModal="costToUnConfirm = null"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
import { round2 } from "@/assets/utils/commonMath";
import ExtraCost from "@/components/tasks/costs/ExtraCost.vue";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import ExtraCostPlanning from "@/components/tasks/costs/ExtraCostPlanning.vue";
import moment from "moment";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";

export default {
  name: "TaskExtraCostAssignments",
  components: {
    ConfirmModal,
    ExtraCostPlanning,
    DeleteConfirmModal,
    ExtraCost,
  },
  props: {
    taskId: {
      type: String,
      default: null,
    },
    taskConfirmed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taskExtraCosts: [],
      extraWorkPlannings: [],
      planningToDelete: false,
      edit: null,
      moment: moment,
      costToUnConfirm: null,
      costToConfirm: null,
    };
  },
  methods: {
    round2,
    loadTaskExtraCosts() {
      this.apiRequest(
        `template/${this.companyId}/extras/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.extraWorkPlannings = res.data;
        this.$store.dispatch("modals/updateCheckModal/initData", {
          name: "extraCostPlanning",
          retrieveUrl: `template/${this.companyId}/extras/?task=${this.taskId}`,
          startingData: JSON.parse(JSON.stringify(res.data)),
        });
      });
      this.apiRequest(
        `costs/${this.companyId}/workers/costs/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.taskExtraCosts = res.data;
        this.$store.dispatch("modals/updateCheckModal/initData", {
          name: "extraCost",
          retrieveUrl: `costs/${this.companyId}/workers/costs/?task=${this.taskId}`,
          startingData: JSON.parse(JSON.stringify(res.data)),
        });
      });
    },
    confirmWorkerExtraCost(cost) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${cost.worker.id}/extra/${cost.id}/confirm/`,
        "post",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskExtraCosts();
        }
      });
    },
    unConfirmWorkerExtraCost(cost) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${cost.worker.id}/extra/${cost.id}/unconfirm/`,
        "post",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskExtraCosts();
        }
      });
    },
    editCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/extraCost/${e.id}/edit/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskExtraCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          })
        );
    },
    deleteCost(e) {
      this.apiRequest(
        `costs/${this.companyId}/costs/extraCost/${e.id}/delete/`,
        "delete",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.loadTaskExtraCosts();
          }
        })
        .catch((err) =>
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Kulu kustutamine ebaõnnestus!",
            ttl: 5,
          })
        );
    },
    updatePlanningCost(e) {
      if (e.vatType) e.vat = e.vatType.id;
      this.apiRequest(
        `template/${this.companyId}/extras/task/${this.taskId}/${e.id}/edit/`,
        "patch",
        true,
        e
      ).then((res) => {
        if (res.status === 200) this.loadTaskExtraCosts();
      });
    },
    deletePlannedCost() {
      if (!this.planningToDelete) return;
      this.apiRequest(
        `template/${this.companyId}/extras/task/${this.taskId}/${this.planningToDelete.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) this.loadTaskExtraCosts();
      });
    },
  },
  async mounted() {
    this.loadTaskExtraCosts();
    EventBus.$on("costSaved", () => this.loadTaskExtraCosts());
  },
  beforeDestroy() {
    EventBus.$off("costSaved");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      userPermissions: "companyData/userPermission",
      taskTime: "modals/taskModal/taskTime",
      workerId: "companyData/workerId",
    }),
  },
};
</script>
<style lang="scss">
.extra-cost-table {
  //                     worker      amount      cost_co     total       confirmed
  //                           description cost        sum         info
  grid-template-columns: 0.3fr 0.5fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.5fr 0.2fr;
}
.extra-cost-table-worker {
  //                     worker      amount      total       confirmed
  //                           name        cost        info
  grid-template-columns: 0.1fr 0.4fr 0.2fr 0.2fr 0.2fr 0.4fr 0.2fr;

  @media screen and (max-width: 992px) {
    //                     name amount price sum info confirmed
    grid-template-columns: 0.4fr 0.1fr 0.1fr 0.15fr 0.2fr 0.1fr;
  }
  @media screen and (max-width: 601px) {
    @apply flex-wrap m-1 border-b border-b-offwhite-dark w-full;
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
  }
}
</style>
