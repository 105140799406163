<template>
  <div
    class="gwd-table-row"
    :class="
      isAdmin || isManager ? 'extra-cost-table' : 'extra-cost-table-worker'
    "
  >
    <span v-if="edit" />
    <span v-if="!edit" class="mobile:hidden">Planeeritud töö</span>
    <span v-if="!edit">
      <span class="desktop:hidden">Planeeritud töö:</span>
      {{ extraCostPlanning.name }}
    </span>
    <input v-else type="text" v-model="editables.name" class="input-narrow" />
    <span v-if="!edit">
      {{ extraCostPlanning.amount }} {{ extraCostPlanning.unit }}
    </span>
    <div v-else class="flex items-center">
      <decimal-input v-model="editables.amount" class="ml-auto input-narrow" />
      <select class="input-narrow" v-model="editables.unit">
        <option v-for="unit in units" :value="unit" :key="unit">
          {{ unit }}
        </option>
      </select>
    </div>
    <div v-if="isAdmin || isManager">
      <span v-if="!edit">{{ extraCostPlanning.cost }} €</span>
      <decimal-input v-else v-model="editables.cost" class="input-narrow" />
    </div>
    <div v-if="isAdmin || isManager">
      <span v-if="!edit">{{ extraCostPlanning.cost_coefficient }}</span>
      <decimal-input
        v-else
        v-model="editables.cost_coefficient"
        class="input-narrow"
      />
    </div>
    <span v-if="isAdmin || isManager">
      {{ round2(extraCostPlanning.cost * extraCostPlanning.amount) }}
      €
    </span>
    <span v-else>-</span>
    <span v-if="isAdmin || isManager">
      {{
        round2(
          extraCostPlanning.cost *
            extraCostPlanning.amount *
            extraCostPlanning.cost_coefficient
        )
      }}
      €
    </span>
    <span v-else>-</span>
    <span />
    <div v-if="isAdmin || isManager" class="flex items-center">
      <button v-if="!edit" class="btn-primary mr-3" @click="edit = true">
        <span class="typcn typcn-edit" />
      </button>
      <button v-if="!edit" class="btn-danger" @click="deletePlanningCost">
        <span class="typcn typcn-trash" />
      </button>
      <button v-if="edit" class="btn-primary" @click="updateExtraCost">
        <span class="label">Salvesta</span>
      </button>
      <button v-if="edit" class="btn-danger" @click="resetEditables">
        <span class="label">Tühista</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { round2 } from "@/assets/utils/commonMath";
import { clone } from "lodash";
import DecimalInput from "@/components/reusable/DecimalInput.vue";

export default {
  name: "ExtraCostPlanning",
  components: { DecimalInput },
  props: {
    extraCostPlanning: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      edit: false,
      editables: null,
    };
  },
  methods: {
    round2,
    deletePlanningCost() {
      this.$emit("deletePlanningCost", this.extraCostPlanning);
    },
    resetEditables() {
      this.editables = clone(this.extraCostPlanning);
      this.edit = false;
    },
    updateExtraCost() {
      this.$emit("updatePlanningCost", this.editables);
      this.edit = false;
    },
  },
  mounted() {
    this.editables = clone(this.extraCostPlanning);
  },
  computed: {
    ...mapGetters({
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      units: "companyVariables/units",
    }),
  },
};
</script>

<style scoped lang="scss">
.input-narrow {
  width: 80%;
}
</style>
