<template>
  <div v-if="editables" class="gwd-table-row">
    <slot v-if="relatedSeparate" class="mobile:col-span-2"></slot>
    <div
      class="flex"
      :class="relatedSeparate ? 'mobile:col-span-2' : 'mobile:col-span-4'"
    >
      <span v-if="!edit">{{ cost.name }}</span>
      <input type="text" v-else v-model="editables.name" />
    </div>
    <div class="flex justify-end items-center gap-x-1" v-if="edit">
      <decimal-input v-model="editables.amount" class="ml-auto" />
      <select class="add-offer-input" v-model="editables.unit">
        <option v-for="unit in units" :value="unit" :key="unit">
          {{ unit }}
        </option>
      </select>
    </div>
    <span v-else> {{ cost.amount }} {{ cost.unit }} </span>
    <div class="flex">
      <span v-if="!edit">{{ cost.cost }} €</span>
      <decimal-input v-else v-model="editables.cost" />
    </div>
    <div v-if="isAdmin || isManager" class="flex">
      <span v-if="!edit">{{ cost.price }} €</span>
      <decimal-input v-else v-model="editables.price" />
    </div>
    <span v-else> {{ round2(cost.amount * cost.cost) }} € </span>
    <div v-if="showCoefficient && (isAdmin || isManager)" class="flex">
      <span v-if="!edit"> {{ cost.cost_coefficient }}</span>
      <decimal-input v-else v-model="editables.cost_coefficient" />
    </div>
    <span v-if="!edit && (isAdmin || isManager)" class="mobile:col-span-2">{{
      editables.vatType ? editables.vatType.description : "-"
    }}</span>
    <select
      v-if="edit && (isAdmin || isManager)"
      v-model="editables.vatType"
      class="mobile:col-span-2"
    >
      <option
        v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
        :key="taxType.id"
        :value="taxType"
      >
        {{ `${taxType.description}` }}
      </option>
    </select>
    <div
      v-if="isAdmin || isManager"
      class="flex items-center mobile:col-span-2"
    >
      <span class="w-1/2"> {{ costWithCoef(cost) }} €</span>
      <span class="w-1/2"> {{ priceWithVat(cost) }} €</span>
    </div>
    <div
      v-if="isAdmin || isManager"
      class="flex justify-end items-center gap-x-2 mobile:col-span-4"
    >
      <button v-if="!edit" class="icon-button primary" @click="toggleEdit">
        <span class="typcn typcn-edit icon text-white" />
      </button>
      <button
        @click="toggleShowInformation"
        class="icon-button primary"
        :class="{ light: showExtraInformation }"
      >
        <img src="/bc21/info.svg" class="h-4 w-4" alt="?" />
      </button>
    </div>
    <div
      v-if="showExtraInformation"
      class="flex flex-col border-b border-t border-offwhite-dark p-1 mt-1 bg-offwhite col-span-11 mobile:col-span-4"
    >
      <div class="grid grid-cols-7 items-center mobile:grid-cols-4">
        <div class="flex flex-row items-center mobile:col-span-4">
          <span class="font-medium mr-3">Lisaja</span>
          <span>{{ cost.added_by ? cost.added_by.worker_name : "-" }} </span>
        </div>
        <div class="flex flex-row items-center mobile:col-span-4">
          <span class="font-medium mr-3">Töö aeg</span>
          <span>{{
            moment(cost.occurrence_time).format("DD.MM.YYYY HH:mm")
          }}</span>
        </div>
        <span class="col-span-4 mobile:hidden" />
        <div
          v-if="isManager || isAdmin"
          class="flex justify-end items-center gap-x-2 mobile:col-span-4"
        >
          <div v-html="parseCostStatus(cost.status)" />
          <button
            v-if="!edit && [0, 1].includes(cost.status)"
            class="icon-button grey"
            title="Muuda omakuluks"
            @click="selfCostActive = true"
          >
            <span class="typcn typcn-home icon text-white" />
          </button>
          <button v-if="edit" class="icon-button primary" @click="editCost">
            <span class="typcn typcn-tick text-white" />
          </button>
          <button v-if="edit" class="icon-button danger" @click="resetCost">
            <span class="typcn typcn-times text-white" />
          </button>
          <button
            v-if="!edit"
            class="icon-button danger"
            @click="deleteCostActive = true"
          >
            <img
              src="/bc21/trash.svg"
              class="h-4 w-4 filter-to-white"
              alt="Delete icon"
            />
          </button>
        </div>
      </div>
    </div>
    <confirm-modal
      v-if="selfCostActive"
      :header-text="'Kulu muutmine omakuluks'"
      :text="'Kas olete kindel et soovite omakuluks muuta?'"
      @confirm="markAsSelfCost"
      @closeModal="selfCostActive = false"
    />
    <delete-confirm-modal
      v-if="deleteCostActive"
      @confirmDelete="deleteCost"
      @closeModal="deleteCostActive = false"
    />
  </div>
</template>

<script>
import { costWithCoef, priceWithVat, round2 } from "@/assets/utils/commonMath";
import { isEqual } from "lodash";
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { mapGetters } from "vuex";
import DeleteConfirmModal from "@/components/reusable/DeleteConfirmModal.vue";
import moment from "moment";
import StatusParser from "@/assets/mixins/StatusParser";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
import { costUtil } from "@/assets/utils/commonCostMethods";

export default {
  name: "CompactCost",
  components: { ConfirmModal, DeleteConfirmModal, DecimalInput },
  props: {
    cost: {
      type: Object,
      default: null,
    },
    taxTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    relatedSeparate: {
      type: Boolean,
      default: true,
    },
    showCoefficient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      editables: null,
      selfCostActive: false,
      deleteCostActive: false,
      showExtraInformation: false,
      moment: moment,
    };
  },
  mixins: [StatusParser],
  methods: {
    priceWithVat,
    costWithCoef,
    round2,
    toggleEdit() {
      this.edit = true;
      this.showExtraInformation = true;
    },
    toggleShowInformation() {
      if (this.showExtraInformation) {
        this.showExtraInformation = false;
        this.resetCost();
      } else {
        this.showExtraInformation = true;
      }
    },
    resetCost() {
      this.editables = JSON.parse(JSON.stringify(this.cost));
      this.edit = false;
      this.showExtraInformation = false;
    },
    async editCost() {
      if (!isEqual(this.editables, this.cost)) {
        if (this.editables.vatType)
          this.editables.vat = this.editables.vatType.id;
        try {
          const { status } = await costUtil.edit.call(this, this.editables);
          if (status === 200) this.$emit("costsUpdated");
          this.edit = false;
          this.showExtraInformation = false;
        } catch (err) {
          await this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          });
        }
      } else {
        this.edit = false;
        this.showExtraInformation = false;
      }
    },
    async markAsSelfCost() {
      try {
        const { status } = await costUtil.selfCost.call(this, this.editables);
        if (status === 200) this.$emit("costsUpdated");
        this.selfCostActive = false;
      } catch (err) {
        this.selfCostActive = false;
        await this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          error: err,
          text: "Midagi läks valesti",
          ttl: 5,
        });
      }
    },
    async deleteCost() {
      try {
        const { status } = await costUtil.deleteCost.call(this, this.editables);
        if (status === 200) this.$emit("costsUpdated");
      } catch (err) {
        this.deleteCostActive = false;
        await this.$store.dispatch("messageHandler/throwMessage", {
          type: "error",
          error: err,
          text: "Midagi läks valesti",
          ttl: 5,
        });
      }
    },
  },
  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.cost));
  },
  computed: {
    ...mapGetters({
      units: "companyVariables/units",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      companyId: "companyData/activeCompanyUuid",
    }),
  },
};
</script>
<style scoped lang="scss">
input {
  width: 95%;
}
select {
  width: 75%;
  option {
    width: 75%;
  }
}
</style>
