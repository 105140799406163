<template>
  <div class="gwd-table nested h-full">
    <div class="gwd-table-header">Planeeritud tehnika</div>
    <div class="gwd-table-header equipment-assignments-table">
      <span class="mobile:col-span-2">Tehnika nimi</span>
      <span class="mobile:col-span-2">Vastutav töötaja</span>
      <span>Kogus</span>
      <span>Kulu</span>
      <span>Hind</span>
      <span>Koefitsent</span>
      <span class="mobile:col-span-2">Käibemaks</span>
      <span class="mobile:col-span-2">Teenuse makse tüüp</span>
    </div>
    <div class="gwd-table-row-wrapper">
      <equipment-assignment
        v-for="equipmentAssignment in taskEquipment"
        :key="equipmentAssignment.id"
        :equipment-assignment="equipmentAssignment"
        :tax-types="taxTypes"
        @enableDelete="enableDelete"
        @editAssignment="editAssignment"
      />
      <span v-if="taskEquipment.length === 0" class="p-2">
        Määratud varustust pole.
      </span>
      <div class="gwd-table-header mt-2">Tehnika kulud</div>
      <div class="gwd-table-header equipment-costs-table">
        <span class="mobile:col-span-4">Tehnika</span>
        <span>Kogus</span>
        <span>Kulu</span>
        <span>Müügihind</span>
        <span>Koefitsent</span>
        <span class="mobile:col-span-2">Käibemaks</span>
        <span class="mobile:col-span-2">Kulu/Hinna summa</span>
        <span class="mobile:col-span-4" />
      </div>
      <compact-cost
        class="equipment-costs-table"
        v-for="equipmentCost in equipmentCosts"
        :key="equipmentCost.id"
        :cost="equipmentCost"
        :tax-types="taxTypes"
        :show-coefficient="true"
        :related-separate="false"
        @costsUpdated="loadTaskEquipmentCosts"
      />
      <div v-if="equipmentCosts.length === 0" class="p-1">Kulud puuduvad</div>
    </div>
    <div class="gwd-table-bottom-bar">
      <button
        class="btn-primary mr-3"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="
          $store.commit(
            'modals/taskModal/setShowNewEquipmentAssignmentModal',
            true
          )
        "
      >
        <span class="label">Määra tehnika</span>
      </button>
      <button
        class="btn-primary"
        v-if="['R0', 'R1', 'R2'].includes(userPermissions)"
        @click="
          $store.commit('modals/taskModal/setShowNewEquipmentCostModal', {
            show: true,
            equipmentAssignment: null,
          })
        "
      >
        <span class="label">Lisa kulu</span>
      </button>
    </div>
    <confirm-modal
      key="equipment-assignment-delete-modal"
      v-if="showDeleteAssignmentModal"
      text="Oled kindel, et soovid tehnika töölt kustutada?"
      @confirm="deleteEquipmentAssignment"
      @closeModal="
        () => {
          this.deleteObject = null;
          this.showDeleteAssignmentModal = false;
        }
      "
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ConfirmModal from "@/components/reusable/ConfirmModal.vue";
import EquipmentAssignment from "@/components/tasks/costs/EquipmentAssignment.vue";
import { costWithCoef, priceWithVat, round2 } from "@/assets/utils/commonMath";
import set from "lodash/set";
import moment from "moment";
import CompactCost from "@/components/tasks/costs/CompactCost.vue";
import EventBus from "@/assets/mixins/EventBus";

export default {
  name: "TaskEquipmentAssignments",
  components: {
    CompactCost,
    EquipmentAssignment,
    ConfirmModal,
  },
  props: {
    taskId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      taskEquipment: [],
      showDeleteAssignmentModal: false,
      deleteObject: null,
      equipmentCosts: [],
      moment: moment,
    };
  },
  methods: {
    priceWithVat,
    costWithCoef,
    round2,
    loadTaskEquipment() {
      this.apiRequest(
        `template/${this.companyId}/equipment/assignments/?task=${this.taskId}`,
        "get",
        true
      ).then((res) => {
        this.taskEquipment = res.data;
        for (let equipmentAssignment of this.taskEquipment) {
          if (equipmentAssignment.vat) {
            set(
              equipmentAssignment,
              "vatType",
              this.taxTypes.find((x) => x.id === equipmentAssignment.vat)
            );
          }
        }
      });
    },
    loadTaskEquipmentCosts() {
      this.apiRequest(
        `costs/${this.companyId}/task/${this.taskId}/equipment/`,
        "get",
        true
      ).then((res) => {
        this.equipmentCosts = res.data;
        for (let equipmentCost of this.equipmentCosts) {
          if (equipmentCost.vat) {
            set(
              equipmentCost,
              "vatType",
              this.taxTypes.find((x) => x.id === equipmentCost.vat)
            );
          }
        }
      });
    },
    enableDelete(e) {
      this.showDeleteAssignmentModal = true;
      this.deleteObject = e;
    },
    deleteEquipmentAssignment() {
      this.apiRequest(
        `template/${this.companyId}/equipment/${this.deleteObject.equipment.id}/assignment/${this.deleteObject.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.loadTaskEquipment();
        }
      });
    },

    editAssignment(e) {
      e.vat = e.vatType ? e.vatType.id : e.vat;
      this.apiRequest(
        `template/${this.companyId}/equipment/${e.equipment.id}/${e.id}/update/`,
        "patch",
        true,
        e
      )
        .then((res) => {
          if (res.status === 200) this.loadTaskEquipment();
        })
        .catch((err) => {
          this.$store.dispatch("messageHandler/throwMessage", {
            type: "error",
            error: err,
            text: "Midagi läks valesti",
            ttl: 5,
          });
        });
    },
  },
  async mounted() {
    if ((this.isAdmin || this.isAdmin) && !this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    this.loadTaskEquipment();
    this.loadTaskEquipmentCosts();
    EventBus.$on("equipmentAssignmentAdded", this.loadTaskEquipment);
    EventBus.$on("equipmentCostAdded", this.loadTaskEquipmentCosts);
  },
  beforeDestroy() {
    EventBus.$off("equipmentAssignmentAdded", null);
    EventBus.$off("equipmentCostAdded", null);
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      userPermissions: "companyData/userPermission",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      taxTypes: "companyVariables/taxTypes",
    }),
  },
};
</script>

<style lang="scss">
.equipment-assignments-table {
  //                     name worker amount cost costCo price vat type  delete
  grid-template-columns: 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr;
  @media screen and (max-width: 601px) {
    @apply grid-cols-4 border-b border-grey-light;
  }
}
.equipment-costs-table {
  //                     name        cost        cost_co     sum
  //                           amount      price       vat         buttons
  grid-template-columns: 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.1fr;
  @media screen and (max-width: 601px) {
    @apply grid-cols-4 border-b border-grey-light;
  }
}
</style>
