<template>
  <div class="modal text-sm">
    <div
      class="modal-body modal-medium modal-vwide"
      v-on-clickaway:mousedown="closeModal"
    >
      <div class="modal-content flex-col w-full" v-if="itemInited">
        <div class="gwd-table nested">
          <div class="gwd-table-row template-generator-modal-rows">
            <span>Programmikood</span>
            <span>Kirjeldus</span>
            <span>Kogus</span>
            <span />
            <span>Kulu</span>
            <span>Koefitsent</span>
            <span>Hind</span>
            <span />
            <span>Käibemaks</span>
            <span>Kulu/Hinna summa</span>
          </div>
          <div
            v-for="cost in costs"
            :key="cost.id"
            class="gwd-table-row template-generator-modal-rows"
          >
            <span />
            <span>{{ cost.description }}</span>
            <span>{{ cost.amount }} {{ cost.unit }}</span>
            <span />
            <span>{{ cost.cost }}</span>
            <span>{{ cost.cost_coefficient }}</span>
            <span>{{ cost.price }}</span>
            <span />
            <span>{{ cost.vatType ? cost.vatType.description : "-" }}</span>
            <div class="flex items-center">
              <span class="w-1/2"> {{ costWithCoef(cost) }} €</span>
              <span class="w-1/2"> {{ priceWithVat(cost) }} €</span>
            </div>
          </div>
          <div class="gwd-table-row template-generator-modal-rows">
            <div class="pr-2">
              <select
                v-model="item.serviceObj"
                class="w-full"
                :class="
                  showInvalid && !item.serviceObj
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
                @change="
                  setDefaultServiceObjectFields(
                    item,
                    serviceObjects,
                    taxTypes,
                    defaultTaxType(client, payer, taxTypes)
                  )
                "
              >
                <option
                  v-for="serviceObj in serviceObjects"
                  :key="serviceObj.id"
                  :value="serviceObj"
                  :disabled="serviceObj.service_code.length === 2"
                >
                  {{
                    `${serviceObj.service_code} - ${serviceObj.service_name}`
                  }}
                </option>
              </select>
            </div>
            <div class="no-hover pr-2">
              <input type="text" v-model="item.description" class="w-full" />
            </div>
            <div class="no-hover pr-2">
              <decimal-input
                v-model="item.amount"
                class="w-full"
                :class="
                  showInvalid && !item.amount
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              />
            </div>
            <div class="no-hover pr-2">
              <select class="add-offer-input" v-model="item.unit">
                <option v-for="unit in units" :value="unit" :key="unit">
                  {{ unit }}
                </option>
              </select>
            </div>
            <div></div>
            <div></div>
            <div class="no-hover pr-2">
              <decimal-input
                step="0.01"
                v-model="item.price"
                class="w-full"
                :class="
                  showInvalid && !item.price
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              />
            </div>
            <div class="no-hover pr-2">
              <span> {{ round2(item.amount * item.price) }} €</span>
            </div>
            <div class="no-hover pr-2">
              <select
                v-if="taxTypes"
                v-model="item.taxType"
                class="max-w-full"
                :class="
                  showInvalid && !item.vatType
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              >
                <option
                  v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                  :key="taxType.id"
                  :value="taxType"
                >
                  {{
                    taxType
                      ? `${taxType.description} (${
                          taxType.tax_percentage ? taxType.tax_percentage : "0"
                        }%)`
                      : "-"
                  }}
                </option>
              </select>
            </div>
            <div class="flex items-center">
              <span class="w-1/2" />
              <span class="w-1/2">
                {{ priceWithVat(item) }}
                {{ currency ? currency.sign : "€" }}
              </span>
            </div>
          </div>
          <div class="gwd-table-row template-generator-modal-rows">
            <div class="pr-2" />
            <div class="items-center pr-2">
              <button class="btn-primary mr-3" @click="addDate">
                Lisa kuupäev
                <span class="label ml-1">
                  ({{
                    task
                      ? moment(task.start_time).format("DD.MM.YYYY")
                      : moment(ee).format("DD.MM.YYYY")
                  }})
                </span>
              </button>
              <button
                v-if="task"
                :class="task.address ? 'btn-primary' : 'btn-disabled'"
                @click="addStartAddress"
              >
                Algaadress
              </button>
              <button
                v-if="task"
                class="mx-3"
                :class="task.end_address ? 'btn-primary' : 'btn-disabled'"
                @click="addEndAddress"
              >
                Lõppaadress
              </button>
              <button
                v-if="task"
                :class="
                  task.address && task.end_address
                    ? 'btn-primary'
                    : 'btn-disabled'
                "
                @click="addBothAddress"
              >
                Alg - Lõpp
              </button>
            </div>
            <div class="flex items-center text-sm gap-x-3" v-if="!amountAdded">
              <button class="btn-primary" @click="calculateAverageAmount">
                <span class="label">kesk.</span>
              </button>
              <button class="btn-primary" @click="calculateSumAmount">
                <span class="label">sum</span>
              </button>
              <button class="btn-primary" @click="calculateMaxAmount">
                <span class="label">max</span>
              </button>
            </div>
            <span v-else />
            <span />
            <span />
            <span />
            <div class="flex items-center text-sm gap-x-3" v-if="!priceAdded">
              <button class="btn-primary" @click="calculateAveragePrice">
                <span class="label">kesk.</span>
              </button>
              <button class="btn-primary" @click="calculateSumPrice">
                <span class="label">sum</span>
              </button>
              <button class="btn-primary" @click="calculateMaxPrice">
                <span class="label">max</span>
              </button>
            </div>
            <span v-else />
            <span />
            <span />
            <div
              class="flex items-center text-sm"
              v-if="!priceAdded && !amountAdded"
            >
              <button class="btn-primary" @click="calculateSumOfEach">
                <span class="lable">Summa</span>
              </button>
            </div>
            <span v-else />
          </div>
          <div
            class="gwd-table-row template-generator-modal-rows"
            v-if="task && (task.address || task.end_address)"
          >
            <div />
            <div class="col-span-8">
              {{ task.address }} - {{ task.end_address }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveRevenue">Salvesta</button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Revenue } from "@/assets/types/Revenue";
import moment from "moment";
import { costWithCoef, priceWithVat, round2 } from "@/assets/utils/commonMath";
import DecimalInput from "../reusable/DecimalInput.vue";
import { ee } from "vuejs-datepicker/dist/locale";
import {
  setDefaultServiceObjectFields,
  defaultTaxType,
} from "@/assets/utils/commonInvoiceMethods";
export default {
  name: "CostTemplateRevenueGeneratorModal",
  props: {
    template: {
      type: Object,
      default: null,
    },
    costs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    task: {
      type: Object,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    payer: {
      type: Object,
      default: null,
    },
  },
  components: { DecimalInput },
  data() {
    return {
      item: null,
      itemInited: false,
      showInvalid: false,
      currency: null,
      moment: moment,
      amountAdded: false,
      priceAdded: false,
      ee: ee,
    };
  },
  methods: {
    setDefaultServiceObjectFields,
    defaultTaxType,
    priceWithVat,
    costWithCoef,
    round2,
    calculateAverageAmount() {
      this.item.amount = round2(
        this.costs.map((x) => parseFloat(x.amount)).reduce((x, y) => x + y, 0) /
          this.costs.length
      );
      this.amountAdded = true;
    },
    calculateSumAmount() {
      this.item.amount = round2(
        this.costs.map((x) => parseFloat(x.amount)).reduce((x, y) => x + y, 0)
      );
      this.amountAdded = true;
    },
    calculateMaxAmount() {
      this.item.amount = round2(
        Math.max(...this.costs.map((x) => parseFloat(x.amount)))
      );
      this.amountAdded = true;
    },
    calculateAveragePrice() {
      this.item.price = round2(
        this.costs.map((x) => parseFloat(x.price)).reduce((x, y) => x + y, 0) /
          this.costs.length
      );
      this.priceAdded = true;
    },
    calculateSumPrice() {
      this.item.price = round2(
        this.costs.map((x) => parseFloat(x.price)).reduce((x, y) => x + y, 0)
      );
      this.priceAdded = true;
    },
    calculateMaxPrice() {
      this.item.price = round2(
        Math.max(...this.costs.map((x) => parseFloat(x.price)))
      );
      this.priceAdded = true;
    },
    calculateSumOfEach() {
      this.item.amount = 1;
      this.item.price = round2(
        this.costs
          .map(
            (x) =>
              parseFloat(x.price) * parseFloat(x.amount) +
              (parseFloat(x.price) *
                parseFloat(x.amount) *
                parseFloat(x.vatType ? x.vatType.tax_percentage : "0")) /
                100
          )
          .reduce((x, y) => x + y, 0)
      );
      this.priceAdded = true;
      this.amountAdded = true;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    saveRevenue() {
      this.$emit("revenueSaved", this.item);
      this.closeModal();
    },
    addDate() {
      if (this.task) {
        this.item.description = `${this.item.description} ${moment(
          this.task.start_time
        ).format("DD.MM.YYYY")}`;
      } else {
        this.item.description = `${this.item.description} (${moment(ee).format(
          "DD.MM.YYYY"
        )})`;
      }
    },
    addStartAddress() {
      if (!this.task.address) return;
      const start = this.task.address.split(",");
      let addr;
      if (start.length > 2) {
        addr = start[0];
      } else {
        addr = `${start[0]}, ${start[1]}`;
      }
      this.item.description = `${this.item.description} ${addr}`;
    },
    addEndAddress() {
      if (!this.task.end_address) return;
      const end = this.task.end_address.split(",");
      let endAddr;
      if (end.length > 2) {
        endAddr = end[0];
      } else {
        endAddr = `${end[0]}, ${end[1]}`;
      }
      this.item.description = `${this.item.description} ${endAddr}`;
    },
    addBothAddress() {
      if (!this.task.address) return;
      if (!this.task.end_address) return;
      const start = this.task.address.split(",");
      let addr;
      if (start.length > 2) {
        addr = start[0];
      } else {
        addr = `${start[0]}, ${start[1]}`;
      }
      const end = this.task.end_address.split(",");
      let endAddr;
      if (end.length > 2) {
        endAddr = end[0];
      } else {
        endAddr = `${end[0]}, ${end[1]}`;
      }
      this.item.description = `${this.item.description} ${addr} - ${endAddr}`;
    },
    removeCostFromTemplateCosts(e) {
      this.$emit("removeCostFromTemplateCosts", e);
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
    let vat = this.template.vatType ?? null;
    let serviceCode = this.template.serviceCode ?? null;
    console.log("VAT", vat);
    this.item = new Revenue(
      null,
      serviceCode,
      0,
      this.template.unit,
      vat,
      this.template.price,
      this.costs.map((x) => `${x.type}${x.id}`),
      this.template.description
    );
    setDefaultServiceObjectFields(
      this.item,
      this.serviceObjects,
      this.taxTypes,
      this.defaultTaxType(this.client, this.payer, this.taxTypes),
      true
    );
    this.itemInited = true;
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
      companyId: "companyData/activeCompanyUuid",
      currencyList: "companyVariables/currencyList",
      serviceObjects: "companyVariables/serviceObjects",
      units: "companyVariables/units",
    }),
  },
};
</script>
<style lang="scss" scoped>
.template-generator-modal-rows {
  //                    service name amount cost cost_co price ?  ?   vat  sum
  //grid-template-columns: 0.4fr 1fr 0.4fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.4fr 0.4fr;
  grid-template-columns: 0.3fr 0.9fr 0.4fr 0.2fr 0.2fr 0.2fr 0.4fr 0.3fr 0.4fr 0.3fr;
}
</style>
