var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table nested h-full"},[_c('div',{staticClass:"gwd-table-header"},[_vm._v("Planeeritud tehnika")]),_vm._m(0),_c('div',{staticClass:"gwd-table-row-wrapper"},[_vm._l((_vm.taskEquipment),function(equipmentAssignment){return _c('equipment-assignment',{key:equipmentAssignment.id,attrs:{"equipment-assignment":equipmentAssignment,"tax-types":_vm.taxTypes},on:{"enableDelete":_vm.enableDelete,"editAssignment":_vm.editAssignment}})}),(_vm.taskEquipment.length === 0)?_c('span',{staticClass:"p-2"},[_vm._v(" Määratud varustust pole. ")]):_vm._e(),_c('div',{staticClass:"gwd-table-header mt-2"},[_vm._v("Tehnika kulud")]),_vm._m(1),_vm._l((_vm.equipmentCosts),function(equipmentCost){return _c('compact-cost',{key:equipmentCost.id,staticClass:"equipment-costs-table",attrs:{"cost":equipmentCost,"tax-types":_vm.taxTypes,"show-coefficient":true,"related-separate":false},on:{"costsUpdated":_vm.loadTaskEquipmentCosts}})}),(_vm.equipmentCosts.length === 0)?_c('div',{staticClass:"p-1"},[_vm._v("Kulud puuduvad")]):_vm._e()],2),_c('div',{staticClass:"gwd-table-bottom-bar"},[(['R0', 'R1', 'R2'].includes(_vm.userPermissions))?_c('button',{staticClass:"btn-primary mr-3",on:{"click":function($event){return _vm.$store.commit(
          'modals/taskModal/setShowNewEquipmentAssignmentModal',
          true
        )}}},[_c('span',{staticClass:"label"},[_vm._v("Määra tehnika")])]):_vm._e(),(['R0', 'R1', 'R2'].includes(_vm.userPermissions))?_c('button',{staticClass:"btn-primary",on:{"click":function($event){return _vm.$store.commit('modals/taskModal/setShowNewEquipmentCostModal', {
          show: true,
          equipmentAssignment: null,
        })}}},[_c('span',{staticClass:"label"},[_vm._v("Lisa kulu")])]):_vm._e()]),(_vm.showDeleteAssignmentModal)?_c('confirm-modal',{key:"equipment-assignment-delete-modal",attrs:{"text":"Oled kindel, et soovid tehnika töölt kustutada?"},on:{"confirm":_vm.deleteEquipmentAssignment,"closeModal":function () {
        this$1.deleteObject = null;
        this$1.showDeleteAssignmentModal = false;
      }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table-header equipment-assignments-table"},[_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Tehnika nimi")]),_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Vastutav töötaja")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v("Kulu")]),_c('span',[_vm._v("Hind")]),_c('span',[_vm._v("Koefitsent")]),_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Käibemaks")]),_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Teenuse makse tüüp")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gwd-table-header equipment-costs-table"},[_c('span',{staticClass:"mobile:col-span-4"},[_vm._v("Tehnika")]),_c('span',[_vm._v("Kogus")]),_c('span',[_vm._v("Kulu")]),_c('span',[_vm._v("Müügihind")]),_c('span',[_vm._v("Koefitsent")]),_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Käibemaks")]),_c('span',{staticClass:"mobile:col-span-2"},[_vm._v("Kulu/Hinna summa")]),_c('span',{staticClass:"mobile:col-span-4"})])}]

export { render, staticRenderFns }