<template>
  <div class="gwd-table-row nopad group flex flex-col">
    <div class="flex gwd-table-row nopady tasks-costs-table gap-x-2">
      <div
        class="flex h-full justify-center items-center gap-x-1 text-white font-medium group-last:rounded-bl"
        :style="{ backgroundColor: type.color }"
        :title="type.name"
      >
        <span>{{ type.name[0] }}</span>
      </div>

      <span
        v-if="!edit || (edit && cost.type === `workerCost`)"
        class="flex truncate mr-3"
        :title="cost.description"
      >
        {{ cost.description }}
      </span>
      <input v-else type="text" v-model="editables.name" />
      <span v-if="!edit || (edit && cost.type === `workerCost`)">
        {{ cost.amount }} {{ cost.unit }}
      </span>
      <div v-else class="flex justify-end items-center gap-x-1">
        <decimal-input v-model="editables.amount" class="ml-auto" />
        <select class="add-offer-input" v-model="editables.unit">
          <option v-for="unit in units" :value="unit" :key="unit">
            {{ unit }}
          </option>
        </select>
      </div>
      <span v-if="!edit" class="flex"> {{ cost.cost }}€</span>
      <decimal-input v-else v-model="editables.cost" />
      <span> {{ round2(cost.cost * cost.amount) }} €</span>
      <span v-if="!edit" class="flex">
        {{ cost.cost_coefficient ? cost.cost_coefficient : "-" }}</span
      >
      <decimal-input v-else v-model="editables.cost_coefficient" />
      <div class="flex items-center" v-if="!edit">
        <span> {{ costWithCoef(cost) }} €</span>
      </div>
      <div class="flex items-center" v-else>
        <span> {{ costWithCoef(editables) }} €</span>
      </div>
      <div
        v-if="!edit"
        class="flex truncate cursor-pointer mr-3"
        :title="cost.info"
      >
        {{ cost.info ? cost.info : "" }}
      </div>
      <input v-else type="text" v-model="editables.info" maxlength="128" />

      <span v-html="parseCostStatus(cost.status)" />
      <div class="flex items-center justify-end pr-2 gap-x-2">
        <div
          v-if="
            ['workerCost', 'extraCost'].includes(cost.type) &&
            !edit &&
            cost.status !== 5
          "
        >
          <button
            class="btn-primary"
            v-if="!cost.confirmed"
            @click="confirmCost"
          >
            Kinnita
          </button>
          <span v-else class="typcn typcn-tick">Kinnitatud</span>
        </div>
        <button
          v-if="!edit && cost.status !== 5 && !cost.confirmed"
          class="icon-button primary"
          @click="edit = true"
        >
          <span class="typcn typcn-edit text-white" />
        </button>
        <button v-if="edit" class="icon-button primary" @click="editCost">
          <span class="typcn typcn-tick text-white" />
        </button>
        <button v-if="edit" class="icon-button danger" @click="reset">
          <span class="typcn typcn-times text-white" />
        </button>
        <button
          v-if="edit && [0, 1, 4].includes(cost.status) && isAdmin"
          class="icon-button danger"
          @click="deleteCost"
        >
          <span class="typcn typcn-trash text-white" />
        </button>
        <button
          v-if="!edit"
          @click="showExtraInformation = !showExtraInformation"
          class="h-6 w-6 bg-primary hover:bg-primary-dark rounded shadow flex items-center justify-center"
        >
          <img src="/bc21/info.svg" class="h-4 w-4 filter-to-white" alt="?" />
        </button>
      </div>
    </div>
    <div
      v-if="showExtraInformation"
      class="flex flex-col border-b border-t border-offwhite-dark p-1 bg-offwhite"
    >
      <div class="grid grid-cols-6 font-medium">
        <span>Lisaja</span>
        <span>Töö aeg</span>
        <span>Kinnitaja</span>
        <span>Kinnitamise aeg</span>
        <span class="col-span-2">Seotud arved</span>
      </div>
      <div class="grid grid-cols-6">
        <span>{{ cost.added_by ? cost.added_by.worker_name : "-" }}</span>
        <span>{{
          moment(cost.occurrence_time).format("DD.MM.YYYY HH:mm")
        }}</span>
        <span>{{
          cost.confirmed
            ? cost.confirmed_by
              ? cost.confirmed_by.worker_name
              : "Kinnitaja puudub"
            : "Kinnitamata"
        }}</span>
        <span>{{
          cost.confirmed_time
            ? moment(cost.confirmed_time).format("DD.MM.YYYY HH:mm")
            : "-"
        }}</span>
        <div class="flex flex-grow items-center col-span-2">
          <span
            class="p-1 cursor-pointer inline-block rounded font-medium"
            :class="
              invoice.manually_checked ? 'bg-green' : 'bg-pending text-white'
            "
            v-for="invoice in cost.outgoing_invoices"
            :key="invoice.id"
            @click="routeToOutgoingInvoice(invoice)"
          >
            Arve
            {{
              invoice.manually_checked
                ? `nr: ${invoice.invoice_nr}`
                : `id: ${invoice.id}`
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DecimalInput from "@/components/reusable/DecimalInput.vue";
import { costWithCoef, round2 } from "@/assets/utils/commonMath";
import moment from "moment/moment";
import { StatusParser } from "@/assets/mixins/GewodoMixins";
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { mapGetters } from "vuex";

export default {
  name: "TaskCost",
  props: {
    cost: {
      type: Object,
      default: () => {},
    },
  },
  components: { DecimalInput },
  mixins: [StatusParser, CostTypeParser],

  data() {
    return {
      edit: false,
      editables: null,
      showExtraInformation: false,
      moment: moment,
    };
  },
  mounted() {
    this.editables = JSON.parse(JSON.stringify(this.cost));
  },
  methods: {
    round2,
    costWithCoef,
    confirmCost() {
      this.displayTimes = false;
      this.$emit("confirm", this.cost);
    },
    editCost() {
      this.$emit("editCost", this.editables);
      this.edit = false;
    },
    deleteCost() {
      this.$emit("deleteCost", this.editables);
      this.edit = false;
    },
    reset() {
      this.editables = JSON.parse(JSON.stringify(this.cost));
      this.edit = false;
    },
    routeToOutgoingInvoice(invoice) {
      const route = this.$router.resolve({
        path: invoice.manually_checked
          ? `/invoices/outgoing/confirmed/${invoice.id}`
          : `/invoices/outgoing/${invoice.id}`,
        query: {
          view: 6,
        },
      });
      window.open(route.href, "_blank");
    },
  },
  computed: {
    ...mapGetters({
      units: "companyVariables/units",
      isAdmin: "companyData/isAdmin",
    }),
    type() {
      return this.getCostTypeObject(this.cost.type);
    },
    timer() {
      if (this.cost.type !== "workerCost") return null;
      return this.taskTimers.find((x) => x.cost_id === this.cost.id);
    },
  },
};
</script>
