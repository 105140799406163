<template>
  <div class="flex flex-col">
    <div class="flex items-start gap-x-2 mobile:flex-col parent">
      <div
        class="flex flex-col bg-white shadow rounded items-center justify-center w-52 mobile:w-full"
      >
        <div
          class="bg-offwhite w-full rounded-t justify-center flex py-1 cursor-pointer"
          @click="filterBy = null"
        >
          <h4>Töötaja</h4>
        </div>
        <div
          v-for="worker in taskWorkers"
          :key="worker.id"
          class="p-2 mobile:p-1 last:rounded-b hover:bg-primary hover:text-white duration-100 w-full cursor-pointer truncate block overflow-ellipsis"
          :class="{ 'bg-primary text-white': filterBy === worker.id }"
          :title="worker.worker_name"
          @click="filterBy = worker.id"
        >
          {{ worker.worker_name }}
        </div>
      </div>
      <div class="timers">
        <div class="header-row" @click="openGeoMapEveryBody">
          <div class="flex mobile:col-span-2"><h4>Töötaja</h4></div>
          <div class="flex"><h4>Kestvus</h4></div>
          <div class="flex"><h4>Kuupäev</h4></div>
          <div class="flex"><h4>Plan. algus</h4></div>
          <div class="flex"><h4>Plan. lõpp</h4></div>
          <div class="flex"><h4>Tegelik algus</h4></div>
          <div class="flex"><h4>Tegelik lõpp</h4></div>
          <div class="flex mobile:col-span-2"><h4>Info</h4></div>
          <div class="flex mobile:col-span-2"></div>
        </div>
        <task-timer-item
          v-for="item in filteredTimerData"
          :key="item.id"
          :item="item"
          @endTimerAsManager="endStopperAsManager"
          @openGeoMap="openGeoMap"
          @confirm="confirmTime"
          @deleteTime="deleteTime"
          @update="updateTime"
          ref="timers"
          :class="{
            'bg-primary/25': outliers.map((x) => x.id).includes(item.id),
          }"
        />
        <div class="p-2" v-if="filteredTimerData.length === 0">
          <span>Tööaegu pole</span>
        </div>
        <div v-if="stopperToAdd" class="timer-to-add-row">
          <div class="flex col-span-2 pr-2 mobile:pr-2">
            <select v-model="stopperToAdd.worker">
              <option
                v-for="worker in taskWorkers"
                :key="worker.id"
                :value="worker.id"
              >
                {{ worker.worker_name }}
              </option>
            </select>
          </div>
          <div class="flex col-span-2">
            <span v-if="stopperProcessed">
              {{
                moment
                  .utc(
                    moment
                      .duration(
                        moment(this.stopperProcessed.stopper_end).diff(
                          moment(this.stopperProcessed.stopper_start)
                        )
                      )
                      .as("milliseconds")
                  )
                  .format("HH:mm:ss")
              }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="flex col-span-2">
            <date-selector
              v-model="stopperToAdd.date"
              :key="`dsrr-${filteredTimerData.length}`"
              :pos="filteredTimerData.length > 5 ? 't' : 'b'"
            />
          </div>
          <div class="flex pr-2 mobile:pr-2">
            <time-selector
              :key="`ts1rr-${filteredTimerData.length}`"
              v-model="stopperToAdd.startTime"
              :pos="filteredTimerData.length > 5 ? 't' : 'b'"
            />
          </div>
          <div class="flex pr-2 mobile:pr-2">
            <time-selector
              :key="`ts2rr-${filteredTimerData.length}`"
              v-model="stopperToAdd.endTime"
              :pos="filteredTimerData.length > 5 ? 't' : 'b'"
            />
          </div>
          <div class="flex">
            <button
              :class="
                saving || !stopperProcessed ? 'btn-disabled' : 'btn-primary'
              "
              @click="saveNewStopper"
              :disabled="saving || !stopperProcessed"
            >
              <ClipLoader size="20px" v-if="saving" />
              <span v-else>Lisa</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DateSelector from "../../globals/DateSelector.vue";
import TimeSelector from "../../reusable/TimeSelector.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import TaskTimerItem from "@/components/tasks/taskmodal/TaskTimerItem.vue";

export default {
  data() {
    return {
      filterBy: null,
      moment: moment,
      stopperToAdd: null,
      saving: false,
      localTimerData: [],
    };
  },
  components: {
    TaskTimerItem,
    DateSelector,
    TimeSelector,
    ClipLoader,
  },
  methods: {
    findTimeOutliersStd(times) {
      const timeToMinutes = (timeStr) => {
        const [hours, minutes] = moment(timeStr)
          .format("HH:mm")
          .split(":")
          .map(Number);
        return hours * 60 + minutes;
      };

      const minutesList = times.map(timeToMinutes);

      // Sort minutes to work with clusters
      const sortedMinutes = [...minutesList].sort((a, b) => a - b);

      // Find gaps between consecutive times
      const gaps = [];
      for (let i = 1; i < sortedMinutes.length; i++) {
        gaps.push({
          gap: sortedMinutes[i] - sortedMinutes[i - 1],
          beforeValue: sortedMinutes[i - 1],
          afterValue: sortedMinutes[i],
          index: i,
        });
      }

      // Find the largest gap
      const SIGNIFICANT_GAP = 15;
      const significantGaps = gaps.filter((g) => g.gap > SIGNIFICANT_GAP);

      if (significantGaps.length > 0) {
        // Find which side of the gap has more times
        const maxGap = significantGaps[0];
        const timesBeforeGap = maxGap.index;
        const timesAfterGap = sortedMinutes.length - maxGap.index;

        // The side with fewer times contains the outliers
        if (timesBeforeGap < timesAfterGap) {
          // Earlier times are outliers
          const threshold = maxGap.afterValue;
          return times.filter((time, index) => {
            const minutes = minutesList[index];
            return minutes < threshold;
          });
        } else {
          // Later times are outliers
          const threshold = maxGap.beforeValue;
          return times.filter((time, index) => {
            const minutes = minutesList[index];
            return minutes > threshold;
          });
        }
      }

      return []; // No outliers found if no significant gap exists
    },
    openGeoMap(timerData) {
      if (timerData.start_location || timerData.end_location)
        this.$store.dispatch("modals/taskModal/openTimerMap", [timerData]);
    },
    openGeoMapEveryBody() {
      const data = this.timerData.filter(
        (x) => x.start_location || x.end_location
      );
      if (data.length > 0)
        this.$store.dispatch("modals/taskModal/openTimerMap", data);
    },
    confirmTime(workerTime) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${workerTime.worker_id}/${workerTime.cost_id}/confirm/`,
        "post",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.$store.dispatch(
              "modals/taskModal/retrieveTimerData",
              this.taskData.id
            );
          }
        })
        .catch((err) => {
          if (
            err.response.status === 409 ||
            { err }.err.response.status === 409
          )
            this.$store.dispatch("messageHandler/throwMessage", {
              text: "Kinnitamine ebaõnnestus, lõppajata aega ei saa kinnitada!",
              type: "error",
              ttl: 15,
            });
        });
    },
    deleteTime(time) {
      this.apiRequest(
        `tasks/${this.companyId}/${time.worker_id}/${time.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch(
            "modals/taskModal/retrieveTimerData",
            this.taskData.id
          );
        }
      });
    },
    updateTime(data) {
      this.apiRequest(
        `tasks/${this.companyId}/${data.workerId}/${data.id}/edit/`,
        "patch",
        true,
        {
          start_time: data.startTime,
          end_time: data.endTime,
        }
      ).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch(
            "modals/taskModal/retrieveTimerData",
            this.taskData.id
          );
        }
      });
    },
    saveNewStopper() {
      this.saving = true;
      this.apiRequest(
        `tasks/${this.companyId}/stopper/create/`,
        "post",
        true,
        this.stopperProcessed
      )
        .then((res) => {
          if (res.status === 201) {
            this.$store.dispatch(
              "modals/taskModal/retrieveTimerData",
              this.taskData.id
            );
            const start = moment(this.taskData.start_time);
            const end = moment(this.taskData.end_time);
            this.stopperToAdd = {
              worker: null,
              date: start,
              startTime: {
                hour: start.hour(),
                minute: start.minute(),
              },
              endTime: {
                hour: end.hour(),
                minute: end.minute(),
              },
            };
            this.saving = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 10,
          });
          this.saving = false;
        });
    },
    endStopperAsManager(e) {
      this.saving = true;
      this.apiRequest(
        `tasks/${this.taskData.id}/stopper/${e.id}/stop/`,
        "put",
        true,
        {}
      ).then((res) => {
        if (res.status === 200) {
          this.$store
            .dispatch("modals/taskModal/retrieveTimerData", this.taskData.id)
            .then(() => {
              this.localTimerData = [];
            });
          this.saving = false;
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(
      "modals/taskModal/retrieveTimerData",
      this.taskData.id
    );
    const start = moment(this.taskData.start_time);
    const end = moment(this.taskData.end_time);
    this.stopperToAdd = {
      worker: null,
      date: start,
      startTime: {
        hour: start.hour(),
        minute: start.minute(),
      },
      endTime: {
        hour: end.hour(),
        minute: end.minute(),
      },
    };
    this.localTimerData = JSON.parse(JSON.stringify(this.timerData));
  },
  watch: {
    timerData: {
      deep: true,
      handler() {
        this.localTimerData = JSON.parse(JSON.stringify(this.timerData));
      },
    },
  },
  computed: {
    ...mapGetters({
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
      taskData: "modals/taskModal/taskData",
      companyId: "companyData/activeCompanyUuid",
      isManager: "companyData/isManager",
    }),
    filteredTimerData() {
      if (!this.localTimerData) return null;
      if (this.filterBy)
        return this.localTimerData.filter((x) => x.worker_id === this.filterBy);
      return this.localTimerData;
    },
    stopperProcessed() {
      if (
        !this.stopperToAdd.date ||
        !this.stopperToAdd.startTime ||
        !this.stopperToAdd.endTime ||
        !this.taskData.id ||
        !this.stopperToAdd.worker
      )
        return null;

      let data = {
        worker: this.stopperToAdd.worker,
        stopper_start: moment(
          `${moment(this.stopperToAdd.date).format("YYYY-MM-DD")} ${
            this.stopperToAdd.startTime.hour
          }:${String(this.stopperToAdd.startTime.minute).padStart(2, "0")}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        stopper_end: moment(
          `${moment(this.stopperToAdd.date).format("YYYY-MM-DD")} ${
            this.stopperToAdd.endTime.hour
          }:${String(this.stopperToAdd.endTime.minute).padStart(2, "0")}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        task: this.taskData.id,
      };
      if (this.taskData.related_object)
        data.project = this.taskData.related_object.object_uuid;
      return data;
    },
    outliers() {
      const timeGroups = [];
      for (let timer of this.localTimerData) {
        let group = timeGroups.find(
          (x) =>
            x.start_time === timer.task_start && x.end_time === timer.task_end
        );
        if (group) {
          group.timers = [
            ...group.timers,
            {
              stopper_start: timer.stopper_start,
              stopper_end: timer.stopper_end,
              id: timer.id,
              worker_name: timer.worker_name,
            },
          ];
        } else {
          timeGroups.push({
            start_time: timer.task_start,
            end_time: timer.task_end,
            timers: [
              {
                stopper_start: timer.stopper_start,
                stopper_end: timer.stopper_end,
                id: timer.id,
                worker_name: timer.worker_name,
              },
            ],
          });
        }
      }

      let startOutliers = [];
      let endOutliers = [];

      for (let group of timeGroups) {
        const startTimes = group.timers.map((x) => x.stopper_start);
        const endTimes = group.timers.map((x) => x.stopper_end);

        const outlierStart = this.findTimeOutliersStd(startTimes);
        const outlierEnd = this.findTimeOutliersStd(endTimes);
        startOutliers.push(
          group.timers
            .filter((x) => outlierStart.includes(x.stopper_start))
            .map((x) => {
              return {
                id: x.id,
                start_time: moment(x.stopper_start).format("HH:mm"),
                worker_name: x.worker_name,
              };
            })
        );
        endOutliers.push(
          group.timers
            .filter((x) => outlierEnd.includes(x.stopper_end))
            .map((x) => {
              return {
                id: x.id,
                end_time: moment(x.stopper_end).format("HH:mm"),
                worker_name: x.worker_name,
              };
            })
        );
      }

      return [...startOutliers, ...endOutliers].flat();
    },
  },
};
</script>
<style lang="scss">
.parent {
  @apply flex overflow-hidden;
}
.header-row {
  @apply grid border-t border-offwhite-dark py-2 items-center px-2;
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.5fr 0.2fr;
  @media screen and (max-width: 601px) {
    @apply grid-cols-2 border-b border-grey-light;
    grid-template-columns: 1fr 1fr;
  }
}
.timers {
  @apply flex flex-grow flex-col bg-white rounded shadow w-full;
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.5fr 0.2fr;
  @media screen and (max-width: 601px) {
    @apply grid-cols-2;
    grid-template-columns: 1fr 1fr;
  }
}
.timer-rows {
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.5fr 0.2fr;
  @media screen and (max-width: 601px) {
    @apply grid-cols-2;
    grid-template-columns: 1fr 1fr;
  }
}
.timer-to-add-row {
  @apply grid grid-cols-9 border-t border-offwhite-dark py-2 items-center px-2;
  @media screen and (max-width: 601px) {
    @apply grid-cols-2 shadow border border-offwhite-dark mt-3 gap-y-1;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
