const taskStatuses = [
  { id: 1, description: "Ootel", color: "grey-light" },
  { id: 2, description: "Aktiivne", color: "pending" },
  { id: 3, description: "Käigus", color: "attention" },
  { id: 4, description: "Tõrge", color: "danger" },
  { id: 5, description: "Lõpetatud", color: "turquoise" },
  { id: 6, description: "Kinnitatud", color: "green" },
  { id: 7, description: "Kustutatud", color: "danger-dark" },
];

const projectStatuses = [
  { id: 1, description: "Ootel", color: "grey" },
  { id: 2, description: "Aktiivne", color: "pending" },
  { id: 3, description: "Käigus", color: "attention" },
  { id: 4, description: "Tõrge", color: "danger" },
  { id: 5, description: "Lõpetatud", color: "turquoise" },
  { id: 6, description: "Kinnitatud", color: "green" },
  { id: 7, description: "Kustutatud", color: "danger-dark" },
];

const costStatuses = [
  {
    id: 0,
    description: "Planeeritud",
    color: "bordergrey",
  },
  {
    id: 1,
    description: "Loodud",
    color: "primary",
  },
  {
    id: 2,
    description: "Arvel",
    color: "attention",
  },
  {
    id: 3,
    description: "Tasutud",
    color: "green",
  },
  {
    id: 4,
    description: "Omakulu",
    color: "grey-light",
  },
  {
    id: 5,
    description: "Kustutatud",
    color: "danger",
  },
];

export default {
  methods: {
    parseDraftOfferStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span><span class='text-sm text-center text-attention'>Edastamata</span></div>";
          break;
        case 2:
        case 3:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Lõpetatud</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span><span class='text-sm text-center text-danger'>Tagasilükatud</span></div>";
          break;
        case 5:
          label =
            "<div class='status-label text-primary items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-primary mr-2'></span><span class='text-sm text-center text-primary'>Vastuvõetud</span></div>";
          break;
      }
      return label;
    },
    parseDraftInvoiceStatus(status) {
      let label = "";

      switch (status) {
        case 1:
          label =
            "<div class='status-label text-attention items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-attention mr-2'></span><span class='text-sm text-center text-attention'>Edastamata</span></div>";
          break;
        case 2:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Edastatud</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label text-pending items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-pending mr-2'></span><span class='text-sm text-center text-pending'>Lõpetatud</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label text-danger items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-danger mr-2'></span><span class='text-sm text-center text-danger'>Üle tähtaja</span></div>";
          break;
        case 5:
          label =
            "<div class='status-label text-primary items-center' data-cy='offerPending'><span class='w-4 h-4 rounded-full bg-primary mr-2'></span><span class='text-sm text-center text-primary'>Makstud</span></div>";
          break;
      }
      return label;
    },
    parseTaskStatus(statusInput) {
      let status = taskStatuses.find((status) => status.id === statusInput);
      return `<div class='flex items-center font-medium h-full'><span class='flex h-4 w-4 rounded-full shadow bg-${status.color} mr-2'></span><span>${status.description}</span></div>`;
    },
    parseTaskStatusText(statusInput) {
      let status = taskStatuses.find((status) => status.id === statusInput);
      if (!status) return "ei eksisteeri";
      return status.description;
    },
    parseTaskStatusColor(statusInput) {
      let status = taskStatuses.find((status) => status.id === statusInput);
      if (!status) return "bg-white";
      return `bg-${status.color}`;
    },
    parseProjectStatus(statusInput) {
      let status = projectStatuses.find((status) => status.id === statusInput);
      return `<div class='flex pl-1 py-1 items-center font-medium text-${status.color} mobile:text-xs'><span class='flex h-4 w-4 rounded-full bg-${status.color} mr-2'></span><span class='text-${status.color}'>${status.description}</span></div>`;
    },
    parseEquipmentStatus(status) {
      let label = "";
      switch (status) {
        case 1:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-pending mr-2'></span><span class='text-pending'>Aktiivne</span></div>";
          break;
        case 2:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-attention mr-2'></span><span class='text-attention'>Vigadega</span></div>";
          break;
        case 3:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-danger mr-2'></span><span class='text-danger'>Katki</span></div>";
          break;
        case 4:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-turquoise mr-2'></span><span class='text-turquoise'>Paranduses</span></div>";
          break;
        case 5:
          label =
            "<div class='status-label items-center font-medium text-grey' data-cy='offerPending'><span class='h-4 w-4 rounded-full bg-bordergrey mr-2'></span><span class='text-bordergrey'>Mitte aktiivne</span></div>";
          break;
      }
      return label;
    },
    parseCostStatus(statusInput) {
      const status = costStatuses.find((x) => x.id === statusInput);
      if (!status) return "";

      return `<div class='status-label flex items-center font-medium text-${status.color}'>
      <span class='h-3 w-3 rounded-full bg-${status.color} mr-1'></span>
      <span class='text-${status.color}'>${status.description}</span>
    </div>`;
    },
    parseCostStatusText(statusInput) {
      const status = costStatuses.find((x) => x.id === statusInput);
      if (!status) return "";

      return `<div class='status-label items-center font-medium text-${status.color}'>
      <span class='text-${status.color}'>${status.description}</span>
    </div>`;
    },
  },
};
